<template lang="pug">
  div
    j-master-dialog(
      ref="master_dialog"
      :show="show"
      :master-data="unitData"
      :loading="loading"
      :is-add="isAdd"
      :label="isAdd ? $t('master.unit_master.unit.add') : $t('master.unit_master.unit.edit')"
      :label-name="$t('master.unit_master.unit.name')"
      @on-close="closeDialog"
      @on-update="updateUnit"
      @on-create="createUnit"
      @on-typing="onTyping"
      @on-click-input="focusInput = $event"
    )
      template(v-slot:after-top)
        v-autocomplete.pt-8(
          ref="untg"
          item-text="name"
          item-value="id"
          :items="unitGroups"
          :label="$t('master.unit_master.unit_group.title')"
          v-model="unitData.untg"
          @keydown="onFocus"
          disabled
        )
    j-confirm-dialog(
      :showDialog="showConfirmDialog"
      :messages="confirmMsg"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.ok')"
      @on-cancel="showConfirmDialog=false"
      @on-ok="isAdd ? createUnit(true) : updateUnit(true), showConfirmDialog=false"
    )

</template>
<script>
import { defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted } from 'vue'
import JMasterDialog from '../JMasterDialog/index'
import JConfirmDialog from '../JConfirmDialog/index'
import { api } from '@/plugins'
import { endpoints, showError, master } from '@/utils'

const JUnitDialog = defineComponent({
  components: {
    JMasterDialog,
    JConfirmDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    unit: {
      type: Object,
      required: false,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    untg: {
      type: Number,
      required: true
    },
    prevInput: {
      type: String,
      required: false,
      default: ''
    },
    groups: {
      type: Array,
      required: false,
      default: null
    }
  },
  setup(props, { emit }) {
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const { unit } = toRefs(props)
    const loading = ref(false)
    const unitGroups = ref([])
    const showConfirmDialog = ref(false)
    const confirmMsg = ref([])
    const focusInput = ref('code') // focus default
    const enterKeydown = ref(0)
    const LIST_STATES = ['code', 'untg', 'name', 'yomi', 'name_short', 'name_eng'] // input order

    const unitData = ref({})

    const closeDialog = async () => {
      loading.value = false
      emit('on-close')
    }

    const createUnit = async (isDuplicate=false) => {
      loading.value = true
      try {
        const { data } = await api.post(`${endpoints.MASTER_UNIT}?is_duplicate=${isDuplicate}`, unitData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload', data)
        await closeDialog()
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          const itemName = ($root.$t('master.unit_master.unit.title'))
          const itemGroupName = ($root.$t('master.unit_master.unit_group.title'))
          confirmMsg.value = master.getErrorMessage(e, itemName, itemGroupName)
          confirmMsg.value.join('\n')
          showConfirmDialog.value = true
        } else showError(e, $toast, $root.$t('master.msg.update_failed'))
        // showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const updateUnit = async (isDuplicate=false) => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_UNIT}?is_duplicate=${isDuplicate}`, unitData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        await closeDialog()
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          const itemName = ($root.$t('master.unit_master.unit.title'))
          const itemGroupName = ($root.$t('master.unit_master.unit_group.title'))
          confirmMsg.value = master.getErrorMessage(e, itemName, itemGroupName)
          confirmMsg.value.join('\n')
          showConfirmDialog.value = true
        } else showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const getUnitGroup = async () => {
      if (props.groups) {
        unitGroups.value = props.groups
      } else {
        try {
          const { data } = await api.get(endpoints.MASTER_UNIT_GROUP)
          unitGroups.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }

    const onTyping = (event) => {
      enterKeydown.value = 0
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value, 'untg')
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    const onFocus = (event) => {
      focusInput.value = 'untg'
      if (event.keyCode === 13) {
        enterKeydown.value += 1
        if (enterKeydown.value === 2) onTyping(event)
      }
    }

    const init = async () => {
      focusInput.value = 'code'
      enterKeydown.value = 0
      if (props.show) {
        if (props.isAdd) {
          const unitInit = {
            code: null,
            name: props.prevInput,
            yomi: props.prevInput ? await master.toYomi(props.prevInput) : null,
            name_short: props.prevInput,
            name_eng: null,
            untg: props.untg
          }
          unitData.value = JSON.parse(JSON.stringify(unitInit))
        } else {
          unitData.value = JSON.parse(JSON.stringify(unit.value))
          unitData.value.untg = unit.value.untg.id
        }
      }
    }

    watch(
      () => props.show,
      () => init()
    )

    onMounted(getUnitGroup)

    return {
      closeDialog,
      loading,
      unitData,
      updateUnit,
      createUnit,
      unitGroups,
      showConfirmDialog,
      confirmMsg,
      focusInput,
      onTyping,
      onFocus
    }
  }
})

export default JUnitDialog
</script>
