<template lang="pug" xmlns="">
  j-dialog-container(
    :value="show"
    :label="$t('sale.sales_detail')"
    :width="1200"
    :show-bottom="!isConfirmed"
    mode="create"
    @on-close="$emit('on-close')"
    @on-create="onSave"
  )
    v-list-item.pa-0.info-assign
      span {{ invoiceDetail.product_name }}
      span.ml-3 {{ invoiceDetail.quality_name }}{{ invoiceDetail.size_name }}
      span.ml-3 {{ invoiceDetail.quantity }}{{ invoiceDetail.unit_name }}
      // 口数: 5	総数: 250	売残: 0
    v-list-item.pa-0.info-assign
      span {{ $t('sale.break_down.box') }} {{ invoiceDetail.box }}
      span.ml-3 {{ $t('sale.break_down.stem') }} {{ invoiceDetail.stem }}
      span.ml-3 {{ $t('sale.break_down.remaining') }} {{ remaining }}

    j-common-form-table(
      :columns="columns"
      :rows="saleBreakDowns"
      :show-show-hide-dialog="false"
      width="1150px"
      @on-copy-rows="onCopyRows"
      @on-delete-rows="onDeleteRows"
      @on-input="onInput"
    )
      template(v-slot:cell-input-auction_date="{cell, row}" v-if="isConfirmed")
        span {{ cell }}
      template(v-slot:cell-input-sale_stem="{cell, row}" v-if="isConfirmed")
        span {{ cell }}
      template(v-slot:cell-input-sale_amount="{cell, row}" v-if="isConfirmed")
        span {{ cell }}
      template(v-slot:cell-input-remaining="{cell, row}" v-if="isConfirmed")
        span {{ cell }}
</template>

<script>
import { defineComponent, watch, ref, getCurrentInstance, computed } from 'vue'
import JDialogContainer from '../JDialogContainer/index'
import JCommonFormTable from '../JCommonFormTable/index.vue'
import { initColumnsBreakDown } from './index'
import { getData, initRow, showError, endpoints, validateRows, isNotEmptyRow, convertToWidthCharacter } from '@/utils'
import { api } from '@/plugins'

const JSaleBreakDown = defineComponent({
  props: {
    show: { default: false },
    invoiceDetail: { default: null },
    isConfirmed: { default: false }
  },
  components: { JCommonFormTable, JDialogContainer },
  setup(props, { emit }) {
    const instance = getCurrentInstance().proxy
    const { $toast, $root } = instance
    const columns = ref(initColumnsBreakDown())
    const saleBreakDowns = ref([])
    const delBreakDown = ref([])
    const remaining = computed(() => {
      const breakDownsNotEmpty = saleBreakDowns.value.filter(e => isNotEmptyRow(e))
      const length = breakDownsNotEmpty.length
      if (length > 0) return breakDownsNotEmpty[length - 1].remaining
      return null
    })

    const calculateRemain = (data) => {
      let remain = 0
      saleBreakDowns.value = data.rows.map(breakDown => {
        remain += Number(convertToWidthCharacter(String(breakDown.sale_stem), 'half'))
        if (isNotEmptyRow(breakDown)) return { ...breakDown, remaining: props.invoiceDetail.stem - remain }
        return { ...breakDown }
      })
    }

    const onCopyRows = (data) => {
      /* dataDel: { rows: array } */
      calculateRemain(data)
    }

    const onDeleteRows = (dataDel) => {
      delBreakDown.value = delBreakDown.value.concat(dataDel.delRowIds)
      // re-calculate
      calculateRemain(dataDel)
    }

    const onInput = (dataInput) => {
      const index = dataInput.rows.indexOf(dataInput.dataInput.row)
      saleBreakDowns.value[index].$isValidate = {}
      if (
        dataInput.dataInput.column.name === 'sale_stem' || dataInput.dataInput.column.name === 'remaining'
      ) calculateRemain(dataInput)
    }

    const init = async () => {
      const { $route } = instance
      saleBreakDowns.value = props.invoiceDetail.sald_id
        ? (await getData('saleBreakDown', { saleBreakDown: { sald_id: props.invoiceDetail.sald_id } })).saleBreakDowns
        : []
      saleBreakDowns.value = saleBreakDowns.value.length > 0
        ? saleBreakDowns.value.map(saleBreakDown => {
          return {
            id: saleBreakDown.id,
            sald_id: saleBreakDown.sald_id,
            auction_date: saleBreakDown.auction_date,
            sale_stem: saleBreakDown.sale_stem,
            sale_amount: saleBreakDown.sale_amount,
            remaining: saleBreakDown.remaining
          }
        }).concat(initRow(columns.value.map(col => col.name)))
        : ((props.invoiceDetail.sale_stem || props.invoiceDetail.sale_amount)
          ? [{
            id: null,
            sald_id: props.invoiceDetail.sald_id,
            auction_date:
              props.invoiceDetail.sale_stem || props.invoiceDetail.sale_amount
                ? $route.query.auction_date : null,
            sale_stem: props.invoiceDetail.sale_stem,
            sale_amount: props.invoiceDetail.sale_amount,
            remaining: props.invoiceDetail.sale_stem || props.invoiceDetail.sale_amount
              ? props.invoiceDetail.stem - props.invoiceDetail.sale_stem : null
          }].concat(initRow(columns.value.map(col => col.name)))
          : initRow(columns.value.map(col => col.name))
        )
    }

    const create_or_update = async () => {
      const notEmptyRows = saleBreakDowns.value
        .filter(saleBreakDown => isNotEmptyRow(saleBreakDown))
        .map(saleBreakDown => {
          return {
            ...saleBreakDown,
            sale_stem: convertToWidthCharacter(String(saleBreakDown.sale_stem), 'half'),
            sale_amount: convertToWidthCharacter(String(saleBreakDown.sale_amount), 'half'),
            sald_id: props.invoiceDetail.sald_id
          }
        })
      const body = {
        break_downs: notEmptyRows,
        delete_break_down: delBreakDown.value
      }
      try {
        await api.post(endpoints.SALE_BREAK_DOWN, body)
        $toast.success($root.$t('master.msg.update_successful'))
        const saleStem = notEmptyRows.reduce((val, row) => parseInt(val) + (parseInt(row['sale_stem']) || 0), 0)
        const saleAmount = notEmptyRows.reduce((val, row) => parseInt(val) + (parseInt(row['sale_amount']) || 0), 0)
        emit(
          'on-close',
          {
            saldId: props.invoiceDetail.sald_id,
            saleStem,
            saleAmount,
            hasBreakdown: (notEmptyRows.length > 0),
            remaining: remaining.value
          }
        )
      } catch (e) {
        showError(e, $toast, $root.$t('common.msg.update_failed'))
      }
      // await init()
    }

    const onSave = () => {
      const [success, data] = validateRows(saleBreakDowns.value, columns.value)
      saleBreakDowns.value = data
      if (!success) return
      create_or_update()
    }

    watch(
      () => props.show,
      () => {
        if (props.show) init()
      }
    )

    return {
      columns,
      saleBreakDowns,
      onSave,
      onDeleteRows,
      onInput,
      onCopyRows,
      remaining
    }
  }
})

export default JSaleBreakDown
</script>