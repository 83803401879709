<template lang="pug">
  j-dialog-container(
    ref="dialog_container"
    :label="label"
    :mode="isAdd ? 'create' : 'update'"
    :loading="loading"
    height="85vh"
    @on-close="closeDialog"
    @on-update="updateGroup"
    @on-create="createGroup"
    @on-typing="onTyping"
    v-model="show"
  )
    validation-observer(
      ref="observer"
      v-slot="{ invalid }"
    )
      slot(name="top")
      slot(name="after-top")
      validation-provider(:name="$t('master.code')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
        v-text-field#code(
          ref="code"
          autofocus
          type='number'
          hide-spin-buttons
          :error-messages="errors"
          :label="$t('master.code')"
          v-model="masterData.code"
          @focus="onClickInput('code')"
        )
      validation-provider(v-if="isShowField" rules="required" v-slot="{ errors }" :name="labelName")
        v-text-field#name(
          ref="name"
          :error-messages="errors" :label="labelName"
          v-model="masterData.name"
          @input="toYomiAndNameShort"
          @focus="onClickInput('name')"
        )
      validation-provider(v-else rules="required" v-slot="{ errors }" :name="labelName")
        v-text-field#name(
          ref="name"
          :error-messages="errors" :label="labelName"
          v-model="masterData.name"
          @focus="onClickInput('name')"
        )
      div(v-if="isShowField")
        validation-provider(:rules="{required: true}" v-slot="{ errors }" :name="$t('master.yomigana')")
          v-text-field#yomi(ref="yomi" :error-messages="errors" :label="$t('master.yomigana')" v-model="masterData.yomi" @focus="onClickInput('yomi')")
        validation-provider(:rules="{required: true}" v-slot="{ errors }" :name="$t('master.name_short')")
          v-text-field#name_short(ref="name_short" :error-messages="errors" :label="$t('master.name_short')" v-model="masterData.name_short" @focus="onClickInput('name_short')")
        v-text-field#name_eng(ref="name_eng" :label="$t('master.name_eng')" v-model="masterData.name_eng" @focus="onClickInput('name_eng')")
      div
        slot(name="bottom")

</template>
<script>
import {defineComponent, getCurrentInstance, toRefs, watch, ref} from 'vue'
import {debounce} from 'lodash'
import JDialogContainer from '../JDialogContainer/index.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {master} from '@/utils'

const JGroupDialog = defineComponent({
  components: {
    JDialogContainer,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    masterData: {
      type: Object,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    labelName: {
      type: String,
      required: false,
      default: ''
    },
    isShowField: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, {emit}) {
    const {masterData} = toRefs(props)
    const instance = getCurrentInstance()
    const {$refs, $toast, $root} = instance.proxy
    const isCompositingInput = ref(true)

    const toYomiAndNameShort = debounce(async () => {
      masterData.value.yomi = await master.toYomi(masterData.value.name)
      masterData.value.name_short = masterData.value.name
    }, 300)

    const validateElement = async () => {
      const validate = await ($refs.observer).validate()
      if (!validate) {
        $toast.error($root.$t('master.msg.check_data_type'))
        return false
      }
      return true
    }

    const resetValidate = async () => {
      const validateName = await $refs.observer
      validateName.reset()
    }

    const onClickInput = (refName) => {
      emit('on-click-input', refName)
    }

    const closeDialog = () => {
      emit('on-close')
      resetValidate()
    }

    const convertData = () => {
      Object.keys(masterData.value).forEach((key) => {
        if (masterData.value[key] === '') masterData.value[key] = null
      })
    }


    const createGroup = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-create')
        await resetValidate()
      }
    }

    const updateGroup = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-update')
        await resetValidate()
      }
    }

    const onKeyDown = (event) => {
      isCompositingInput.value = event.key === 'Enter'
    }

    const onTyping = (event) => {
      if (isCompositingInput.value) emit('on-typing', event)
    }

    watch(
      () => masterData.value,
      () => {
        document.getElementById('code')?.addEventListener('keydown', onKeyDown)
        document.getElementById('name')?.addEventListener('keydown', onKeyDown)
        document.getElementById('yomi')?.addEventListener('keydown', onKeyDown)
        document.getElementById('name_short')?.addEventListener('keydown', onKeyDown)
        document.getElementById('name_eng')?.addEventListener('keydown', onKeyDown)
      }, {
        deep: true
      }
    )

    return {
      closeDialog,
      toYomiAndNameShort,
      updateGroup,
      createGroup,
      onClickInput,
      isCompositingInput,
      onTyping
    }
  }
})

export default JGroupDialog
</script>
