import Store from '@/store'

/* eslint-disable consistent-return */
export const navGuard = (to, from, next) => {
  // only add to store if change screen
  if (from.name !== to.name) {
    Store.commit('pushHis', to)
  } else if (from.fullPath !== to.fullPath) {
    Store.commit('updateHis', to)
  }
  next()
}

/* eslint-enable consistent-return */
