<template lang="pug">
  j-dialog-container(
    :label="label"
    :mode="isAdd ? 'create' : 'update'"
    :loading="loading"
    @on-close="closeDialog"
    @on-update="updateVariety"
    @on-create="createVariety"
    @on-typing="onTyping"
    v-model="show"
  )
    validation-observer(
      ref="observer"
      v-slot="{ invalid }"
    )
      validation-provider(:name="$t('master.code')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
        v-text-field#code(
          ref="code"
          autofocus
          :error-messages="errors"
          :label="$t('master.code')"
          v-model="masterData.code"
          @focus="onClickInput('code')"
        )

      validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.fee.name')")
        v-text-field#name(
          ref="name"
          :error-messages="errors"
          :label="$t('master.fee.name')"
          v-model="masterData.name"
          @input="toYomi"
          @focus="onClickInput('name')"
        )

      validation-provider(:rules="{required: true}" v-slot="{ errors }" :name="$t('master.yomigana')")
        v-text-field#yomi(ref="yomi" :error-messages="errors" :label="$t('master.yomigana')" v-model="masterData.yomi" @focus="onClickInput('yomi')")
      validation-provider(:name="$t('master.fee.price')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
        v-text-field#price(
          ref="price"
          :error-messages="errors"
          :label="$t('master.fee.price')"
          v-model="masterData.price"
          @focus="onClickInput('price')"
        )
      validation-provider(:name="$t('master.fee.basic_rate')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
        v-text-field#basic_rate(
          ref="basic_rate"
          :error-messages="errors"
          :label="$t('master.fee.basic_rate')"
          v-model="masterData.basic_rate"
          @focus="onClickInput('basic_rate')"
        )
      v-text-field#show_panel_name(ref="show_panel_name" :label="$t('master.fee.show_panel_name')" v-model="masterData.show_panel_name" @focus="onClickInput('show_panel_name')")
      p.mt-3.mb-2 {{$t('master.fee.is_auto_calculate')}}
        v-radio-group(v-model="masterData.is_auto_calculate").ml-4
          v-radio(:label="$t('master.fee.agree')" :value="true")
          v-radio(:label="$t('master.fee.disagree')" :value="false")
      p.mt-3.mb-2 {{$t('master.fee.is_percentage_based')}}
        v-radio-group(v-model="masterData.is_percentage_based").ml-4
          v-radio(:label="$t('master.fee.agree')" :value="true")
          v-radio(:label="$t('master.fee.disagree')" :value="false")
</template>
<script>
import {defineComponent, getCurrentInstance, toRefs, watch, ref} from 'vue'
import {debounce} from 'lodash'
import JDialogContainer from '../JDialogContainer/index.vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import { master } from '@/utils'

const JFeeForm = defineComponent({
  components: {
    JDialogContainer,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    masterData: {
      type: Object,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { masterData, show, isAdd } = toRefs(props)
    const instance = getCurrentInstance()
    const { $refs, $toast, $root } = instance.proxy
    const isCompositingInput = ref(true)
    const STATE_LIST = [
      'code', 'name', 'yomi',
      'price', 'basic_rate',
      'show_panel_name'
    ]

    const toYomi = debounce(async () => {
      masterData.value.yomi = await master.toYomi(masterData.value.name)
    }, 300)

    const validateElement = async () => {
      const validate = await ($refs.observer).validate()
      if (!validate) {
        $toast.error($root.$t('master.msg.check_data_type'))
        return false
      }
      return true
    }

    const resetValidate = async () => {
      const validateName = await $refs.observer
      validateName.reset()
    }

    const onClickInput = (refName) => {
      emit('on-click-input', refName)
    }

    const closeDialog = () => {
      emit('on-close')
      resetValidate()
    }

    const convertData = () => {
      Object.keys(masterData.value).forEach((key) => {
        if (masterData.value[key] === '') masterData.value[key] = null
      })
    }


    const createVariety = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-create')
        await resetValidate()
      }
    }

    const updateVariety = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-update')
        await resetValidate()
      }
    }

    const onKeyDown = (event) => {
      isCompositingInput.value = event.key === 'Enter'
    }

    const onTyping = (event) => {
      if (isCompositingInput.value) emit('on-typing', event)
    }
    watch(
      () => masterData.value,
      () => {
        STATE_LIST.forEach((element) => {
          document.getElementById(element)?.addEventListener('keydown', onKeyDown)
        })
      }
      , {
        deep: true
      }
      )
    return {
      closeDialog,
      toYomi,
      updateVariety,
      createVariety,
      onClickInput,
      isCompositingInput,
      onTyping
    }
  }
})

export default JFeeForm
</script>

<style lang="sass" scoped>
</style>