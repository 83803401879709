<template lang="pug">
  v-dialog(
    ref="dialog"
    persistent
    width="90vw"
    max-width="500px"
    :return-value.sync="dateData"
    v-model="isOpenDatePicker"
  )
    template(v-slot:activator="{ on, attrs }")
      v-text-field(
        :label="$t('common.auction_date')"
        readonly
        hide-details
        append-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        :value="dateFormat"
        @click:append="isOpenDatePicker = true"
        color="green"
      )
    v-date-picker(full-width scrollable locale="ja-jp" color="primary" header-color="primary" v-model="dateData")
      v-spacer
      v-btn.white--text(dark text color="red" @click="isOpenDatePicker = false")
        span {{$t('common.cancel')}}
      v-btn(dark text color="#343f4b" @click="$refs.dialog.save(dateData), savaDate()")
        span {{$t('common.ok')}}
</template>

<script>
import moment from 'moment'
import { defineComponent, ref, computed, getCurrentInstance, watch } from 'vue'

const DateDialog = defineComponent({
  props: ['date'],
  setup(props, { emit }) {
    const { $root } = getCurrentInstance().proxy
    const isOpenDatePicker = ref(false)
    const dateData = ref(props.date)
    const dateFormat = computed(() => {
      return moment(dateData.value).format($root.$t('common.time_full_format_date'))
    })

    const savaDate = () => {
      emit('save-date', dateData.value)
    }

    watch(
      () => props.date,
      () => {
        dateData.value = props.date
      }
    )

    return {
      isOpenDatePicker,
      savaDate,
      dateData,
      dateFormat
    }
  }
})

export default DateDialog
</script>