<template lang="pug">
  div
    slot(name="combobox" v-bind:items="invoiceSettingList" v-bind:select="selectedInvoiceSetting")
    v-row.ma-0.pa-0
      v-col.pa-0.my-0(cols="10")
        v-select(
          v-model="selectedInvoiceSetting"
          :items="invoiceSettingList"
          item-value="id"
          :item-text="(item) => (getModuleName(item.invm_id) + '  ' + moment(item.apply_at).format($t('common.time_full_format_date')))"
          dense
        )
      v-col.pa-0.my-0(cols="1")
        v-btn(@click="() => onOpenInvoiceSetting('edit')" icon)
          v-icon mdi-pencil
      v-col.pa-0.my-0(cols="1")
        v-btn(@click="() => onOpenInvoiceSetting('create')" icon)
          v-icon mdi-plus
    j-dialog-container(
      :value="isShow"
      :label="$t('master.customer.edit_detail_invoice')"
      :mode="'create'"
      :loading="loading"
      @on-close="closeDialog"
      @on-create="handleSave"
    )
      div.box
        v-row.px-10.pt-3
          span.pr-3 {{ $t('master.customer.name') }}:
          span {{ customerProp.name }}
        v-row.pl-10.pt-1
          span.pr-3 {{ $t('master.code') }}:
          span {{ customerProp.code }}
      br
      div
        v-col.pb-0.pl-1.pr-0
          v-select(
              :label="$t('master.customer.detail_invoice.module')"
              v-model="invoice.invm_id"
              :items="invoiceModules"
              :item-text="(item) => (item.name_short)"
              item-value="id"
            )
        v-col.pb-0.pl-1.pr-0
          div.auction_date__panel.px-0
            v-dialog(
              ref="dialog"
              persistent
              width="90vw"
              max-width="500px"
              :return-value.sync="auctionDate"
              v-model="isOpenDatePicker"
            )
              template(v-slot:activator="{ on, attrs }")
                v-text-field.pa-0.pr-0(
                  :label="$t('master.customer.detail_invoice.apply_date')"
                  readonly
                  hide-details
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                  :value="moment(auctionDate).format($t('common.time_full_format_date'))"
                  @click:append="isOpenDatePicker = true"
                  color="primary"
                )
              v-date-picker(full-width scrollable locale="ja-jp" color="secondary" header-color="secondary" v-model="auctionDate")
                v-spacer
                v-btn.white--text(dark text color="red" @click="isOpenDatePicker = false")
                  span {{$t('common.cancel')}}
                v-btn(dark text color="secondary" @click="$refs.dialog.save(auctionDate)")
                  span {{$t('common.ok')}}
          v-text-field(:label="$t('master.customer.detail_invoice.name')"
            v-model="invoice.setting.name")
          v-text-field(:label="$t('master.customer.detail_invoice.code')"
            v-model="invoice.setting.code")
          v-text-field(:label="$t('master.customer.detail_invoice.wholesaler')"
            v-model="invoice.setting.wholesaler_code")

          v-select(:label="$t('master.customer.detail_invoice.form_type')"
            v-model="invoice.setting.form_type"
            :items="formTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.country_type')"
            v-model="invoice.setting.country_type"
            :items="countryTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.packing_style_type')"
            v-model="invoice.setting.packing_style_type"
            :items="packingStyleTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.offer_type')"
            v-model="invoice.setting.offer_type"
            :items="offerTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.order_note_type')"
            v-model="invoice.setting.order_note_type"
            :items="orderNoteTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.catalog_type')"
            v-model="invoice.setting.catalog_type"
            :items="catalogTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.desirable_price_type')"
            v-model="invoice.setting.desirable_price_type"
            :items="desirablePriceTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.mark_type')"
            v-model="invoice.setting.mark_type"
            :items="markTypes"
            item-text="name"
            item-value="value")
          v-select(:label="$t('master.customer.detail_invoice.add_send_num_type')"
            v-model="invoice.setting.add_send_num_type"
            :items="addSendNumTypes"
            item-text="name"
            item-value="value")
          v-checkbox.py-0.my-0(:label="$t('master.customer.detail_invoice.is_mode')" v-model="invoice.setting.is_mode")
          v-checkbox.py-0.my-0(:label="$t('master.customer.detail_invoice.is_chart')" v-model="invoice.setting.is_chart")
          v-checkbox.py-0.my-0(:label="$t('master.customer.detail_invoice.is_diff_list')" v-model="invoice.setting.is_diff_list")
          v-checkbox.py-0.my-0(:label="$t('master.customer.detail_invoice.is_one_file')" v-model="invoice.setting.is_one_file")
</template>

<script lang="js">
import {computed, defineComponent, ref, watch, getCurrentInstance} from 'vue'

import { api } from '@/plugins'
import { endpoints, showError } from '@/utils'
import moment from 'moment'

import JDialogContainer from '../JDialogContainer/index.vue'

const CustomerDetailInvoiceDialog = defineComponent({
  components: {
    JDialogContainer
  },
  props: {
    customerProp: {
      type: Object,
      required: true
    },
    customerId: {
      type: Number,
      required: true
    }
  },
  setup(props, {emit }) {
    moment.locale('ja')
    const { $toast, $root } = getCurrentInstance().proxy
    const isShow = ref(false)
    const mode = ref('create')
    const isOpenDatePicker = ref(false)
    const auctionDate = ref(moment(new Date()).format('YYYY-MM-DD'))
    const date = ref('')
    const loading = ref(false)
    const defaultInvoice = () => {
      return {
        id: null,
        apply_at: moment(new Date()).format('YYYY-MM-DD'),
        cst_id: props.customerId,
        invm_id: 1,
        setting: {
          code: '',
          name: '',
          wholesaler_code: '',
          is_mode: false,
          is_chart: false,
          is_diff_list: false,
          is_one_file: true,
          form_type: 0,
          country_type: 0,
          packing_style_type: 0,
          offer_type: 0,
          order_note_type: 0,
          catalog_type: 0,
          desirable_price_type: 0,
          mark_type: 0,
          add_send_num_type: 0
        }
      }
    }
    const invoice = ref(defaultInvoice())
    const customerId = ref(props.customerId)
    const invoiceSettingList = ref([])
    const selectedInvoiceSetting = ref(null)
    const formTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const countryTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' }
    ]
    const packingStyleTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' }
    ]
    const offerTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const orderNoteTypes = [
      { value: 1, name: '1' },
      { value: 2, name: '2' },
      { value: 3, name: '3' }
    ]
    const catalogTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' },
      { value: 2, name: '2' }
    ]
    const desirablePriceTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const markTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const addSendNumTypes = [
      { value: 0, name: '0' },
      { value: 1, name: '1' }
    ]
    const invoiceModules = ref([])
    const getListInvoiceModule = async () => {
      try {
        const { data } = await api.get(
          `${endpoints.INVOICE_MODULE}`
        )
        invoiceModules.value = data
      } catch (e) {
        console.log(e)
      }
    }
    getListInvoiceModule()
    const getModuleName = (id) => {
      const foundModule = invoiceModules.value.find((module) => module.id === id)
      return foundModule.name
    }
    const getListInvoice = async () => {
      if (props.customerId) {
        try {
          const { data } = await api.get(
            `${endpoints.INVOICE_SETTING}?cst_id=${
              props.customerId ? props.customerId : customerId.value
            }`
          )
          invoiceSettingList.value = data
          if (data.length !== 0) {
            selectedInvoiceSetting.value = data[0].id
          }
        } catch (e) {
          showError(e, $toast, $root.$t('common.get_data_failed'))
        }
      }
    }
    getListInvoice()

    const closeDialog = () => {
      isShow.value = false
    }

    // CREATE
    const createInvoice = async () => {
      const setting = {
        code: invoice.value.setting.code ? invoice.value.setting.code : '',
        name: invoice.value.setting.name ? invoice.value.setting.name : '',
        wholesaler_code: invoice.value.setting.wholesaler_code ? invoice.value.setting.wholesaler_code : '',
        is_mode: invoice.value.setting.is_mode ? invoice.value.setting.is_mode : false,
        is_chart: invoice.value.setting.is_chart ? invoice.value.setting.is_chart : false,
        is_diff_list: invoice.value.setting.is_diff_list ? invoice.value.setting.is_diff_list : false,
        is_one_file: invoice.value.setting.is_one_file ? invoice.value.setting.is_one_file : true,
        form_type: invoice.value.setting.form_type ? invoice.value.setting.form_type : 0,
        country_type: invoice.value.setting.country_type ? invoice.value.setting.country_type : 0,
        packing_style_type: invoice.value.setting.packing_style_type ? invoice.value.setting.packing_style_type : 0,
        offer_type: invoice.value.setting.offer_type ? invoice.value.setting.offer_type : 0,
        order_note_type: invoice.value.setting.order_note_type ? invoice.value.setting.order_note_type : 0,
        catalog_type: invoice.value.setting.catalog_type ? invoice.value.setting.catalog_type : 0,
        desirable_price_type: invoice.value.setting.desirable_price_type
          ? invoice.value.setting.desirable_price_type
          : 0,
        mark_type: invoice.value.setting.mark_type ? invoice.value.setting.mark_type : 0,
        add_send_num_type: invoice.value.setting.add_send_num_type ? invoice.value.setting.add_send_num_type : 0
      }
      const data = {
        cst_id: props.customerId ? props.customerId : customerId.value,
        apply_at: auctionDate.value,
        invm_id: invoice.value.invm_id,
        setting: setting
      }
      try {
        loading.value = true
        await api.post(endpoints.INVOICE_SETTING, data)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    // UPDATE
    const updateInvoice = async () => {
      const setting = {
        code: invoice.value.setting.code,
        name: invoice.value.setting.name,
        wholesaler_code: invoice.value.setting.wholesaler_code,
        is_mode: invoice.value.setting.is_mode,
        is_chart: invoice.value.setting.is_chart,
        is_diff_list: invoice.value.setting.is_diff_list,
        is_one_file: invoice.value.setting.is_one_file,
        form_type: invoice.value.setting.form_type,
        country_type: invoice.value.setting.country_type,
        packing_style_type: invoice.value.setting.packing_style_type,
        offer_type: invoice.value.setting.offer_type,
        order_note_type: invoice.value.setting.order_note_type,
        catalog_type: invoice.value.setting.catalog_type,
        desirable_price_type: invoice.value.setting.desirable_price_type,
        mark_type: invoice.value.setting.mark_type,
        add_send_num_type: invoice.value.setting.add_send_num_type
      }
      const data = {
        id: invoice.value.id,
        apply_at: auctionDate.value,
        invm_id: invoice.value.invm_id,
        setting: setting
      }
      try {
        loading.value = true
        const result = await api.put(`${endpoints.INVOICE_SETTING}/${invoice.value.id}`, data)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
      closeDialog()
    }

    const resetData = () => {
      invoice.value = defaultInvoice()
    }

    const handleSave = async () => {
      if (invoice.value.id !== null || undefined) {
        await updateInvoice()
      } else {
        await createInvoice()
      }
      await getListInvoice()
    }
    const onOpenInvoiceSetting = (m) => {
      mode.value = m
      isShow.value = true
    }
    watch(
      () => props.customerId || props.customerProp,
      () => {
        customerId.value = props.customerId
        resetData()
        getListInvoice()
      }
    )
    watch(isShow, () => {
      if (isShow) {
        if (mode.value === 'edit'){
          const inv = invoiceSettingList.value.find((invoiceSetting) => invoiceSetting.id === selectedInvoiceSetting.value)
          if (inv) {
            invoice.value = inv
            auctionDate.value = inv.apply_at
          }
        } else if (mode.value === 'create'){
          invoice.value = defaultInvoice()
        }
      }
    })
    return {
      closeDialog,
      handleSave,
      invoice,
      formTypes,
      countryTypes,
      packingStyleTypes,
      offerTypes,
      orderNoteTypes,
      catalogTypes,
      desirablePriceTypes,
      markTypes,
      addSendNumTypes,
      loading,
      invoiceSettingList,
      selectedInvoiceSetting,
      onOpenInvoiceSetting,
      isShow,
      isOpenDatePicker,
      date,
      auctionDate,
      moment,
      invoiceModules,
      getModuleName
    }
  }
})

export default CustomerDetailInvoiceDialog
</script>

<style lang="sass" scoped>
.box
  margin-top: 20px
  background-color: lightgray
  opacity: 0.8
  border-radius: 10px
</style>
