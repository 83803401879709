<template lang="pug">
  .panel.mt-4(v-model="onClose" )
    p.mb-0
      b {{$t('master.customer.invoice_method_panel_title')}}

    v-list-group.pa-0(
      :value="invoiceMethodData.email.isCheck"
      append-icon=""
      :disabled="true"
      @click.stop
      :ripple="false"
    )
      template(v-slot:activator)
        v-checkbox(
          v-model="invoiceMethodData.email.isCheck"
          :label="$t('master.customer.email_method_title')"
          large
        )
      v-list-item.pa-0.ml-2
        v-checkbox(
          v-model="invoiceMethodData.email.sendToEmail"
          :label="$t('master.customer.send_to_email')"
          large
          )
        v-spacer
        span.mail-name {{ customer.invoice_email }}
      v-list-group.pa-0(:value="true" no-action sub-group)
        template(v-slot:activator)
          v-list-item-title.ma-0.pa-0 {{$t('master.customer.list_member_title')}}
        v-list-item.pa-0(v-if="listMemberData.length === 0" ).pl-10.py-0.my-0
          span.empty-member-span
            i {{$t('master.customer.list_member_empty_alert')}}
        v-list-item.ml-4.my-0.py-0(v-for="item in listMemberData" :key="item.id")
          v-checkbox(
            v-model="invoiceMethodData.email.members"
            :value="item.id"
            :label="item.name"
            large
            )
          v-spacer
          span.mail-name {{ item.email }}

    v-list-group.pa-0(
      :value="invoiceMethodData.data.isCheck"
      append-icon=""
      :disabled="true"
      @click.stop
      :ripple="false"
    )
      template(v-slot:activator)
        v-checkbox(
          v-model="invoiceMethodData.data.isCheck"
          :label="$t('master.customer.data_method_title')"
          large
          )
      v-list-item.pa-0.ml-2
        v-checkbox(
          v-model="invoiceMethodData.data.sendToEmail"
          :label="$t('master.customer.send_to_email')"
          large
          )
        v-spacer
        span.mail-name {{ customer.invoice_email }}
      v-list-group.pa-0(:value="true" no-action sub-group)
        template(v-slot:activator)
          v-list-item-title.ma-0.pa-0 {{$t('master.customer.list_member_title')}}
        v-list-item(v-if="listMemberData.length === 0" ).pl-10.py-0.my-0
          span.empty-member-span
            i {{$t('master.customer.list_member_empty_alert')}}
        v-list-item.ml-4.my-0.py-0(v-for="item in listMemberData" :key="item.id")
          v-checkbox(
            v-model="invoiceMethodData.data.members"
            :value="item.id"
            :label="item.name"
            large
          )
          v-spacer
          span.mail-name {{ item.email }}

    v-list-item
      v-checkbox(
        v-model="invoiceMethodData.fax"
        :label="$t('master.customer.fax_method_title')"
        large
        )
      v-spacer
      span.mail-name {{ customer.fax ? customer.fax : '' }}
    v-list-item
      v-checkbox(
        v-model="invoiceMethodData.download"
        :label="$t('master.customer.download_method_title')"
        large
        )
    p.mt-3.ml-4.mb-2(v-if="showAllowPreSale") {{$t('master.customer.allow_pre_slip_title')}}
    v-radio-group(v-if="showAllowPreSale" v-model="customer.allow_pre_sale").ml-4
      v-radio(:label="$t('master.customer.agree')" :value="true")
      v-radio(:label="$t('master.customer.not_agree')" :value="false")
</template>

<script lang="js">
import { defineComponent, ref, Ref, watch, onUpdated } from 'vue'

const SettingsInvoiceMethodPanel = defineComponent({
  props: {
    customer: {
      type: Object,
      required: true
    },
    onClose: {
      type: Boolean,
      required: true
    },
    onLoad: {
      type: Boolean,
      required: false
    },
    listMemberData: {
      type: Array,
      required: true
    },
    showAllowPreSale: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(props, { emit }) {
    const invoiceMethodData = ref({
      email: {
        isCheck: false,
        sendToEmail: true,
        members: []
      },
      data: {
        isCheck: false,
        sendToEmail: true,
        members: []
      },
      fax: false,
      download: false
    })
    const emailDestination = ref({
      mail: '',
      data: ''
    })
    const INVOICE_EMAIL_SIGN = '0'
    const getInvoiceMethodData = () => {
      if (props.customer.invoice_method !== null) {
        if (props.customer.invoice_email_destination !== null) {
          emailDestination.value.mail = props.customer.invoice_email_destination ? props.customer.invoice_email_destination.mail : ''
          emailDestination.value.data = props.customer.invoice_email_destination ? props.customer.invoice_email_destination.data : ''
        }
        const methodArray = props.customer.invoice_method
        if (methodArray) {
          if (methodArray.indexOf('mail') > -1) {
            invoiceMethodData.value.email.isCheck = true
            const emailArray = String(emailDestination.value.mail).split(',')
            invoiceMethodData.value.email.sendToEmail = emailArray.indexOf(INVOICE_EMAIL_SIGN) > -1
            for (let i = 0; i < emailArray.length; i += 1) {
              if (emailArray[i] !== INVOICE_EMAIL_SIGN)
                invoiceMethodData.value.email.members.push(parseInt(emailArray[i], 10))
            }
          }
          if (methodArray.indexOf('data') > -1) {
            invoiceMethodData.value.data.isCheck = true
            const emailArray = String(emailDestination.value.data).split(',')
            invoiceMethodData.value.data.sendToEmail = emailArray.indexOf(INVOICE_EMAIL_SIGN) > -1
            for (let i = 0; i < emailArray.length; i += 1) {
              if (emailArray[i] !== INVOICE_EMAIL_SIGN)
                invoiceMethodData.value.data.members.push(parseInt(emailArray[i], 10))
            }
          }
          if (methodArray.indexOf('fax') > -1) {
            invoiceMethodData.value.fax = true
          }
          if (methodArray.indexOf('download') > -1) {
            invoiceMethodData.value.download = true
          }
        }
      }
    }
    const clearInvoiceMethodData = () => {
      invoiceMethodData.value = {
        email: {
          isCheck: false,
          sendToEmail: true,
          members: []
        },
        data: {
          isCheck: false,
          sendToEmail: true,
          members: []
        },
        fax: false,
        download: false
      }
      emailDestination.value = {
        mail: '',
        data: ''
      }
    }
    const updateInvoiceMethodData = () => {
      const invoiceMethodsArray = []
      if (
        invoiceMethodData.value.email.isCheck &&
        (invoiceMethodData.value.email.members.length !== 0 ||
          invoiceMethodData.value.email.sendToEmail)
      ) {
        invoiceMethodsArray.push('mail')
        const emailArray = []
        if (invoiceMethodData.value.email.sendToEmail) emailArray.push(INVOICE_EMAIL_SIGN)
        for (let i = 0; i < invoiceMethodData.value.email.members.length; i += 1) {
          emailArray.push(invoiceMethodData.value.email.members[i].toString())
        }
        emailDestination.value.mail = emailArray.join()
      } else emailDestination.value.mail = ''
      if (
        invoiceMethodData.value.data.isCheck &&
        (invoiceMethodData.value.data.members.length !== 0 ||
          invoiceMethodData.value.data.sendToEmail)
      ) {
        invoiceMethodsArray.push('data')
        const emailArray = []
        if (invoiceMethodData.value.data.sendToEmail) emailArray.push(INVOICE_EMAIL_SIGN)
        for (let i = 0; i < invoiceMethodData.value.data.members.length; i += 1) {
          emailArray.push(invoiceMethodData.value.data.members[i].toString())
        }
        emailDestination.value.data = emailArray.join()
      } else emailDestination.value.data = ''
      if (invoiceMethodData.value.fax) {
        invoiceMethodsArray.push('fax')
      }
      if (invoiceMethodData.value.download) {
        invoiceMethodsArray.push('download')
      }
      if (invoiceMethodsArray.length > 0) emit('update-invoice-method', invoiceMethodsArray)
      else emit('update-invoice-method', null)
      if (emailDestination.value.mail !== '' || emailDestination.value.data !== '')
        emit('update-invoice-email-destination', emailDestination.value)
      else emit('update-invoice-email-destination', null)
    }
    getInvoiceMethodData()
    watch(
      () => props.customer,
      () => {
        getInvoiceMethodData()
      }
    )
    watch(
      () => props.onClose,
      () => {
        if (!props.onClose) clearInvoiceMethodData()
      }
    )
    watch(
      () => props.onLoad,
      () => {
        if (props.onLoad) {
          clearInvoiceMethodData()
          getInvoiceMethodData()
        }
      }
    )
    watch(
      () => props.listMemberData,
      () => {
        if (props.listMemberData.length === 0) {
          invoiceMethodData.value.email.members = []
          invoiceMethodData.value.data.members = []
        }
      }
    )

    onUpdated(() => {
      updateInvoiceMethodData()
    })
    return {
      invoiceMethodData
    }
  }
})

export default SettingsInvoiceMethodPanel
</script>

<style scoped lang="sass">
//.panel
//  height: fit-content
//  width: 100%
::v-deep .v-list-item
  max-height: 40px !important
  margin: 0
  padding: 0
//::v-deep .v-list-group__header
//  padding-left: 18px !important
::v-deep .theme--light.v-label
  color: black
.empty-member-span
  font-size: 0.8rem
  color: gray
.v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item
  padding-left: 40px
.mail-name
  font-size: 14px
  color: #00000059
</style>
