<template lang="pug">
  j-master-dialog(
    ref="master_dialog"
    :show="show"
    :master-data="commentData"
    :loading="loading"
    :is-add="isAdd"
    :delete-loading="deleteLoading"
    :label="isAdd ? $t('master.comment.add') : $t('master.comment.edit')"
    :label-name="$t('master.comment.name')"
    :is-show-field="false"
    @on-close="closeDialog"
    @on-update="updateComment"
    @on-create="createComment"
    @on-typing="onTyping"
    @on-click-input="focusInput = $event"
  )

</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance} from 'vue'
import JMasterDialog from '../JMasterDialog/index'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {api} from '@/plugins'
import {endpoints, master, showError} from '@/utils'

const JCommentDialog = defineComponent({
  components: {
    JMasterDialog,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    comment: {
      type: Object,
      required: true,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup(props, {emit}) {
    const instance = getCurrentInstance()
    const {$toast, $refs, $root} = instance.proxy
    const loading = ref(false)
    const deleteLoading = ref(false)
    const {comment} = toRefs(props)
    const LIST_STATES = ['code', 'name'] // input order'
    const focusInput = ref('code')

    const commentInit = {
      code: null,
      name: null
    }
    const commentData = ref({})

    const closeDialog = () => {
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    // create new
    const createComment = async () => {
      loading.value = true
      try {
        await api.post(endpoints.MASTER_COMMENT, commentData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      } finally {
        loading.value = false
      }
    }
    // update comment group
    const updateComment = async () => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_COMMENT}`, commentData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const onTyping = (event) => {
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    watch(
      () => props.show,
      () => {
        focusInput.value = 'code'
        if (props.show) {
          if (props.isAdd) commentData.value = JSON.parse(JSON.stringify(commentInit))
          else commentData.value = JSON.parse(JSON.stringify(comment.value))
        }
      }
    )

    return {
      closeDialog,
      loading,
      commentData,
      deleteLoading,
      updateComment,
      createComment,
      onTyping,
      focusInput
    }
  }
})

export default JCommentDialog
</script>
