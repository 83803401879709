
import {computed, defineComponent, PropType, reactive, toRefs} from 'vue'

import {CellMouseEvent, ContextAction, ContextActionPayload, getCursor, getTable} from './types'
import {getSelectedColumnIndexes, getSelectedRowIndexes, toColumns, toRows} from './utils'

export default defineComponent({
  inject: ['$table'],
  props: {
    actions: {
      type: Array as PropType<Array<ContextAction>>,
      required: true
    }
  },
  setup(props, {emit}) {
    const model = setupModel()
    const $table = getTable()
    const cursor = getCursor()

    showContextMenuOnEvent(model)

    /**
     * Check whether any of the action has icon
     */
    const hasIcon = computed(() => {
      return props.actions.some(action => action.icon)
    })

    const actionPayload = computed((): ContextActionPayload => {
      const rowIndexes = getSelectedRowIndexes($table)
      const rows = toRows($table, rowIndexes)
      const columnIndexes = getSelectedColumnIndexes($table)
      const columns = toColumns($table, columnIndexes)
      return {
        row: $table.rows[cursor.rowIndex],
        rowIndex: cursor.rowIndex,
        column: $table.columns[cursor.columnIndex],
        columnIndex: cursor.columnIndex,
        rows,
        rowIndexes,
        columns,
        columnIndexes
      }
    })

    /**
     * Trigger the handler of the context action
     */
    const onActionClick = (action: ContextAction): void => {
      emit(
        'even-right-click', {
          event: action.handler,
          actionPayload: actionPayload.value
        }
      )
    }

    /**
     * Get the actual label for an action
     */
    // const getLabel = (action: ContextAction): string => {
    //   if (typeof action.label === 'function') {
    //     return action.label(actionPayload.value)
    //   } else {
    //     // Since we don't use this yet, temporary ignore from cov
    //     /* istanbul ignore next */
    //     return action.label
    //   }
    // }

    return {
      ...toRefs(model),
      hasIcon,
      onActionClick
    }
  }
})

function setupModel() {
  return reactive({
    menuShown: false,
    x: 0,
    y: 0
  })
}

// eslint-disable-next-line no-undef
function showContextMenuOnEvent(model: ReturnType<typeof setupModel>) {
  const $table = getTable()

  $table.$on('table.contextmenu', ({$event}: CellMouseEvent) => {
    model.x = $event.clientX
    model.y = $event.clientY
    model.menuShown = true
  })
}
