
import {defineComponent} from 'vue'
import JConfirmDialog from '../JConfirmDialog/index.vue'

interface SetUp {
  closeDialog: (param: string) => void
}

const ConfirmReset = defineComponent({
  components: {
    JConfirmDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  setup(props, {emit}): SetUp {
    const closeDialog = (param: string) => {
      emit('on-close', param)
    }

    return {
      closeDialog
    }
  }
})
export default ConfirmReset
