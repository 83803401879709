<template lang="pug">
  j-dialog-container(
    ref="dialog_container"
    :label="isAdd ? $t('master.customer.add') : $t('master.customer.edit')"
    :mode="isAdd ? 'create' : 'update'"
    :loading="loading"
    :width="1300"
    :height="'90vh'"
    @on-close="closeDialog"
    @on-update="update"
    @on-create="create"
    @on-typing="onTyping"
    v-model="show"
  )
    validation-observer(
      ref="observer"
      v-slot="{ invalid }"
    )
      .container
        div
          .title-children
            h3 {{ $t('master.customer.basic_information') }}
          .content
            validation-provider(:name="$t('master.code')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
              v-text-field#code(
                ref="code" autofocus type='number' hide-spin-buttons :error-messages="errors" :label="$t('master.code')"
                v-model="customerData.code" @focus="onClickInput('code')"
              )
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.customer.name')")
              v-text-field#name(
                ref="name" :error-messages="errors" :label="$t('master.customer.name')" v-model="customerData.name"
                @input="toYomiAndNameShort" @focus="onClickInput('name')"
              )
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.yomigana')")
              v-text-field#yomi(ref="yomi" :error-messages="errors" :label="$t('master.yomigana')" v-model="customerData.yomi" @focus="onClickInput('yomi')")
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.name_short_only')")
              v-text-field#name_short(ref="name_short" :error-messages="errors" :label="$t('master.name_short_only')" v-model="customerData.name_short" @focus="onClickInput('name_short')")
            validation-provider(v-slot="{ errors }" :name="$t('master.customer.their_code')")
              v-text-field#their_code(
                ref="their_code" :label="$t('master.customer.their_code')" :error-messages="errors" v-model="customerData.their_code" @focus="onClickInput('their_code')"
              )
            j-master-customer-member-panel(
              v-if="!isAdd"
              :customerID="customerData.id ? customerData.id : cusID"
              @list-member="setListMember"
            )
            br
            j-settings-invoice-method-panel(
              v-if="!isAdd"
              :onClose="show"
              :onLoad="loading"
              :listMemberData="listMember"
              :customer="customerData"
              @update-invoice-method="(event) => onUpdateInvoiceMethod(event)"
              @update-invoice-email-destination="(event) => onUpdateInvoiceEmailDestination(event)"
            )
            br
            div(v-if="!isAdd")
              b {{ $t('master.customer.edit_detail_invoice') }}
            j-customer-detail-invoice-dialog(
              v-if="!isAdd"
              :customer-prop="customerData"
              :customer-id="customerData.id ? customerData.id : cusID"
            )
        div
          .title-children
            h3 {{ $t('master.customer.contact_information') }}
          .content
            v-text-field#owner_name(
              ref="owner_name" :label="$t('master.customer.owner_name')"
              v-model="customerData.owner_name" @focus="onClickInput('owner_name')"
            )
            validation-provider(
              :name="$t('master.customer.tel')"
              :rules={
                regex: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'
              }
              v-slot="{ errors }"
            )
              v-text-field#tel(
                ref="tel" :label="$t('master.customer.tel')" :error-messages="errors" v-model="customerData.tel" @focus="onClickInput('tel')"
              )
            v-text-field#fax(
              ref="fax" :label="$t('master.customer.fax')" v-model="customerData.fax" @focus="onClickInput('fax')"
            )
            v-text-field#email(
              ref="email" :label="$t('master.customer.email')"
              v-model="customerData.email" @focus="onClickInput('email')"
            )
            v-text-field#email(
              ref="invoice_email" :label="$t('master.customer.invoice_email')"
              v-model="customerData.invoice_email" @focus="onClickInput('invoice_email')"
            )
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.customer.zip')")
              v-text-field#zip(
                ref="zip" :label="$t('master.customer.zip')" :error-messages="errors" v-model="customerData.zip" @focus="onClickInput('zip')"
              )
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.customer.address_pref')")
              v-text-field#address_pref(
                ref="address_pref" :label="$t('master.customer.address_pref')" :error-messages="errors" v-model="customerData.address_pref" @focus="onClickInput('address_pref')"
              )
            validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.customer.address_1')")
              v-text-field#address_1(
                ref="address_1" :label="$t('master.customer.address_1')" :error-messages="errors" v-model="customerData.address_1" @focus="onClickInput('address_1')"
              )
            v-text-field#address_2(
              ref="address_2" :label="$t('master.customer.address_2')" v-model="customerData.address_2" @focus="onClickInput('address_2')"
            )
            validation-provider(:name="$t('master.customer.usage_fee_pot')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
              v-text-field#usage_fee_rate_pot(
                ref="usage_fee_rate_pot" type='number' hide-spin-buttons :error-messages="errors" :label="$t('master.customer.usage_fee_pot')"
                v-model="customerData.usage_fee_rate_pot" @focus="onClickInput('usage_fee_rate_pot')"
              )
            validation-provider(:name="$t('master.customer.usage_fee_cut')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
              v-text-field#usage_fee_rate_cut(
                ref="usage_fee_rate_cut" type='number' hide-spin-buttons :error-messages="errors" :label="$t('master.customer.usage_fee_cut')"
                v-model="customerData.usage_fee_rate_cut" @focus="onClickInput('usage_fee_rate_cut')"
              )
        div
          .title-children
            h3 {{ $t('master.customer.payment_information') }}
          .content
            v-checkbox(:label="$t('master.customer.copy_info')" :value="isCopy" @click="onClickCopy")
            v-autocomplete#cst_id_billing(
              ref="cst_id_billing" item-text="name" item-value="id"
              :items="customers"
              :label="$t('master.customer.cst_id_billing')"
              :readonly="customers.length === 0"
              :disabled="isCopy"
              v-model="customerData.cst_id_billing"
              @keydown="onFocus"
            )
            v-text-field#billing_name(
              ref="billing_name" :label="$t('master.customer.billing_name')" :disabled="isCopy"
              v-model="customerData.billing_name" @focus="onClickInput('billing_name')"
            )
            v-text-field#billing_dept(
              ref="billing_dept" :label="$t('master.customer.billing_dept')" :disabled="isCopy" v-model="customerData.billing_dept" @focus="onClickInput('billing_dept')"
            )
            v-text-field#billing_picname(
              ref="billing_picname" :label="$t('master.customer.billing_picname')" :disabled="isCopy" v-model="customerData.billing_picname" @focus="onClickInput('billing_picname')"
            )
            validation-provider(
              :name="$t('master.customer.billing_tel')"
              :rules={
                regex: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'
              }
              v-slot="{ errors }"
            )
              v-text-field#billing_tel(
                ref="billing_tel" :error-messages="errors" :label="$t('master.customer.billing_tel')" :disabled="isCopy"
                v-model="customerData.billing_tel" @focus="onClickInput('billing_tel')"
              )
            v-text-field#billing_fax(
              ref="billing_fax" :label="$t('master.customer.billing_fax')" :disabled="isCopy" v-model="customerData.billing_fax" @focus="onClickInput('billing_fax')"
            )
            v-text-field#billing_zip(
              ref="billing_zip" :label="$t('master.customer.billing_zip')" :disabled="isCopy" v-model="customerData.billing_zip" @focus="onClickInput('billing_zip')"
            )
            v-text-field#billing_address_pref(
              ref="billing_address_pref" :label="$t('master.customer.billing_address_pref')" :disabled="isCopy" v-model="customerData.billing_address_pref"
              @focus="onClickInput('billing_address_pref')"
            )
            v-text-field#billing_address_1(
              ref="billing_address_1" :label="$t('master.customer.billing_address_1')" :disabled="isCopy" v-model="customerData.billing_address_1"
              @focus="onClickInput('billing_address_1')"
            )
            v-text-field#billing_address_2(
              ref="billing_address_2" :label="$t('master.customer.billing_address_2')" :disabled="isCopy" v-model="customerData.billing_address_2"
              @focus="onClickInput('billing_address_2')"
            )

</template>

<script>
import { defineComponent, getCurrentInstance, ref, toRefs, watch } from 'vue'
import JDialogContainer from '../JDialogContainer/index'
import JSettingsInvoiceMethodPanel from '../JSettingsInvoiceMethodPanel/index'
import JCustomerDetailInvoiceDialog from '../JCustomerDetailInvoiceDialog/index'
import JMasterCustomerMemberPanel from '../JMasterCustomerMemberPanel'
import { debounce } from 'lodash'
import { endpoints, master, showError } from '@/utils'
import { STATES, CUSTOMER_INIT, copyData } from './index'
import { api } from '@/plugins'
import moment from "moment/moment";

const CustomerDialog = defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      default: null
    },
    customers: {
      type: Array,
      default: () => []
    },
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  components: {
    JDialogContainer,
    JSettingsInvoiceMethodPanel,
    JCustomerDetailInvoiceDialog,
    JMasterCustomerMemberPanel
  },
  setup(props, { emit }) {
    moment.locale('ja')
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const loading = ref(false)
    const { customer } = toRefs(props)
    const cusID = ref(0)
    const listMember = ref([])
    const LIST_STATES = STATES
    const focusInput = ref('code')
    const customerData = ref({})
    const isCopy = ref(false)
    const enterKeydown = ref(0)
    const validateElement = async () => {
      const validate = await ($refs.observer).validate()
      if (!validate) {
        $toast.error($root.$t('master.msg.check_data_type'))
        return false
      }
      return true
    }

    const resetValidate = async () => {
      const validateName = await $refs.observer
      validateName.reset()
    }
    // set list member from child
    const setListMember = (members) => {
      listMember.value = members
    }
    const closeDialog = () => {
      loading.value = false
      emit('on-close')
      resetValidate()
    }

    const toYomiAndNameShort = debounce(async () => {
      customerData.value.yomi = await master.toYomi(customerData.value.name)
      customerData.value.name_short = customerData.value.name
    }, 300)

    const onClickCopy = () => {
      isCopy.value = !isCopy.value
      customerData.value = copyData(isCopy.value, customerData.value)
    }

    const onFocus = (event) => {
      focusInput.value = 'cst_id_billing'
      if (event.keyCode === 13) {
        enterKeydown.value += 1
        if (enterKeydown.value === 2) onTyping(event)
      }
    }

    const onTyping = (event) => {
      enterKeydown.value = 0
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value, 'cst_id_billing')
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.dialog_container.$refs.save_btn.$el.click()
    }

    const onClickInput = (refName) => {
      focusInput.value = refName
    }

    const convertData = () => {
      Object.keys(customerData.value).forEach((key) => {
        if (customerData.value[key] === '') customerData.value[key] = null
      })
    }

    const update = async () => {
      convertData()
      const success = await validateElement()
      if (!success) {
        return
      }
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_CUSTOMER}`, customerData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const create = async () => {
      convertData()
      const success = await validateElement()
      if (!success) {
        return
      }
      loading.value = true
      try {
        await api.post(`${endpoints.MASTER_CUSTOMER}`, customerData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const init = async () => {
      focusInput.value = 'code'
      if (props.show) {
        if (props.isAdd) customerData.value = JSON.parse(JSON.stringify(CUSTOMER_INIT))
        else customerData.value = JSON.parse(JSON.stringify(customer.value))
      }
    }

    const onUpdateInvoiceMethod = (event) => {
      customerData.value.invoice_method = event
    }
    const onUpdateInvoiceEmailDestination = (event) => {
      customerData.value.invoice_email_destination = event
    }
    watch(
      () => props.show,
      () => init()
    )

    watch(
      () => customerData.value,
      () => {
        customerData.value = copyData(isCopy.value, customerData.value)
      }, {
        deep: true
      }
    )

    return {
      loading,
      onTyping,
      update,
      create,
      onClickInput,
      toYomiAndNameShort,
      customerData,
      isCopy,
      onClickCopy,
      onFocus,
      closeDialog,
      listMember,
      cusID,
      setListMember,
      onUpdateInvoiceMethod,
      onUpdateInvoiceEmailDestination
    }
  }
})

export default CustomerDialog
</script>

<style scoped lang="sass">
.container
  display: grid
  grid-gap: 25px
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr))
.container > div
  object-fit: cover
  border: 1px solid #3c3c3c
  .title-children
    background-color: #008037
    color: white
    padding: 10px
    text-align: center
  .content
    padding: 10px
</style>