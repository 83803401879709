export const MASTER_UNIT = 'master/unit/'
export const MASTER_UNIT_GROUP = 'master/unit_group/'
export const MASTER_ITEM = '/master/item/'
export const MASTER_VARIETY = '/master/variety/'
export const MASTER_SIZE = 'master/size/'
export const MASTER_SIZE_GROUP = 'master/size_group/'
export const TO_YOMI = '/common/get-pronunciation'
export const MASTER_BOX_TYPE = 'master/box_type/'
export const MASTER_BOX_TYPE_GROUP = 'master/box_type_group/'
export const MASTER_QUALITY = 'master/quality/'
export const MASTER_QUALITY_GROUP = 'master/quality_group/'
export const MASTER_COLOR = 'master/color/'
export const MASTER_CUSTOMER = 'master/customer/'
export const PICTURES = 'master/picture/'
export const MASTER_REGION = 'master/region/'
export const MASTER_ASSIGN_TYPE = 'master/assign_type/'
export const MASTER_COMMENT = 'master/comment/'
export const MASTER_SETTING = 'master/setting/'
export const MASTER_MEMBER = 'master/member/'
export const ASSIGNMENTS = '/assignment/'
export const MASTER_POT_SIZE = 'master/pot_size/'
export const CUSTOMER_MEMBER = '/master/customer_member'
export const INVOICE_SETTING = '/master/invoice_setting'
export const INVOICE_MODULE = '/master/invoice_module'
export const MASTER_PRESET_COLUMN = '/master/preset_column/'
export const MASTER_REQUIRED_COLUMN = '/master/required_column/'
export const INVOICE = '/invoice/'
export const SALE = '/sale/'
export const MASTER_FEE = '/master/fee/'
export const MASTER_VAT = '/master/vat/'
export const SALE_BREAK_DOWN = '/sale/break_down/'