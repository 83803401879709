<template lang="pug">
  j-confirm-dialog(
    :showDialog="showDialog"
    :messages="setMessage()"
    :cancel-text="$t('common.cancel')"
    :ok-text="$t('common.delete')"
    :color-confirm="'red'"
    @on-cancel="closeDialog('cancel')"
    @on-ok="closeDialog('delete')"
  )
</template>

<script>
import {defineComponent, getCurrentInstance} from 'vue'
import JConfirmDialog from '../JConfirmDialog/index'

const JConfirmDelete = defineComponent({
  components: {
    JConfirmDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    itemLabel: {
      type: String,
      required: false
    }
  },
  setup(props, {emit}) {
    const {$root} = getCurrentInstance().proxy
    const closeDialog = (param) => {
      emit('on-close', param)
    }

    const setMessage = () => {
      return [props.itemLabel ? props.itemLabel + $root.$t('common.wo_ja') + $root.$t('common.confirm_delete') : $root.$t('common.confirm_delete')]
    }

    return {
      closeDialog,
      setMessage
    }
  }
})
export default JConfirmDelete
</script>
