<template lang="pug">
  div
    j-dialog-container(
      ref="dialog_container"
      :label="$t('master.preset_column.title')"
      :mode="isAdd ? 'create' : 'update'"
      :loading="loading"
      @on-close="closeDialog"
      @on-update="onSave"
      @on-create="onSave"
      v-model="show"
    )
      validation-observer(
        ref="observer"
        v-slot="{ invalid }"
      )
        v-autocomplete(
          :disabled="!isAdd"
          :label="$t('master.preset_column.screen')"
          :items="screens"
          @input="onChangeSelectScreen"
          v-model="presetColumnData.screen"
        )
          template(v-slot:no-data)
            span.grey--text {{$t('common.no_data')}}
        validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.preset_column.type')")
          v-autocomplete(
            :label="$t('master.preset_column.type')"
            :items="productTypes"
            :disabled="!isAdd"
            :error-messages="errors"
            @input="onChangeSelectType"
            v-model="presetColumnData.type"
          )
            template(v-slot:no-data)
              span.pl-5.grey--text {{$t('common.no_data')}}
        validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.preset_column.name')")
          v-text-field(
            :label="$t('master.preset_column.name')"
            :error-messages="errors"
            v-model="presetColumnData.name"
          )
        v-checkbox(
          :label="$t('master.preset_column.is_default')"
          v-model="presetColumnData.is_default"
        )
        span {{ $t('master.preset_column.show_column') }}
        div(v-for="column in columns" :key="column.name")
          v-checkbox.pl-8(
            v-model="column.check"
            :disabled="column.disabled"
            :label="$t(`table_common.${column.name}`)"
            hide-details
          )

    j-confirm-dialog(
      :showDialog="showConfirmDialog"
      :messages="confirmMsg"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.ok')"
      @on-cancel="showConfirmDialog=false"
      @on-ok="onSave(false), showConfirmDialog=false"
    )
</template>
<script>
import { defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted } from 'vue'
import JDialogContainer from '../JDialogContainer/index'
import JConfirmDialog from '../JConfirmDialog/index'
import { api } from '@/plugins'
import { endpoints, showError } from '@/utils'
import { Screen, ProductTypes, Columns, RequiredColumns } from '@/utils/constants'

const JPresetColumnDialog = defineComponent({
  props: {
    show: {
      type: Boolean,
      required: true
    },
    presetColumn: {
      type: Object,
      required: false,
      default: null
    },
    presetColumns: {
      type: Array,
      required: false,
      default: () => []
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { JDialogContainer, JConfirmDialog },
  setup(props, { emit }) {
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const screens = Object.keys(Screen).map(key => {
      return {text: $root.$t(`${Screen[key]}.title`), value: Screen[key]}
    })
    const productTypes = ProductTypes.map(productType => {
      return {text: $root.$t(`common.${productType}`), value: productType}
    })
    const { presetColumn } = toRefs(props)
    const presetColumnData = ref({})
    const requiredColumns = ref([])
    const columns = ref([])
    const showConfirmDialog = ref(false)
    const confirmMsg = ref([])

    const convertData = () => {
      Object.keys(presetColumnData.value).forEach((key) => {
        if (presetColumnData.value[key] === '') presetColumnData.value[key] = null
      })
    }

    const updateCheckedColumns = (requiredColumn) => {
      if (requiredColumn.length === 0) requiredColumn = RequiredColumns
      columns.value = Columns.map((column) => {
        return {
          name: column,
          check: (presetColumnData.value.type === ProductTypes[1] && column === 'pot_size') ? false : requiredColumn.includes(column),
          disabled:
            (presetColumnData.value.type === ProductTypes[1] && column === 'pot_size') ||
            requiredColumns.value.find(
              requiredColumn => requiredColumn.screen === presetColumnData.value.screen
            )?.columns?.includes(column) ||
            RequiredColumns.includes(column)
        }
      })
    }

    const validateElement = async () => {
      const validate = await ($refs.observer).validate()
      if (!validate) {
        $toast.error($root.$t('master.msg.check_data_type'))
        return false
      }
      return true
    }

    const resetValidate = async () => {
      const validateName = await $refs.observer
      validateName.reset()
    }

    const closeDialog = () => {
      emit('on-close')
      resetValidate()
    }

    const getRequiredColumns = async () => {
      try {
        const { data } = await api.get(endpoints.MASTER_REQUIRED_COLUMN)
        requiredColumns.value = data
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.get_data_failed'))
      }
    }

    const getBody = () => {
      convertData()
      return {
        ...presetColumnData.value,
        show_column: columns.value.filter(column => column.check).map(column => column.name)
      }
    }

    const checkIsDefault = (type) => {
      if (!presetColumnData.value.is_default) return true
      const defaultExists = props.presetColumns.find(
        pre => pre.is_default && pre.type === type && pre.screen === presetColumnData.value.screen && (pre?.id ? pre.id !== presetColumnData.value?.id : true)
      )
      if (defaultExists) {
        confirmMsg.value = []
        showConfirmDialog.value = true
        confirmMsg.value.push($root.$t('master.preset_column.confirm_default'))
        return false
      }
      return true
    }

    const onSave = async (is_check = true) => {
      let success = true
      if (is_check) {
        success = checkIsDefault(presetColumnData.value.type)
      }
      if (!success) return
      if (!await validateElement()) return
      try {
        props.isAdd
          ? await api.post(endpoints.MASTER_PRESET_COLUMN, getBody())
          : await api.put(endpoints.MASTER_PRESET_COLUMN, getBody())
        closeDialog()
        props.isAdd
          ? $toast.success($root.$t('master.msg.create_successful'))
          : $toast.success($root.$t('master.msg.update_successful'))
        emit('reload')
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      }
    }

    const onChangeSelectScreen = (value) => {
      const requiredColumn = requiredColumns.value.find(rqc => rqc.screen === value)
      if (requiredColumn) {
        presetColumnData.value.show_column = JSON.parse(JSON.stringify(requiredColumn.columns))
      } else presetColumnData.value.show_column = JSON.parse(JSON.stringify(RequiredColumns))
      updateCheckedColumns(presetColumnData.value.show_column)
    }

    const onChangeSelectType = () => {
      updateCheckedColumns(presetColumnData.value.show_column)
    }

    const init = async() => {
      // enterKeydown.value = 0
      if (props.show) {
        if (props.isAdd) {
          const presetColumnInit = {
            screen: Screen.ASSIGNMENT,
            type: null,
            name: null,
            show_column: [],
            is_default: false
          }
          presetColumnData.value = JSON.parse(JSON.stringify(presetColumnInit))
          onChangeSelectScreen(presetColumnData.value.screen)
        } else {
          presetColumnData.value = JSON.parse(JSON.stringify(presetColumn.value))
          updateCheckedColumns(presetColumnData.value.show_column)
        }
      }
    }

    watch(
      () => props.show,
      () => init()
    )

    onMounted(getRequiredColumns)

    return {
      closeDialog,
      onSave,
      screens,
      productTypes,
      columns,
      presetColumnData,
      onChangeSelectScreen,
      onChangeSelectType,
      requiredColumns,
      showConfirmDialog,
      confirmMsg
    }
  }
})

export default JPresetColumnDialog
</script>

<style lang="sass" scoped>
.v-input--selection-controls
  padding: 0
  margin: 0
//.v-sheet.v-list:not(.v-sheet--outlined)
//  text-align: center
</style>
