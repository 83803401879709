<template lang="pug">
  div
    j-dialog-container(
      :label="$t('master.sort')"
      :show-bottom="false"
      :show-action-button="true"
      v-model="value"
      @on-close="$emit('on-close')"
      @on-save-action-button="onSave"
      @on-reset-action-button="showConfirmReset=true"
    )
      j-master-change-order-list(
        :value="items"
        @input="(event) => onInput(event)"
      )
    confirm-reset(
      :show-dialog="showConfirmReset"
      @on-close="confirmReset"
    )
</template>

<script lang="js">
import {defineComponent, ref} from 'vue'
import JMasterChangeOrderList from '../JMasterChangeOrderList/index.vue'
import JDialogContainer from '../JDialogContainer/index.vue'
import ConfirmReset from './ConfirmReset.vue'

const JSortDialog = defineComponent({
  components: {
    JMasterChangeOrderList,
    JDialogContainer,
    ConfirmReset
  },
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },
    items: {
      type: Array,
      require: true
    }
  },
  setup(props, {emit}) {
    const itemsSortVal = ref([])
    const showConfirmReset = ref(false)
    const onClose = () => {
      emit('on-close')
    }

    const onInput = (event) => {
      itemsSortVal.value = event
    }
    const onSave = () => {
      if (itemsSortVal.value.length > 0)
        emit('update-order-num', itemsSortVal.value)
    }

    const confirmReset = async (param) => {
      showConfirmReset.value = false
      if (param === 'reset') {
        emit('confirm-reset')
      }
    }

    return {
      onClose,
      onSave,
      onInput,
      showConfirmReset,
      confirmReset
    }
  }
})

export default JSortDialog
</script>
