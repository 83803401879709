
import {defineComponent} from 'vue'
import {PropType} from 'vue'

import {CellMouseEvent, Column, getTable, Row} from './types'

export default defineComponent({
  props: {
    column: {
      type: Object as PropType<Column>,
      required: true
    },
    row: {
      type: Object as PropType<Row>,
      required: true
    },
    rowIndex: {
      type: Number,
      required: true
    },
    columnIndex: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const on = setupEventHandlers(props)

    // Allow cell slot to update input by calling onInput(value)
    const {onInput} = setupInputEvent(props)
    const getDataDisPlay = (column, row) => {
      let returnValue = ''
      if (column.displayField && row[column.name]) {
        returnValue = row[column.name][column.displayField]
      } else if (column.type === 'number') {
        returnValue = isNaN(Number(row[column.name])) || Number(row[column.name]) === 0 
          ? row[column.name]
          : Number(row[column.name]).toLocaleString('en-US')
      } else {
        returnValue = row[column.name]
      }
      return returnValue
    }
    return {on, onInput, getDataDisPlay}
  }
})

function setupInputEvent(props: { row: Row; column: Column }) {
  const $table = getTable()
  return {
    onInput($event) {
      $table.$emit('input', {row: props.row, column: props.column, value: $event})
    }
  }
}

function setupEventHandlers(props: { rowIndex: number; columnIndex: number }) {
  const $table = getTable()

  function onClick($event: MouseEvent) {
    $table.$emit('cellclick', {
      rowIndex: props.rowIndex,
      columnIndex: props.columnIndex,
      $event
    } as CellMouseEvent)
  }

  function onDoubleClick($event: MouseEvent) {
    $table.$emit('celldblclick', {
      rowIndex: props.rowIndex,
      columnIndex: props.columnIndex,
      $event
    } as CellMouseEvent)
  }

  function onContextMenu(event: MouseEvent) {
    event.preventDefault()

    const $event: CellMouseEvent = {
      rowIndex: props.rowIndex,
      columnIndex: props.columnIndex,
      $event: event
    }

    $table.$emit('table.contextmenu', $event)
  }
  function onKeyDown($event: KeyboardEvent) {
    console.log($event)
  }
  return {
    mousedown: onClick,
    dblclick: onDoubleClick,
    contextmenu: onContextMenu,
    keydown: onKeyDown
  }
}
