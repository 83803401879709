import { Auth } from 'aws-amplify'

import { i18n } from '@/plugins'

/* eslint-disable consistent-return */
export const authGuard = (to, from, next) => {
  document.title = to.name ? `JFGA - ${i18n.t(to.name)}` : 'JFGA'

  // If the user is authenticated, continue with the route
  const fn = async () => {
    const isAuthenticated = await Auth.currentAuthenticatedUser()
      .then(() => {
        return true
      })
      .catch(() => {
        return false
      })

    // Disable auth during tests
    if (isAuthenticated || 'Cypress' in window) {
      return next()
    }

    // Otherwise, log in
    Auth.federatedSignIn()
  }

  return fn()
}

/* eslint-enable consistent-return */
