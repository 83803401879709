export const initColumnsMixedProduct = () => {
    const columns = [
        {
            name: 'item',
            header: true,
            items: [],
            dropdown: true,
            isActive: true,
            enterSkip: false,
            required: true,
            rules: 'required'
        },
        {
            name: 'variety',
            header: true,
            items: [],
            dropdown: true,
            isActive: true,
            enterSkip: false,
            required: true,
            rules: 'required'
        },
        {
            name: 'quality',
            header: true,
            items: [],
            dropdown: true,
            required: true,
            rules: 'required',
            isActive: true,
            enterSkip: false
        },
        {
            name: 'size',
            header: true,
            items: [],
            dropdown: true,
            required: true,
            rules: 'required',
            isActive: true,
            enterSkip: false
        },
        {
            name: 'quantity',
            isActive: true,
            enterSkip: false,
            required: true,
            rules: 'required',
            type: 'number',
            help: 'Number of units in each box'
        },
        {
            name: 'unit',
            header: true,
            items: [],
            dropdown: true,
            isActive: true,
            enterSkip: false,
            rules: 'required',
            required: true
        }
    ]

    return columns
}