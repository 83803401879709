<template lang="pug">
  j-master-dialog(
    ref="master_dialog"
    :show="show"
    :master-data="colorData"
    :loading="loading"
    :is-add="isAdd"
    :delete-loading="deleteLoading"
    :label="isAdd ? $t('master.color.add') : $t('master.color.edit')"
    :label-name="$t('master.color.name')"
    @on-close="closeDialog"
    @on-update="updateColor"
    @on-create="createColor"
    @on-typing="onTyping"
    @on-click-input="focusInput = $event"
  )

</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance} from 'vue'
import {debounce} from 'lodash'
import JMasterDialog from '../JMasterDialog/index'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {api} from '@/plugins'
import {endpoints, master, showError} from '@/utils'

const JColorDialog = defineComponent({
  components: {
    JMasterDialog,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    color: {
      type: Object,
      required: false,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true
    },
    prevInput: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, {emit}) {
    const instance = getCurrentInstance()
    const {$toast, $refs, $root} = instance.proxy
    const loading = ref(false)
    const deleteLoading = ref(false)
    const {color} = toRefs(props)
    const LIST_STATES = ['code', 'name', 'yomi', 'name_short', 'name_eng'] // input order'
    const focusInput = ref('code')
    const colorData = ref({})

    const toYomiAndNameShort = debounce(async () => {
      colorData.value.yomi = await master.toYomi(colorData.value.name)
      colorData.value.name_short = colorData.value.name
    }, 300)

    const closeDialog = () => {
      loading.value = false
      deleteLoading.value = false
      emit('on-close')
    }

    // create new
    const createColor = async () => {
      loading.value = true
      try {
        const {data} = await api.post(endpoints.MASTER_COLOR, colorData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload', data)
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      } finally {
        loading.value = false
      }
    }
    // update color group
    const updateColor = async () => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_COLOR}`, colorData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const onTyping = (event) => {
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    watch(
      () => props.show,
      async () => {
        focusInput.value = 'code'
        if (props.show) {
          if (props.isAdd) {
            const colorInit = {
              code: null,
              name: props.prevInput,
              yomi: props.prevInput ? await master.toYomi(props.prevInput) : null,
              name_short: props.prevInput,
              name_eng: null
            }
            colorData.value = JSON.parse(JSON.stringify(colorInit))
          } else colorData.value = JSON.parse(JSON.stringify(color.value))
        }
      }
    )

    return {
      closeDialog,
      loading,
      colorData,
      deleteLoading,
      toYomiAndNameShort,
      updateColor,
      createColor,
      onTyping,
      focusInput
    }
  }
})

export default JColorDialog
</script>
