<template lang="pug">
  div
    j-dialog-container(
      :value="isShow"
      :mode="isAdd ? 'create' : 'edit'"
      :label="isAdd ? $t('master.customer.add_mem') : $t('master.customer.edit_mem')"
      :loading="loading"
      :delete-loading="deleteLoading"
      :showDeleteBtn="true"
      :is-back="true"
      :itemLabel="itemProps.name"
      @on-close="closeDialog"
      @on-create="createMember"
      @on-update="updateMember"
      @on-delete="confirmDelete"
    )
      v-row.mx-2.pa-0.px-1(justify="space-around")
        v-col.pb-0.pl-1.pr-0
          v-text-field.mt-2(
            outlined
            :label="$t('master.customer.name_member')"
            v-model="itemProps.name"
          )
          v-text-field.mt-2(
            outlined
            :label="$t('master.customer.email')"
            v-model="itemProps.email"
          )
</template>
<script lang="js">
import { defineComponent, ref, toRefs, watch, getCurrentInstance } from 'vue'
import { JDialogContainer, JConfirmDelete } from '@/components'
import { api } from '@/plugins'
import { endpoints, showError } from '@/utils'

const MemberEditDialog = defineComponent({
  components: {
    JDialogContainer,
    JConfirmDelete
  },
  props: {
    isShow: {
      type: Boolean,
      required: false
    },
    itemProps: {
      type: Object,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: false
    },
    cusID: {
      type: Number,
      required: false
    }
  },
  setup(props, { emit }) {
    const { $toast, $root } = getCurrentInstance().proxy
    const { itemProps } = toRefs(props)
    const isAddNewSuccessful = ref(false)
    const MemberModal = ref(false)
    const deleteLoading = ref(false)
    const loading = ref(false)
    const customerID = ref(props.cusID)

    const closeDialog = () => {
      emit('close-dialog')
    }

    const resetData = () => {
      itemProps.value.name = ''
      itemProps.value.email = ''
    }

    // CREATE
    const createMember = async () => {
      const data = {
        name: itemProps.value.name ? itemProps.value.name : '',
        email: itemProps.value.email ? itemProps.value.email : '',
        cst_id: customerID.value ? customerID.value : props.cusID,
        mode: 'create'
      }
      if (customerID.value && data.email !== '' && data.name !== '') {
        try {
          loading.value = true
          await api.post(endpoints.CUSTOMER_MEMBER, data)
          $toast.success($root.$t('master.msg.create_successful'))
          emit('on-reload')
          emit('add-member', data)
          closeDialog()
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.create_failed'))
        } finally {
          loading.value = false
        }
      } else {
        $toast.error($root.$t('master.msg.create_failed'))
      }
    }

    // UPDATE
    const updateMember = async () => {
      // const id = itemProps.value.id
      const data = {
        id: itemProps.value.id,
        name: itemProps.value.name,
        email: itemProps.value.email,
        mode: 'update'
      }
      if (data.email !== '' && data.name !== '') {
        try {
          loading.value = true
          const result = await api.put(`${endpoints.CUSTOMER_MEMBER}/${props.itemProps.id}`, data)
          $toast.success($root.$t('master.msg.update_successful'))
          emit('on-reload')
          emit('update-member', data)
          if (result === undefined) {
            console.log(result)
          } else {
            console.log(result)
          }
          resetData()
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.update_failed'))
        } finally {
          loading.value = false
        }
        closeDialog()
      } else {
        $toast.error($root.$t('master.msg.update_failed'))
      }
    }

    // DELETE
    const confirmDelete = async () => {
      deleteLoading.value = true
      const data = {
        id: itemProps.value.id,
        name: itemProps.value.name,
        email: itemProps.value.email,
        mode: 'delete'
      }
      try {
        await api.delete(
          `${endpoints.CUSTOMER_MEMBER}/${props.itemProps.id}?cst_id=${customerID.value}`
        )
        $toast.success($root.$t('master.msg.delete_successful'))
        emit('on-reload')
        emit('delete-data', data)
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.delete_failed'))
      } finally {
        resetData()
        deleteLoading.value = false
      }
    }

    watch(
      () => props.itemProps,
      () => {
        customerID.value = props.cusID
      }
    )

    return {
      closeDialog,
      isAddNewSuccessful,
      MemberModal,
      confirmDelete,
      deleteLoading,
      loading,
      updateMember,
      createMember,
      customerID
    }
  }
})

export default MemberEditDialog
</script>
