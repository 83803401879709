<template lang="pug">
  j-dialog-container(
    :label="label"
    :mode="isAdd ? 'create' : 'update'"
    :loading="loading"
    @on-close="closeDialog"
    @on-update="updateVariety"
    @on-create="createVariety"
    @on-typing="onTyping"
    v-model="show"
    :width="800"
  )
    validation-observer(
      ref="observer"
      v-slot="{ invalid }"
    )
      slot(name="top")
      //v-text-field#commonID(
      //  ref="id"
      //  disabled
      //  :error-messages="errors"
      //  :label="$t('master.variety.id')"
      //  v-model="masterData.vat_id"
      //  @focus="onClickInput('id')"
      //)
      v-text-field#ItemName(
        ref="item_name"
        disabled
        :label="$t('master.item.title')"
        v-model="item.name_short"
      )
      validation-provider(:name="$t('master.code')" :rules={regex: '^[0-9]*$'} v-slot="{ errors }")
        v-text-field#code(
          ref="code"
          autofocus
          :error-messages="errors"
          :label="$t('master.code')"
          v-model="masterData.code"
          @focus="onClickInput('code')"
        )

      validation-provider(rules="required" v-slot="{ errors }" :name="$t('master.variety.name')")
        v-text-field#name(
          ref="name"
          :error-messages="errors"
          :label="$t('master.variety.variety_name')"
          v-model="masterData.name"
          @input="toYomiAndNameShort"
          @focus="onClickInput('name')"
        )

      validation-provider(:rules="{required: true}" v-slot="{ errors }" :name="$t('master.name_short')")
        v-text-field#name_short(ref="name_short" :error-messages="errors" :label="$t('master.name_short')" v-model="masterData.name_short" @focus="onClickInput('name_short')")

      validation-provider(:rules="{required: true}" v-slot="{ errors }" :name="$t('master.yomigana')")
        v-text-field#yomi(ref="yomi" :error-messages="errors" :label="$t('master.yomigana')" v-model="masterData.yomi" @focus="onClickInput('yomi')")

      v-text-field#name_eng(ref="name_eng" :label="$t('master.name_eng')" v-model="masterData.name_eng" @focus="onClickInput('nam_eng')")
      v-row
        v-col
          v-select#color(ref="color"  :label="$t('master.variety.color')"
            v-model="masterData.clr"
            :items="colorList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            clearable
            @change="onClickInput('color')"
            )
      v-text-field#quantity(ref="quantity" 
        :label="$t('master.variety.quantity')"
        v-model="masterData.quantity" 
        @focus="onClickInput('quantity')"
        )
      v-row
        v-col
          v-select#quality_group(ref="quality_group"  :label="$t('master.variety.quality_group')"
            v-model="masterData.quag"
            :items="qualityGroupList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('quality_group')"
            @input="onSelectGroup('quality_group')"
            clearable
            @click:clear="onClearGroup('quality_group')"
            )
        v-col
          v-select#quality(ref="quality"  :label="$t('master.variety.quality')"
            v-model="masterData.qua"
            :items="qualityList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('quality')"
          clearable
          )
      v-row
        v-col
          v-select#size_group(ref="size_group"  :label="$t('master.variety.size_group')"
            v-model="masterData.sizg"
            :items="sizeGroupList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('size_group')"
            @input="onSelectGroup('size_group')"
            clearable
            @click:clear="onClearGroup('size_group')"
            )
        v-col
          v-select#size(ref="size"  :label="$t('master.variety.size')"
            v-model="masterData.siz"
            :items="sizeList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('size')"
            clearable
            )
      v-row
        v-col
          v-select#unit_group(ref="unit_group"  :label="$t('master.variety.unit_group')"
            v-model="masterData.untg"
            :items="unitGroupList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('unit_group')"
            @input="onSelectGroup('unit_group')"
            clear-icon="mdi-close"
            clearable
            @click:clear="onClearGroup('unit_group')"
            )
        v-col
          v-select#unit(ref="unit"  :label="$t('master.variety.unit')"
            v-model="masterData.unt"
            :items="unitList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('unit')"
            clearable
            )
      v-row
        v-col
          v-select#box_type_group(ref="box_type_group"  :label="$t('master.variety.box_type_group')"
            v-model="masterData.bxtg"
            :items="boxTypeGroupList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('box_type_group')"
            @input="onSelectGroup('box_type_group')"
            clearable
            @click:clear="onClearGroup('box_type_group')"
            )
        v-col
          v-select#box_type(ref="box_type"  :label="$t('master.variety.box_type')"
            v-model="masterData.bxt"
            :items="boxTypeList" item-text="name" item-value="id"
            attach
            :menu-props="{ top: true, offsetY: true }"
            hide-details
            @change="onClickInput('box_type')"
            clearable
          )
</template>
<script>
import { defineComponent, getCurrentInstance, toRefs, watch, ref } from 'vue'
import { debounce } from 'lodash'
import JDialogContainer from '../JDialogContainer/index.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { api } from '@/plugins'
import { endpoints, showError, master } from '@/utils'
import router from '@/router'

const JVarietyForm = defineComponent({
  components: {
    JDialogContainer,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    masterData: {
      type: Object,
      required: true
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    qualityGroupList: {
      type: Array,
      required: true
    },
    sizeGroupList: {
      type: Array,
      required: true
    },
    unitGroupList: {
      type: Array,
      required: true
    },
    boxTypeGroupList: {
      type: Array,
      required: true
    },
    colorList: {
      type: Array,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const { masterData, show, isAdd, item } = toRefs(props)
    const instance = getCurrentInstance()
    const { $refs, $toast, $root } = instance.proxy
    const isCompositingInput = ref(true)
    const isClearGroup = ref(false)
    const STATE_LIST = [
      'id',
      'code',
      'name',
      'name_short',
      'yomi',
      'name_eng',
      'quantity',
      'color',
      'quality_group',
      'quality',
      'size_group',
      'size',
      'unit_group',
      'unit',
      'box_type_group',
      'box_type'
    ]
    const qualityList = ref([])
    const sizeList = ref([])
    const unitList = ref([])
    const boxTypeList = ref([])

    const toYomiAndNameShort = debounce(async () => {
      masterData.value.yomi = await master.toYomi(masterData.value.name)
      masterData.value.name_short = masterData.value.name
    }, 300)

    const validateElement = async () => {
      const validate = await ($refs.observer).validate()
      if (!validate) {
        $toast.error($root.$t('master.msg.check_data_type'))
        return false
      }
      return true
    }

    const resetValidate = async () => {
      const validateName = await $refs.observer
      validateName.reset()
    }

    const onClickInput = (refName) => {
      isClearGroup.value = false
      emit('on-click-input', refName)
    }

    const closeDialog = () => {
      emit('on-close')
      resetValidate()
    }

    const convertData = () => {
      Object.keys(masterData.value).forEach((key) => {
        if (masterData.value[key] === '') masterData.value[key] = null
      })
    }


    const createVariety = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-create')
        await resetValidate()
      }
    }

    const updateVariety = async () => {
      convertData()
      if (await validateElement()) {
        emit('on-update')
        await resetValidate()
      }
    }

    const onKeyDown = (event) => {
      isCompositingInput.value = event.key === 'Enter'
    }

    const onTyping = (event) => {
      if (isCompositingInput.value) emit('on-typing', event)
    }
    const onSelectGroup = async (type, editMode = false) => {
      let url = null
      if (isClearGroup.value === false) {
        switch (type) {
          case 'quality_group':
            url = `${endpoints.MASTER_QUALITY}?quag=${masterData.value.quag}`
            break
          case 'size_group':
            url = `${endpoints.MASTER_SIZE}?sizg=${masterData.value.sizg}`
            break
          case 'unit_group':
            url = `${endpoints.MASTER_UNIT}?untg=${masterData.value.untg}`
            break
          case 'box_type_group':
            url = `${endpoints.MASTER_BOX_TYPE}?bxtg=${masterData.value.bxtg}`
            break
        }
        try {
          const { data } = await api.get(url)
          switch (type) {
            case 'quality_group':
              qualityList.value = data
              if (!editMode) {
                masterData.value.qua = qualityList.value[0] ? qualityList.value[0].id : null
              }
              break
            case 'size_group':
              sizeList.value = data
              if (!editMode) {
                masterData.value.siz = sizeList.value[0] ? sizeList.value[0].id : null
              }
              break
            case 'unit_group':
              unitList.value = data
              if (!editMode) {
                masterData.value.unt = unitList.value[0] ? unitList.value[0].id : null
              }
              break
            case 'box_type_group':
              boxTypeList.value = data
              if (!editMode) {
                masterData.value.bxt = boxTypeList.value[0] ? boxTypeList.value[0].id : null
              }
              break
          }
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    const onFirstLoad = (editMode) => {
      if (masterData.value.quag !== null) {
        onSelectGroup('quality_group', editMode)
      }
      if (masterData.value.sizg !== null) {
        onSelectGroup('size_group', editMode)
      }
      if (masterData.value.untg !== null) {
        onSelectGroup('unit_group', editMode)
      }
      if (masterData.value.bxtg !== null) {
        onSelectGroup('box_type_group', editMode)
      }
      masterData.value.quantity = masterData.value.quantity ? masterData.value.quantity : null
    }
    const defaultInitAllSelection = () => {
      masterData.value.quag = null
      masterData.value.sizg = null
      masterData.value.untg = null
      masterData.value.bxtg = null
    }

    const onClearGroup = (type) => {
      isClearGroup.value = true
      switch (type) {
        case 'quality_group':
          qualityList.value = []
          break
        case 'size_group':
          sizeList.value = []
          break
        case 'unit_group':
          unitList.value = []
          break
        case 'box_type_group':
          boxTypeList.value = []
          break
      }
    }
    watch(
      () => masterData.value,
      () => {
        STATE_LIST.forEach((element) => {
          document.getElementById(element)?.addEventListener('keydown', onKeyDown)
        })
      }
      , {
        deep: true
      }
    )
    watch(show, () => {
      if (show.value) {
        if (isAdd.value) {
          defaultInitAllSelection()
          onFirstLoad(false)
        }
        else {
          onFirstLoad(true)
        }
      }
    })
    return {
      closeDialog,
      toYomiAndNameShort,
      updateVariety,
      createVariety,
      onClickInput,
      isCompositingInput,
      onTyping,
      qualityList,
      sizeList,
      unitList,
      boxTypeList,
      onSelectGroup,
      onClearGroup
    }
  }
})

export default JVarietyForm
</script>

<style lang="sass" scoped>
</style>