import { getData } from '@/utils'

const initColumns = (disable = []) => {
    const columns = [
        {
            name: "cutpot",
            items: [
                {
                    name: "Cut",
                    value: "cut"
                },
                {
                    name: "Pot",
                    value: "pot"
                },
                {
                    name: "Other",
                    value: "other"
                }
            ],
            dropdown: true,
            isActive: true,
            rules: "required|oneOf:cut,pot,other"
        },
        {
            name: "icon",
            items: [
                {
                    name: "New",
                    value: "new"
                },
                {
                    name: "Special offer",
                    value: "special_offer"
                },
                {
                    name: "Recommended",
                    value: "recommended"
                },
                {
                    name: "Rare",
                    value: "rare"
                }
            ],
            dropdown: true,
            isActive: true,
            rules: "oneOf:new,special_offer,recommended,rare"
        },
        {
            name: "origin",
            isActive: true
        },
        {
            name: "family",
            header: true,
            items: [
                {
                    code: '001',
                    name: "product 1",
                    value: "product 1"
                },
                {
                    code: '002',
                    name: "product 2",
                    value: "product 2"
                },
                {
                    code: '003',
                    name: "product 3",
                    value: "product 3"
                },
                {
                    code: '004',
                    name: "product 4",
                    value: "product 4"
                }
            ],
            dropdown: true,
            isActive: true,
            required: true,
            rules: "required"
        },
        {
            name: "variety",
            isActive: true,
            required: true,
            rules: "required"
        },
        {
            name: "color",
            isActive: true
        },
        {
            name: "pot_size",
            isActive: true
        },
        {
            name: "fee",
            isActive: true
        },
        {
            name: "grade",
            isActive: true,
            help: "The flower quality (Selected, Standard, etc...), or consistent length of stem, size of bloom."
        },
        {
            name: "quantity",
            isActive: true,
            required: true,
            rules: "required",
            type: "number",
            help: "Number of units in each box"
        },
        {
            name: "unit",
            isActive: true,
            help: "E.g: stem, branch, etc.",
            rules: "required",
            required: true
        },
        {
            name: "pieces_per_unit",
            isActive: true,
            help: "Number of pieces per each unit. E.g. stems per bunch",
            type: "number",
            rules: "min_value:1"
        },
        {
            name: "box_weight",
            isActive: true,
            type: "number"
        },
        {
            name: "price",
            isActive: true,
            required: true,
            rules: "required|greater_than_zero",
            help: "Price per unit"
        },
        {
            name: "note",
            isActive: true,
            help: "Additional information for an item"
        },
        {
            name: "private_note",
            isActive: true,
            help: "Note for internal use, not visible to merchants. The note will also show up in orders."
        },
        {
            name: "sold_out",
            isActive: true,
            rules: "isBool"
        },
        {
            name: "images",
            isActive: true
        }
    ]

    columns.forEach(col => {
        col.isActive = disable.indexOf(col.name) === -1
    })

    return columns
}


const initRow = (columns, data = null) => {
    // columns: string[]
    let row = {}
    columns.forEach((name) => {
        row[name] = null
    })

    if (data) {
        Object.keys(data).forEach((key) => {
            row[key] = data[key]
        })
    }
    return [row]
}


const checkLastRow = (rows, indexCurrentRowInput) => {
    return rows.length - 1 === indexCurrentRowInput
}


const autoAddEmptyRow = (rows, indexCurrentRowInput) => {
    if (checkLastRow(rows, indexCurrentRowInput)) return addOneRow(rows)
}


const addOneRow = (rows, index = null, data = null) => {
    /*
    {
        row: $table.rows[cursor.rowIndex],
        rowIndex: cursor.rowIndex,
        column: $table.columns[cursor.columnIndex],
        columnIndex: cursor.columnIndex,
        rows,
        rowIndexes,
        columns,
        columnIndexes,
    }
    */
    // exp: { color: 'red', box_type: 'L' }
    const column = Object.keys(rows[0])
    let newRow = initRow(column, data)[0]

    const indexInsert = index ? index : rows.length
    if (index === 0) {
        rows.splice(index, 0, newRow)
    } else {
        rows.splice(indexInsert, 0, newRow)
    }
    return rows
}

const addRows = (rows, numberRowAdd = 1, index = null, isAbove = false) => {
    const indexInsert = index !== null ? (isAbove ? index : index + 1) : rows.length - 1
    for (let i = 0; i < numberRowAdd; i++) {
        rows = addOneRow(rows, indexInsert)
    }
    return rows
}

const deleteRows = (rows, numberRowDelete, index) => {
    if (rows.length === (numberRowDelete + index)) {
        rows = addOneRow(rows, numberRowDelete + index + 1)
    }
    const deletedRows = rows.slice(index, numberRowDelete + index)
    rows.splice(index, numberRowDelete)
    return [rows, deletedRows]
}

const copyRows = (rows, newRows, index) => {
    let indexInsert = index + 1
    newRows.forEach((newRow) => {
        newRow = JSON.parse(JSON.stringify(newRow))
        if ('history' in newRow) newRow.history = null
        if (newRow.id) newRow.id = null
        rows.splice(indexInsert, 0, newRow)
        indexInsert++
    })
    return rows
}

const getSelectItems = async (
    columns, dataInput, dataMaster
) => {
    const searchInput = dataInput.searchInput
    const curColumn = dataInput.column.name
    if (curColumn === 'item') {
        const data = await getData(
            ['item'],
            { item: { is_self_searching: true, search_input: searchInput === null ? '' : searchInput } }
        )
        columns = addDataDropDown(columns, { item: data.items })
    }
    if (curColumn === 'variety') {
        const curItemRow = dataMaster.items.find(item => item.name_short === dataInput.row.item)
        if (dataInput.row.item !== null && curItemRow) {
            const data = await getData(['variety'], {
                variety: {
                    item: curItemRow?.id,
                    search_input: searchInput ? searchInput : ''
                }
            })
            columns = addDataDropDown(columns, { variety: data.varieties })
        }
    }
    if (curColumn === 'size') {
        const filterData = dataMaster.sizes.filter(e => e.sizg?.id === dataInput.row.size_group)
        columns = addDataDropDown(
            columns, { size: filterData.length > 0 ? filterData : dataMaster.sizes }
        )
    }
    if (curColumn === 'unit') {
        const filterData = dataMaster.units.filter(e => e.untg?.id === dataInput.row.unit_group)
        columns = addDataDropDown(
            columns, { unit: filterData.length > 0 ? filterData : dataMaster.units }
        )
    }
    if (curColumn === 'box_type') {
        const filterData = dataMaster.boxTypes.filter(e => e.bxtg?.id === dataInput.row.box_type_group)
        columns = addDataDropDown(
            columns, { box_type: filterData.length > 0 ? filterData : dataMaster.boxTypes }
        )
    }
    if (curColumn === 'quality') {
        const filterData = dataMaster.qualities.filter(e => e.quag?.id === dataInput.row.quality_group)
        columns = addDataDropDown(
            columns, { quality: filterData.length > 0 ? filterData : dataMaster.qualities }
        )
    }
    if (curColumn === 'color') {
        columns = addDataDropDown(
            columns, { color: dataMaster.colors }
        )
    }
    if (curColumn === 'pot_size') {
        columns = addDataDropDown(
            columns, { pot_size: dataMaster.potSizes }
        )
    }
    if (curColumn === 'fee') {
        columns = addDataDropDown(
            columns, {fee: dataMaster.fees}
        )
    }
    return columns
}

const addDataDropDown = (columns, dataDetail) => {
    return columns.map(column => {
        if (column.dropdown && dataDetail[column.name]) {
            column.items = dataDetail[column.name]
        }
        return { ...column }
    })
}

const isNotEmptyRow = (row) => {
    return Object.keys(row).some((key) => {
        if (key === '$isValidate') return false
        return row[key] !== null
    })
}

const validateRow = (row, column, value) => {
    row = JSON.parse(JSON.stringify(row))
    if (column.required) {
        if (value === '' || !value) {
            if (!row.$isValidate) row.$isValidate = {}
            row.$isValidate[column.name] = true
            return [false, row]
        }
    }
    return [true, row]
}

const validateRows = (rows, columns) => {
    let success = true
    rows = rows.map((row) => {
        if (!isNotEmptyRow(row)) return row
        columns.forEach(column => {
            const [successRow, rowResult] = validateRow(row, column, row[column.name])
            success = success && successRow
            row = rowResult
        })
        return row
    })
    console.log(success)
    return [success, JSON.parse(JSON.stringify(rows))]
}

export {
    initRow,
    addOneRow,
    addRows,
    deleteRows,
    copyRows,
    autoAddEmptyRow,
    initColumns,
    addDataDropDown,
    getSelectItems,
    isNotEmptyRow,
    validateRow,
    validateRows
}