import { render, staticRenderFns } from "./Icon.vue?vue&type=template&id=be3cca94&scoped=true&lang=pug&"
import script from "./Icon.vue?vue&type=script&lang=js&"
export * from "./Icon.vue?vue&type=script&lang=js&"
import style0 from "./Icon.vue?vue&type=style&index=0&id=be3cca94&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be3cca94",
  null
  
)

/* custom blocks */
import block0 from "./Icon.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports