<template lang="pug">
  v-app
    j-header-menu(:back="enableBack")
    v-main
      j-navigation-menu(
        v-model="isOpenNav"
        @toggle="toggleNavigation"
      )
      router-view.router-view
</template>

<script>
import { defineComponent, ref, watch, getCurrentInstance } from 'vue'
import { JHeaderMenu, JNavigationMenu } from 'components'
import { urlPath } from 'utils'
import { history } from "./store/history"
// import router from '@/router'

const App = defineComponent({
  components: {
    JHeaderMenu,
    JNavigationMenu
  },
  setup( ) {
    const instance = getCurrentInstance()
    const { $root } = instance.proxy
    const enableBack = ref(false)
    const isOpenNav = ref(false)
    const toggleNavigation = () => {
      isOpenNav.value = !isOpenNav.value
    }
    watch(
        () => $root.$route,
        () => {
          const { path } = $root.$route
          const isMainUrl = urlPath.DISABLE_NAVIGATION.find((url) => url === path)
          // check histories
          if (history.state.histories.length > 1) {
            enableBack.value = !isMainUrl
          }
          else { enableBack.value = false }
        }
    )
    return {
      isOpenNav,
      toggleNavigation,
      enableBack
    }
  }
})
export default App
</script>

<style lang="sass">
@import '@/style/css/master.sass'
.router-view
  padding-top: 2px
  padding-left: 8px
@media (max-width: 820px)
  .nav-hide
    padding-left: 2px
.v-main__wrap
  position: inherit !important
</style>
