import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { navGuard, authGuard } from '@/utils/Gaurd'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { urlPath } from '@/utils'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        ...urlPath.HOME,
        component: () => import(/* webpackChunkName: "home" */ '../pages/Home/index.vue')
    },
    {
        ...urlPath.TEST_TABLE,
        component: () => import('../pages/TestTable/index.vue')
    },
    {
        ...urlPath.UNIT_GROUP,
        component: () => import('../pages/Master/Unit/unitGroup.vue')
    },
    {
        ...urlPath.UNIT,
        component: () => import('../pages/Master/Unit/unit.vue')
    },
    {
        ...urlPath.ITEM,
        component: () => import('../pages/Master/Item/index.vue')
    },
    {
        ...urlPath.VARIETY,
        component: () => import('../pages/Master/Variety/index.vue')
    },
    {
        ...urlPath.SIZE_GROUP,
        component: () => import('../pages/Master/Size/sizeGroup.vue')
    },
    {
        ...urlPath.SIZE,
        component: () => import('../pages/Master/Size/size.vue')
    },
    {
        ...urlPath.BOX_TYPE_GROUP,
        component: () => import('../pages/Master/BoxType/boxTypeGroup.vue')
    },
    {
        ...urlPath.BOX_TYPE,
        component: () => import('../pages/Master/BoxType/boxType.vue')
    },
    {
        ...urlPath.QUALITY_GROUP,
        component: () => import('../pages/Master/Quality/qualityGroup.vue')
    },
    {
        ...urlPath.QUALITY,
        component: () => import('../pages/Master/Quality/quality.vue')
    },
    {
        ...urlPath.COLOR,
        component: () => import('../pages/Master/Color/index.vue')
    },
    {
        ...urlPath.POT_SIZE,
        component: () => import('../pages/Master/PotSize/index.vue')
    },
    {
        ...urlPath.SETTING,
        component: () => import('../pages/Master/Setting/index.vue')
    },
    {
        ...urlPath.COMMENT,
        component: () => import('../pages/Master/Comment/index.vue')
    },
    {
        ...urlPath.FEE,
        component: () => import('../pages/Master/Fee/index.vue')
    },
    {
        ...urlPath.LOGIN_CALLBACK,
        component: () =>
            import('../pages/LoginCallback/index.vue')
    },
    {
        ...urlPath.ASSIGNMENT,
        component: () =>
            import('../pages/Assignment/index.vue')
    },
    {
        ...urlPath.CUSTOMER,
        component: () =>
            import('../pages/Master/Customer/index.vue')
    },
    {
        ...urlPath.INVOICE,
        component: () => import('../pages/Invoice/index.vue')
    },
    {
      ...urlPath.PRESET_COLUMN,
      component: () =>
          import('../pages/Master/PresetColumn/index.vue')
    },
    {
        ...urlPath.INVOICE,
        component: () => import('../pages/Invoice/index.vue')
    },
    {
        ...urlPath.SALE,
        component: () => import('../pages/Sale/index.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(navGuard)
router.beforeEach(authGuard)

export default router
