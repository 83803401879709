<template lang="pug">
  div
    span {{ msg }}
    v-btn(text).clickable(@click="goto") {{ clickableTxt }}
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['msg', 'clickableTxt'],
  setup(props, { emit }) {
    const goto = () => {
      emit('goto')
    }
    return { goto }
  }
})
</script>
<style scoped lang="sass">
.clickable
  color: white !important
  font-weight: bold
</style>