<template lang="pug" xmlns="">

</template>

<script>
import { defineComponent } from 'vue'

const JCommonSearch = defineComponent({
  setup() {
    return {}
  }
})

export default JCommonSearch
</script>