import router from '../../router'
import Store from '../../store'
import { api, i18n } from '@/plugins'
import Vue, { getCurrentInstance } from "vue";
import { endpoints, showError, query, convertToWidthCharacter } from '@/utils'
import moment from 'moment'

export const replaceUrl = ({ query, params }) => {
    // type query and params is object
    // exp: {id: 1}
    router
        .replace({
            query: query,
            params: params
        })
        .catch((e) => {
            return
        })
}

export const numberToString = (number) => {
    return number ? number.toLocaleString('en-US', { maximumFractionDigits: 2 }) : ''
}

export const backPage = () => {
    const historiesStore = Store.getters.showHistories
    Store.commit('popHis')
    const lastRoute = historiesStore.histories[historiesStore.histories.length - 1]
    Store.commit('popHis')
    router.push(lastRoute)
}

export const getData = async (
    listData = ['color', 'quality', 'size', 'boxType', 'unit'], queryData = {}
) => {
    const result = {
        items: null,
        varieties: null,
        colors: null,
        qualities: null,
        sizes: null,
        boxTypes: null,
        units: null,
        qualityGroups: null,
        sizeGroups: null,
        unitGroups: null,
        boxTypeGroups: null,
        assignTypes: null,
        regions: null,
        comments: null,
        settings: null,
        potSizes: null,
        presetColumns: null,
        requiredColumns: null,
        saleBreakDowns: null
    }

    const queryItem = queryData.item ? query.buildQuery(queryData.item) : ''
    const queryVariety = queryData.variety ? query.buildQuery(queryData.variety) : ''
    const querySaleBreakDown = queryData.saleBreakDown ? query.buildQuery(queryData.saleBreakDown) : ''
    try {
        await Promise.all([
            listData.includes('item') ? api.get(`${endpoints.MASTER_ITEM}?${queryItem}`).then((value) => result.items = value.data) : null,
            listData.includes('variety') ? api.get(`${endpoints.MASTER_VARIETY}?${queryVariety}`).then((value) => result.varieties = value.data) : null,
            listData.includes('color') ? api.get(endpoints.MASTER_COLOR).then(value => result.colors = value.data) : null,
            listData.includes('quality') ? api.get(endpoints.MASTER_QUALITY).then(value => result.qualities = value.data) : null,
            listData.includes('size') ? api.get(endpoints.MASTER_SIZE).then(value => result.sizes = value.data) : null,
            listData.includes('boxType') ? api.get(endpoints.MASTER_BOX_TYPE).then(value => result.boxTypes = value.data) : null,
            listData.includes('unit') ? api.get(endpoints.MASTER_UNIT).then(value => result.units = value.data) : null,
            listData.includes('region') ? api.get(endpoints.MASTER_REGION).then(value => result.regions = value.data) : null,
            listData.includes('assignType') ? api.get(endpoints.MASTER_ASSIGN_TYPE).then(value => result.assignTypes = value.data) : null,
            listData.includes('comment') ? api.get(endpoints.MASTER_COMMENT).then(value => result.comments = value.data) : null,
            listData.includes('setting') ? api.get(endpoints.MASTER_SETTING).then(value => result.settings = value.data) : null,
            listData.includes('qualityGroup') ? api.get(endpoints.MASTER_QUALITY_GROUP).then(value => result.qualityGroups = value.data) : null,
            listData.includes('sizeGroup') ? api.get(endpoints.MASTER_SIZE_GROUP).then(value => result.sizeGroups = value.data) : null,
            listData.includes('boxTypeGroup') ? api.get(endpoints.MASTER_BOX_TYPE_GROUP).then(value => result.boxTypeGroups = value.data) : null,
            listData.includes('unitGroup') ? api.get(endpoints.MASTER_UNIT_GROUP).then(value => result.unitGroups = value.data) : null,
            listData.includes('potSizes') ? api.get(endpoints.MASTER_POT_SIZE).then(value => result.potSizes = value.data) : null,
            listData.includes('presetColumn') ? api.get(endpoints.MASTER_PRESET_COLUMN).then(value => result.presetColumns = value.data) : null,
            listData.includes('requiredColumn') ? api.get(endpoints.MASTER_REQUIRED_COLUMN).then(value => result.requiredColumns = value.data) : null,
            listData.includes('saleBreakDown') ? api.get(`${endpoints.SALE_BREAK_DOWN}?${querySaleBreakDown}`).then(value => result.saleBreakDowns = value.data) : null
        ])
    } catch (e) {
        showError(e, Vue.$toast, i18n.t('common.get_data_failed'))
    }
    return result
}
const returnDate = (year, month, date, format) => {
    let returnYear = year
    if (parseInt(year) < 100) {
        returnYear = `20${year}`
    } else if (year === null) {
        returnYear = new Date().getFullYear()
    }
    let lastDayOfMonth = moment(`${returnYear}-${month}`).endOf('month').format('DD')
    let dateOfMonth = date
    let monthOfYear = parseInt(month) - 1
    if (month > 12) {
        monthOfYear = 11
    }
    if (parseInt(date) > parseInt(lastDayOfMonth)) {
        dateOfMonth = lastDayOfMonth
    }
    if (lastDayOfMonth === 'Invalid date') {
        // if (year === null) {
        //     return `${month}${date}`
        // }
        // return `${year}${month}${date}`
        return null
    }
    return moment().year(returnYear).month(monthOfYear).date(dateOfMonth).format(format)
}
export const pickDate = (input, format = 'YYYY-MM-DD') => {
    input = convertToWidthCharacter(input, 'half')

    if (input === null) {
        return input
    }
    if (input === undefined) {
        return input
    }
    const v = input.trim()
    // 当月月末日を取得
    const last = moment()
        .endOf('month')
        .date()

    // 数値1-2桁のみ受け付ける
    switch (input.length) {
        case 1: {
            // 「a」なら月初
            if (v === 'a') {
                return moment()
                    .date(1)
                    .format(format)
            }
            // 「z」なら月末
            if (v === 'z') {
                return moment()
                    .date(last)
                    .format(format)
            }
            // 「0」なら当日
            if (v === '0') {
                return moment().format(format)
            }

            if (parseInt(v) > 0 && parseInt(v) < 10) {
                return moment()
                    .date(parseInt(v))
                    .format(format)
            }

            return v
        }
        case 2: {
            const p = /^([0-9]{1,2})$/
            if (!p.test(v)) {
                return v
            }
            // 当月月初から月末日までの日付分の数値を列挙
            const dates = [...Array(last).keys()].map(i => ++i)
            // 当月の有効な日付のみ受け付ける
            if (!dates.includes(parseInt(v, 10))) {
                return v
            }
            // 日付を設定して、有効な日付か最終チェック。
            const x = moment().date(v)
            if (x.isValid()) {
                return x.format(format)
            }
            return v
        }
        case 3: {
            let month3 = input.slice(0, 1)
            let day3 = input.slice(1, 3)
            return returnDate(null, '0' + month3, day3, format)
        }
        case 4: {
            let month4 = input.slice(0, 2)
            let day4 = input.slice(2, 4)
            return returnDate(null, month4, day4, format)
        }

        case 6: {
            let year6 = input.slice(0, 2)
            let month6 = input.slice(2, 4)
            let day6 = input.slice(4, 6)
            return returnDate(year6, month6, day6, format)
        }
        case 8: {
            let year8 = input.slice(0, 4)
            let month8 = input.slice(4, 6)
            let day8 = input.slice(6, 8)
            return returnDate(year8, month8, day8, format)
        }
        case 10: {
            let year10 = input.slice(0, 4)
            let month10 = input.slice(5, 7)
            let day10 = input.slice(8, 10)
            return returnDate(year10, month10, day10, format)
        }
        default:
            return null
    }
}