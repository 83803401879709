<template lang="pug">
  j-dialog-container(
    ref="dialog_container"
    :label="$t('master.setting.edit')"
    mode="update"
    :loading="loading"
    @on-close="closeDialog"
    @on-update="updateSetting"
    v-model="show"
  )
    div(v-if="setting.name !== $t('master.setting.keys.product_type')")
      v-text-field#key.my-6(
        ref="key"
        autofocus
        :label="setting.name"
        v-model="setting.value"
        @keyup.enter.native="updateSetting"
      )
    div.pt-4(v-else)
      span.setting-name {{setting.name}}
      div.pl-4(
        :label="setting.name"
      )
        v-radio-group(v-model='setting.value')
          v-radio(
            v-for="prdtp in productTypes"
            :key="prdtp.key"
            :label="prdtp.name"
            :value="prdtp.name"
          )     

</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance} from 'vue'
import JDialogContainer from '../JDialogContainer/index'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {api} from '@/plugins'
import {endpoints, showError, toSnakeCase} from '@/utils'

const JSettingDialog = defineComponent({
  components: {
    JDialogContainer,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    setting: {
      type: Object,
      required: true,
      default: null
    }
  },
  setup(props, {emit}) {
    const instance = getCurrentInstance()
    const {$toast, $root} = instance.proxy
    const loading = ref(false)
    const deleteLoading = ref(false)
    const {setting} = toRefs(props)
    const valueFirst = ref('')
    const productTypes = [
      {
        key: "pot",
        name: "鉢・苗"
      },
      {
        key: "cut",
        name: "切花"
      },
      {
        key: "allTypes",
        name: "全分類"
      }
    ]

    const settingData = ref({})

    const generateBody = () => {
      return {
        id: setting.value.id,
        odrnum: setting.value.id,
        key: setting.value.key,
        value: setting.value.value.trim()
      }
    }

    const closeDialog = () => {
      loading.value = false
      deleteLoading.value = false
      emit('on-close', valueFirst.value)
    }

    // update setting group
    const updateSetting = async () => {
      loading.value = true
      const body = toSnakeCase(generateBody())
      try {
        await api.put(`${endpoints.MASTER_SETTING}`, body)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    watch(
      () => props.show,
      () => {
        if (props.show) {
         settingData.value = JSON.parse(JSON.stringify(setting.value))
         valueFirst.value = settingData.value.value // set value before change
        }
      }
    )

    return {
      closeDialog,
      loading,
      settingData,
      deleteLoading,
      updateSetting,
      productTypes
    }
  }
})

export default JSettingDialog
</script>

<style lang="sass">
.setting-name
  color: black  
  </style>
