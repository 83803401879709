
import { defineComponent } from 'vue'
import draggable from 'vuedraggable'

const DraggableContainer = defineComponent({
  components: {
    draggable
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  }
})

export default DraggableContainer
