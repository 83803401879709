<template lang="pug">
  j-master-dialog(
    ref="master_dialog"
    :show="show"
    :master-data="unitGroupData"
    :loading="loading"
    :is-add="isAdd"
    :label="isAdd ? $t('master.unit_master.unit_group.add') : $t('master.unit_master.unit_group.edit')"
    :label-name="$t('master.unit_master.unit.name')"
    @on-close="closeDialog"
    @on-update="updateUnitGroup"
    @on-create="createUnitGroup"
    @on-typing="onTyping"
    @on-click-input="focusInput = $event"
  )

</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance} from 'vue'
import JMasterDialog from '../JMasterDialog/index'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {api} from '@/plugins'
import {endpoints, master, showError} from '@/utils'

const JUnitGroupDialog = defineComponent({
  components: {
    JMasterDialog,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    unitGroup: {
      type: Object,
      required: true,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup(props, {emit}) {
    const instance = getCurrentInstance()
    const {$toast, $refs, $root} = instance.proxy
    const loading = ref(false)
    const {unitGroup} = toRefs(props)
    const LIST_STATES = ['code', 'name', 'yomi', 'name_short', 'name_eng'] // input order'
    const focusInput = ref('code')

    const unitGroupInit = {
      code: null,
      name: null,
      yomi: null,
      name_short: null,
      name_eng: null
    }
    const unitGroupData = ref({})

    const closeDialog = () => {
      loading.value = false
      emit('on-close')
    }

    // create new
    const createUnitGroup = async () => {
      loading.value = true
      try {
        await api.post(endpoints.MASTER_UNIT_GROUP, unitGroupData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      } finally {
        loading.value = false
      }
    }
    // update unit group
    const updateUnitGroup = async () => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_UNIT_GROUP}`, unitGroupData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const onTyping = (event) => {
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    watch(
      () => props.show,
      () => {
        focusInput.value = 'code'
        if (props.show) {
          if (props.isAdd) unitGroupData.value = JSON.parse(JSON.stringify(unitGroupInit))
          else unitGroupData.value = JSON.parse(JSON.stringify(unitGroup.value))
        }
      }
    )

    return {
      closeDialog,
      loading,
      unitGroupData,
      updateUnitGroup,
      createUnitGroup,
      onTyping,
      focusInput
    }
  }
})

export default JUnitGroupDialog
</script>
