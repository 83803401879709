<template lang="pug">
  div
    v-row.title
      v-col
        span {{ $t('master.customer.manager_setting') }}
      v-col(align="right")
        v-btn.white--text(color="primary" style="opacity: 0.7" @click="createDialog")
          span {{ $t('master.customer.add_manager') }}
    div(v-for="item in listMember" :key="item.id")
      list-member(
        v-if="item.mode!=='delete'"
        :item="item"
        @click-item="openItemDialog(item)"
      )
    member-edit-dialog(
      :isShow="show"
      :itemProps="dialogValue"
      :isAdd="isAdd"
      :cusID="customerID"
      @on-reload="getListMember"
      @close-dialog="closeDialog"
      @add-member="setNewMember"
      @update-member="setUpdateMember"
      @delete-member="setDeleteMember"
    )
    .no-value(v-if="listMember.length === 0")
      span まだ担当者はいません。
</template>

<script lang="js">
import { defineComponent, ref, Ref, watch, getCurrentInstance } from 'vue'
import { api } from '@/plugins'
import { endpoints, showError } from '@/utils'
import _ from 'lodash'
import ListMember from '../ListMember/index.vue'
import MemberEditDialog from '../MemberEditDialog/index.vue'

const MasterCustomerMember = defineComponent({
  components: {
    ListMember,
    MemberEditDialog
  },
  props: {
    customerID: {
      types: Number || undefined,
      required: true
    }
  },
  setup(props, { emit }) {
    const { $toast, $root } = getCurrentInstance().proxy
    const listMember = ref([]) // list to display all member of each customer
    const show = ref(false) // check to show dialog
    const isAdd = ref(true) // check create/edit case
    const dialogValue = ref({}) // 1 item obj has been choose

    const setNewMember = (members) => {
      const instance = getCurrentInstance()
      listMember.value.push(members)
      instance?.proxy?.$forceUpdate()
    }

    const setUpdateMember = (member) => {
      const index = _.findIndex(listMember.value, (item) => {
        return item.id === member.id
      })
      listMember.value[index] = member
    }

    const setDeleteMember = (member) => {
      const index = _.findIndex(listMember.value, (item) => {
        return item.id === member.id
      })
      listMember.value[index] = member
    }

    const getListMember = async () => {
      if (props.customerID !== undefined) {
        try {
          const id = props.customerID
          const data = await api.get(`${endpoints.CUSTOMER_MEMBER}?cst_id=${id}`)
          listMember.value = data.data.map((i) => {
            return { id: i.id, name: i.name, email: i.email, cst_id: i.cst_id }
          })
          emit('list-member', listMember.value)
        } catch (e) {
          showError(e, $toast, $root.$t('common.get_data_failed'))
        }
      }
    }
    getListMember()

    // click each item: open 1 item has been choose dialog + get this item info
    const openItemDialog = (item = null) => {
      if (item) {
        dialogValue.value = item
        isAdd.value = false
      } else {
        dialogValue.value = {}
        isAdd.value = true
      }
      show.value = true
    }

    // click Create button to create new item
    const createDialog = () => {
      show.value = true
      isAdd.value = true
      dialogValue.value = {}
    }

    const closeDialog = () => {
      show.value = false
      getListMember()
    }

    watch(
      () => props.customerID,
      () => {
        getListMember()
      }
    )

    return {
      listMember,
      getListMember,
      openItemDialog,
      closeDialog,
      show,
      dialogValue,
      isAdd,
      createDialog,
      setNewMember,
      setUpdateMember,
      setDeleteMember
    }
  }
})
export default MasterCustomerMember
</script>

<style lang="sass" scoped>
.title
  font-weight: bold
.no-value
  color: gray
  font-style: italic
  text-align-last: center
</style>
