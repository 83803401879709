<template lang="pug">
  j-dialog-container(
    :value="show"
    :label="$t('mix_content.title')"
    :width="1500"
    mode="create"
    @on-close="$emit('on-close')"
    @on-create="onSave"
  )
    v-list-item.pa-0.info-assign
      span {{ assignment.item }}
      span.ml-3 {{ assignment.variety }}
      span.ml-3 {{ assignment.quality }}{{ assignment.size }}
      span.ml-3 {{ assignment.quantity }}{{ assignment.unit }}
      span.ml-3 {{ assignment.box_type }}
      span.ml-3 ({{ assignment.box }} {{ $t('mix_content.case') }})
    span.msg-mix {{ $t('mix_content.msg') }}
    j-common-form-table(
      :columns="columns"
      :rows="mixContents"
      :show-show-hide-dialog="false"
      :size-groups="sizeGroups"
      :box-type-groups="boxTypeGroups"
      :unit-groups="unitGroups"
      :quality-groups="qualityGroups"
      :colors="colors"
      width="1450px"
      @on-input="onInput"
      @on-searching-select-box="onSearchingSelectBox"
    )
</template>

<script>
import { defineComponent, ref, toRefs, watch, getCurrentInstance } from 'vue'
import JCommonFormTable from '../JCommonFormTable/index.vue'
import JDialogContainer from '../JDialogContainer/index'
import { initColumnsMixedProduct } from './index'
import { addDataDropDown, getSelectItems, initRow, isNotEmptyRow, endpoints, showError } from '@/utils'
import { api } from '@/plugins'

const MixedProduct = defineComponent({
  props: {
    show: { default: false },
    assignment: { required: true },
    sizes: { default: () => [] },
    qualities: { default: () => [] },
    units: { default: () => [] },
    items: { default: () => [] },
    sizeGroups: { default: () => [] },
    boxTypeGroups: { default: () => [] },
    unitGroups: { default: () => [] },
    qualityGroups: { default: () => [] },
    colors: { default: () => [] }
  },
  components: { JCommonFormTable, JDialogContainer },
  setup(props, { emit }) {
    const { $toast, $root } = getCurrentInstance().proxy
    const columns = ref(initColumnsMixedProduct())
    const columnNames = columns.value.map((column => column.name))
    const mixContents = ref([])
    const varieties = ref([])
    const { items, sizes, qualities, units, assignment, sizeGroups, boxTypeGroups, unitGroups, qualityGroups, colors } = toRefs(props)

    const init = () => {
      mixContents.value = assignment.value.mix_content
        ? assignment.value.mix_content.map(mc => {
            return {
              size: mc.siz_txt,
              quality: mc.qua_txt,
              item: mc.itm_txt,
              variety: mc.vrt_txt,
              unit: mc.unt_txt,
              quantity: mc.quantity,
              unit_group: units.value.find(unit => unit.id === mc.unt_id).untg.id,
              quality_group: qualities.value.find(quality => quality.id === mc.qua_id).quag.id,
              size_group: sizes.value.find(size => size.id === mc.siz_id).sizg.id
            }
          }).concat(initRow(columnNames))
        : initRow(columnNames)
      columns.value = addDataDropDown(columns.value, {
        size: sizes.value,
        quality: qualities.value,
        unit: units.value
      })
    }

    const onInput = (data) => {
      const index = data.rows.indexOf(data.dataInput.row)
      const curVarRow = varieties.value.find(vari => vari.name_short === data.dataInput.row.variety)
      const curItemRow = props.items.find(item => item.name_short === data.dataInput.row.item)
      if (data.dataInput.column.name === 'item') {
        mixContents.value = data.rows
        mixContents.value[index].variety = null
        columns.value = addDataDropDown(columns.value, {variety: []})
        if (curItemRow) {
          mixContents.value[index].quality = curItemRow.qua?.name_short || null
          mixContents.value[index].size = curItemRow.siz?.name_short || null
          mixContents.value[index].unit = curItemRow.unt?.name_short || null
          mixContents.value[index].quantity = curItemRow.quantity || null
          mixContents.value[index].quality_group = curItemRow.quag?.id || null
          mixContents.value[index].unit_group = curItemRow.untg?.id || null
          mixContents.value[index].size_group = curItemRow.sizg?.id || null
        }
      } else if (data.dataInput.column.name === 'variety') {
        if (mixContents.value[index].item !== null) {
          curItemRow.value = null
          if (curVarRow) {
            mixContents.value[index].quality = (curVarRow.qua?.name_short || curItemRow.qua?.name_short) || null
            mixContents.value[index].size = (curVarRow.siz?.name_short || curItemRow.siz?.name_short) || null
            mixContents.value[index].unit = (curVarRow.unt?.name_short || curItemRow.unt?.name_short) || null
            mixContents.value[index].quantity = (curVarRow.quantity || curItemRow.quantity) || null
            mixContents.value[index].quality_group = (curVarRow.quag?.id || curItemRow.quag?.id) || null
            mixContents.value[index].unit_group = (curVarRow.untg?.id || curItemRow.untg?.id) || null
            mixContents.value[index].size_group = (curVarRow.sizg?.id || curItemRow.sizg?.id) || null
          }
        }
      }
    }

    const onSearchingSelectBox = async (dataInput) => {
      columns.value = await getSelectItems(
        columns.value, dataInput, {
          items: items.value, sizes: sizes.value, qualities: qualities.value, units: units.value
        }
      )
      if (dataInput.column.name === 'variety') {
        varieties.value = columns.value.find(c => c.name === 'variety').items
      }
    }

    const getBody = () => {
      return mixContents.value?.filter(mixContent => isNotEmptyRow(mixContent)).map((mixContent, index) => {
        return {
          itm_txt: mixContent.item,
          vrt_txt: mixContent.variety,
          qua_txt: mixContent.quality,
          siz_txt: mixContent.size,
          unt_txt: mixContent.unit,
          quantity: mixContent.quantity,
          odrnum: index + 1
        }
      })
    }

    const onSave = async () => {
      const body = getBody()
      try {
        const { data } = await api.put(`${endpoints.ASSIGNMENTS}${assignment.value.id}`, body)
        $toast.success($root.$t('common.msg.update_success'))
        emit('on-close')
        emit('update-mix-content', data)
      } catch (e) {
        showError(e, $toast, $root.$t('common.msg.update_failed'))
      }
    }

    watch(
      () => props.show,
      () => {
        if (props.show) init()
      }
    )

    return {
      columns,
      mixContents,
      onInput,
      onSearchingSelectBox,
      onSave
    }
  }
})

export default MixedProduct
</script>

<style lang="sass">
.msg-mix
  font-style: italic
.info-assign
  span
    font-size: 20px
    color: #111111
</style>