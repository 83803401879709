<template lang="pug">
  div
    button.nav-hide(:value="!value" @click="$emit('toggle')")
      v-btn.btn-icon(icon)
        v-icon(x-large color="white") mdi-pan-right

    v-navigation-drawer(
      app
      left
      fixed
      temporary
      :value="value"
      @input="drawer => $emit('input', drawer)"
    )
      v-list-item
        v-spacer
        v-icon.primary--text(@click="$emit('toggle')") mdi-backburger
      v-list(expand)
        v-list-group(
          value="group"
          v-for="menu in menus"
          :key="menu.title"
          :prepend-icon="menu.icon"
        )
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title {{ menu.title }}
          v-list-group(
            no-action
            sub-group
            :value="true"
            v-for="child in menu.children"
            :prepend-icon="child.children2 ? 'mdi-menu-down' : ''"
            :key="child.title"
            )
            template(v-slot:activator)
              v-list-item-content(@click="onClickMenu(child)")
                v-list-item-title(:class="child.children2 ? '' : 'no-icon'") {{ child.title }}
            v-list-item(
              v-for="child2 in child.children2"
              :key="child2.title"
              link
              :to="{ path: child2.path }"
            )
              v-list-item-title {{ child2.title }}
</template>

<script>
import {defineComponent, ref} from 'vue'
import {MENU} from './menu'
import router from '@/router'

const JNavigationMenu = defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const group = ref(null)
    const menus = MENU

    const onClickMenu = (menu) => {
      if (menu.name) {
        router.push({ name: menu.name })
      }
    }

    return {
      group,
      menus,
      onClickMenu
    }
  }
})
export default JNavigationMenu
</script>

<style scoped lang="sass">
@import '@/style/css/common.sass'
.nav-hide
  position: fixed
  text-align: left
  height: 100vh
  width: 18px
  background-color: #92aaa369 !important
  box-shadow: 5px -5px 10px -5px rgba(128, 128, 128, 0.75)

  .v-icon
    font-size: x-large
    padding-right: 10px

  .btn-icon
    opacity: 0.5 !important
    background-color: rgba(46, 96, 67, 0.73)
    position: absolute
    left: 2px
    top: 15px
    box-shadow: 0 0 2px 2px rgba(218, 216, 216, 0.49)

.nav-show
  position: absolute
  text-align: left
  height: 100vh
  width: 250px
  background-color: white

.title
  font-weight: bold
  color: var(--v-primary-base)
  font-size: 18px

.child
  .link
    color: $rough-black
    text-underline-position: under

  .text
    font-size: 18px

::v-deep .v-list .v-list-item--active
  color: var(--v-primary-base)

::v-deep .v-list-item .v-list-item__title
  color: $rough-black

.no-icon
  padding-left: 40px

@media (max-width: 820px)
  .nav-hide
    width: 0
</style>