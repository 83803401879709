<template lang="pug">
  j-dialog-container(
    :value="show"
    :label="$t('remark_content.title')"
    :width="1000"
    mode="create"
    @on-close="$emit('on-close')"
    @on-create="onSaveRemark"
    height="561"
  )
    v-list-item.pa-0.info-assign
      span {{ assignment.item }}
      span.ml-3 {{ assignment.variety }}
      span.ml-3 {{ assignment.quality }}{{ assignment.size }}
      span.ml-3 {{ assignment.quantity }}{{ assignment.unit }}
      span.ml-3 {{ assignment.box_type }}
      span.ml-3(v-if="assignment.box") ({{ assignment.box }} {{ $t('mix_content.case') }})
    v-row.pt-2.pb-0.mb-0
      v-col(style="text-align: center")
        v-textarea(
          :label="$t('master.remark')"
          auto-grow
          outlined
          rows="3"
          row-height="96vh"
          shaped
          v-model="remarkContent"
          autofocus
        )
        v-row.justify-center
          .pb-0.mb-0.code
            v-text-field(
              :label="$t('master.code')"
              outlined
              dense
              single-line
              v-model="newCode"
            )
          v-btn.px-5.mx-5.mt-1(outlined @click="addToMaster").primary--text {{ $t('remark_content.save_to_master') }}
      v-col
        v-row.search.ml-1
          v-text-field.mb-1.mr-4.input(
            :label="searchText"
            outlined
            dense
            v-model="searchInfo"
            prepend-inner-icon="mdi-magnify"
            @input="handleSearchInput"
            color="primary"
          )
        v-row.main
          v-data-table.table(
            v-model="selected"
            :headers="headers"
            :items="items"
            hide-default-footer
            item-key="name"
            :items-per-page="-1"
            :show-select="false"
            class="elevation-2"
            fixed-header
            mobile-breakpoint="0"
            height="26vh"
          )
            template(v-slot:no-data)
              span {{ $t('common.no_data') }}
            template(v-slot:item.action="{ item }")
              v-btn(outlined @click="addRemark(item)").primary--text {{ $t('common.choose') }}
</template>

<script>
import { defineComponent, ref, toRefs, watch, getCurrentInstance, onMounted } from 'vue'
import JCommonFormTable from '../JCommonFormTable/index.vue'
import JDialogContainer from '../JDialogContainer/index'
import { addDataDropDown, getSelectItems, initRow, isNotEmptyRow, endpoints, showError, replaceUrl } from '@/utils'
import { api } from '@/plugins'
import {debounce} from "lodash"
import router from '@/router'

const JRemarkDialog = defineComponent({
  props: {
    show: { default: false },
    assignment: { required: true }
  },
  components: { JCommonFormTable, JDialogContainer },
  setup(props, { emit }) {
    const { $toast, $root } = getCurrentInstance().proxy
    const { assignment } = toRefs(props)
    const searchText = '備考を検索'
    const { search: searchInfoValue } = router.currentRoute.query
    const searchInfo = ref(searchInfoValue || '')
    const selected = ref([])
    const items = ref([]) // list comment get from database (comment master)
    const remarkContent = ref('')
    const newCode = ref(null)
    const headers = [
      {
        text: $root.$t('common.code'),
        sortable: false,
        value: 'code',
        width: 100,
        class: 'primary'
      },
      {
        text: $root.$t('master.comment.name'),
        sortable: false,
        value: 'name',
        class: 'primary'
      },
      {
        text: '',
        align: 'right',
        sortable: false,
        value: 'action',
        class: 'primary'
      }
    ]

    // get comment/remark
    const getComment = async () => {
      try {
        const { data } = await api.get(`${endpoints.MASTER_COMMENT}?search_input=${searchInfo.value}`)
        items.value = data
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.get_data_failed'))
      }
    }

    const handleSearchInput = debounce((value) => {
      getComment()
      replaceUrl({query: { search: searchInfo.value }}) //change in url
    }, 500)

    // add remark from master -> to text-area
    const addRemark = (item) => {
      const remarks = [remarkContent.value]
      remarks.push(item.name)
      remarkContent.value = remarks.toString().replace(',',' ')
    }

    const reload = () => {
      getComment()
    }

    // add remark from text area -> to master
    const addToMaster = async () => {
      const commentData = ref({
        code: newCode.value ? newCode.value : null,
        name: remarkContent.value
      })
      try {
        await api.post(endpoints.MASTER_COMMENT, commentData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        reload()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      }
    }

    const onSaveRemark = async () => {
      assignment.value.remark = remarkContent.value
      emit('update-remark', assignment.value)
      emit('on-close')
    }

    onMounted(getComment)

    watch(
        () => props.assignment.remark,
        () => {
          remarkContent.value = props.assignment.remark
        }
    )

    return {
      searchText,
      headers,
      items,
      getComment,
      handleSearchInput,
      selected,
      searchInfo,
      addRemark,
      remarkContent,
      addToMaster,
      onSaveRemark,
      newCode
    }
  }
})

export default JRemarkDialog
</script>

<style lang="sass" scoped>
.search
  padding-top: 12px
  display: flex

  .divider
    background-color: var(--v-primary-base)

  .input
    .v-input__slot
      background: #ffffff
      min-height: 40px !important

    .v-input__slot:hover
      background: #ffffff !important

  .categories-list
    position: absolute
    left: 8px
    right: 8px
    z-index: 4
    overflow: auto
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5)

    .category-title
      padding: 4px

      .v-icon
        color: var(--v-primary-base)
        padding: 2px !important
.main
  margin: 0 auto
  .table
    width: -webkit-fill-available
.text-start
  padding: 0
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th
  color: white !important
  font-weight: bold
  font-size: 22px
.code
  width: 90px
</style>