<template lang="pug">
  div
    .panel-items
      .table-control-panel(v-if="showShowHideDialog")
        v-menu(
          v-model="menu"
          top
          :close-on-content-click="false"
        )
          template(v-slot:activator="{ on, attrs }")
            v-row.py-4.ml-3.mr-3
              v-btn.add-item-btn(
                color='primary'
                outlined
                v-bind="attrs"
                v-on="on"
              ) {{$t('table_common.hide_columns')}}
              div.save-btn.pr-1
                slot(name="save")
          v-list.pa-2
            v-autocomplete(
              outlined
              hide-details
              dense
              height="30px"
              :items="presetColumns"
              item-text="name"
              item-value="id"
              @input="$event => $emit('on-select-preset', $event)"
              :value="presetColumn"
            )
              template(v-slot:no-data)
                span.grey--text {{ $t('common.no_data') }}
            v-list-item.px-2.check-box(
              dense
              v-for="(column, index) in columns.filter(c => !c?.disable)"
              :key="index"
              :disabled="(domId === 'CUT' && column.name === 'pot_size') || column.showRequired"
              @click.stop="$emit('show-column', column)"
            )
              v-icon.ml-2.active(
                v-if="column.isActive" :disabled="column.showRequired"
              ) mdi-check-circle
              v-icon.ml-2.active(
                v-if="!column.isActive" :disabled="column.showRequired"
              ) mdi-checkbox-blank-circle-outline
              span.ml-2.mt-1.pointer {{$t(`table_common.${column.name}`)}}
              span.red--text(v-if="column.required") *
            v-list-item
              v-container.pt-0.pb-0
                v-btn(outlined color='rgb(104 159 56)' width="100%" @click="$emit('on-save-default-column'), menu = false") {{ $t('common.save_default_customer') }}
      .wrapper-table-container(:id="'wrapper-table-container-'+ domId")
        div.table-container
          f-editable-table.items-table(
            :domId="'table-container-' + domId"
            :style="width? `min-width: ${width}` : ''"
            :column-sizes.sync='columnSizes'
            :columns="columns"
            :rows="rows"
            :context-actions="actions"
            :showFooter="showFooter"
            @on-searching-select-box="$emit('on-searching-select-box', $event), currentSearchInput = $event.searchInput"
            @input='onInput($event)'
            @even-right-click="eventInRightClick"
            @reorder="onReorder"
            @bodyclick="onBodyClick"
            @cell-enter="(event)=>onEnterCell(event)"
          )
            template(v-slot:header='{column}')
              span {{$t(`table_common.${column.name}`)}}
              span.red--text {{column.required ? '*' : ''}}
            template(v-slot:total)
              slot(name="total")
            template(v-slot:cell-input-dropdown-item-icon='{dropdownItem}')
              al-item-icon(:icon='dropdownItem.value')
              span.ml-2 {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-assign_type='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-pot_size='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-region='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-rgn_origin='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-fee='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-item='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-variety='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-color='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-quality='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-size='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-unit='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-input-dropdown-item-box_type='{dropdownItem}')
              v-row.px-0.mx-0.dropdown-item
                v-col.px-0.mx-0(cols="2")
                  div.pl-2 {{dropdownItem.code}}
                v-col.px-0.mx-0(cols="10")
                  span {{dropdownItem.name_short}}
            template(v-slot:cell-variety='{row, cell}')
              span(:class='{"variety--inactive": row.active == false}') {{cell}}
                v-badge.inactive__badge(
                  v-if='row.active == false',
                  :content='$t("common.inactive")'
                  color='#CACACA'
                  offset-x='2'
                  offset-y='-2'
                )
            template(v-slot:cell-images='{row}')
              icon(v-if='row.item' :name="'image'" :item-val='row' :color="row.images && row.images > 0 ? 'primary' : 'grey'" @on-click-icon="$emit('on-click-icon', $event)")
            template(v-slot:cell-mix_content='{row}')
              icon(v-if='row.item' :name="'mix_content'" :item-val='row' :color="row.mix_content && row.mix_content.length > 0 ? 'primary' : 'grey'" @on-click-icon="$emit('on-click-icon', $event)")
            template(v-slot:cell-remark='{row, cell}')
              div.align-left(
                :class="'three-dots'"
              ) {{ cell }}
            template(v-slot:cell-input-dropdown-no-data-unit='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(v-if="row ? (!!row.unit_group) : false" @click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-size='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(v-if="row ? (!!row.size_group) : false" @click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-box_type='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(v-if="row ? (!!row.box_type_group) : false" @click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-color='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(@click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-item='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(@click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-quality='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(v-if="row ? (!!row.quality_group) : false" @click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-variety='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(v-if="row ? (!!row.item) : false" @click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}
            template(v-slot:cell-input-dropdown-no-data-pot_size='{row, column}')
              span.pl-5.grey--text {{$t('common.no_data')}}
              div(@click="addNewMaster(column, row)")
                v-row.ma-0.pa-0(justify="center")
                  a {{`'${currentSearchInput??''}'${$t('table_common.add_new_master')}`}}

            template(v-for='(_, slot) of $scopedSlots' v-slot:[slot]='scope')
              slot(v-bind='scope', :name='slot')
      //v-btn.sort-items-btn.ml-2(color='primary' outlined @click='sortItems({panelIndex: index})')
      //  v-icon(left) mdi-swap-vertical
      //  | {{$tc('actions.sort_item', 1)}}

    j-size-dialog(
      :show="isShowSizeDialog"
      :is-add="true"
      :sizg="currentRow ? (currentRow.size_group ? currentRow.size_group : 0) : 0"
      :prev-input="currentSearchInput"
      :groups="sizeGroups"
      @on-close="isShowSizeDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, addNew: {name: 'size', value: $event}})"
    )
    j-box-type-dialog(
      :show="isShowBoxTypeDialog"
      :is-add="true"
      :bxtg="currentRow ? (currentRow.box_type_group ? currentRow.box_type_group : 0) : 0"
      :prev-input="currentSearchInput"
      :groups="boxTypeGroups"
      @on-close="isShowBoxTypeDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'box-type', value: $event}})"
    )
    j-color-dialog(
      :show="isShowColorDialog"
      :is-add="true"
      :prev-input="currentSearchInput"
      @on-close="isShowColorDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'color', value: $event}})"
    )
    j-item-dialog(
      :show="isShowItemDialog"
      :is-add="true"
      :prev-input="currentSearchInput"
      :unit-groups-props="unitGroups"
      :size-groups-props="sizeGroups"
      :quality-groups-props="qualityGroups"
      :box-type-groups-props="boxTypeGroups"
      :colors-props="colors"
      @on-close="isShowItemDialog =false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'item', value: $event}})"
    )
    j-quality-dialog(
      :show="isShowQualityDialog"
      :groups="qualityGroups"
      :is-add="true"
      :quag="currentRow ? (currentRow.quality_group ? currentRow.quality_group : 0) : 0"
      :prev-input="currentSearchInput"
      @on-close="isShowQualityDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'quality', value: $event}})"
    )
    j-unit-dialog(
      :show="isShowUnitDialog"
      :is-add="true"
      :untg="currentRow ? (currentRow.unit_group ? currentRow.unit_group : 0) : 0"
      :groups="unitGroups"
      :prev-input="currentSearchInput"
      @on-close="isShowUnitDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'unit', value: $event}})"
    )
    j-variety-dialog(
      :show="isShowVarietyDialog"
      :item="itemValue"
      :is-add="true"
      :prev-input="currentSearchInput"
      :unit-groups-props="unitGroups"
      :size-groups-props="sizeGroups"
      :quality-groups-props="qualityGroups"
      :box-type-groups-props="boxTypeGroups"
      :colors-props="colors"
      @on-close="isShowVarietyDialog =false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'variety', value: $event}})"
    )
    j-pot-size-dialog(
      :show="isShowPotSizeDialog"
      :is-add="true"
      :prev-input="currentSearchInput"
      @on-close="isShowPotSizeDialog = false"
      @on-reload="onInput({row: currentRow, column: currentColumn, value: $event.name_short, newMaster: {name: 'pot_size', value: $event}})"
    )
</template>

<script>
import { defineComponent, getCurrentInstance, onMounted, toRefs, ref, onUnmounted } from 'vue'
import JSizeDialog from '../JSizeDialog/index.vue'
import JBoxTypeDialog from '../JBoxTypeDialog/index.vue'
import JColorDialog from '../JColorDialog/index.vue'
import JItemDialog from '../JItemDialog/index.vue'
import JQualityDialog from '../JQualityDialog/index.vue'
import JUnitDialog from '../JUnitDialog/index.vue'
import JVarietyDialog from '../JVarietyDialog/index.vue'
import JPotSizeDialog from '../JPotSizeDialog/index.vue'
import { FEditableTable, ProductIcon, AlItemIcon, JHelp } from '@/components'
import { addOneRow, addRows, deleteRows, copyRows, autoAddEmptyRow } from '@/utils'
import { ProductType } from '@/utils/constants'
import Icon from './Images/Icon.vue'
import { api } from '@/plugins'
import { endpoints, showError } from '@/utils'
import { DEFAULT_ACTIONS } from './index'

const JCommonFormTable = defineComponent({
  components: {
    FEditableTable,
    ProductIcon,
    AlItemIcon,
    JHelp,
    Icon,
    JBoxTypeDialog,
    JColorDialog,
    JItemDialog,
    JQualityDialog,
    JSizeDialog,
    JUnitDialog,
    JVarietyDialog,
    JPotSizeDialog
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    keyUpdate: {
      type: Number,
      default: 0
    },
    domId: {
      type: String,
      required: false,
      default: null
    },
    sizeGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    unitGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    boxTypeGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    qualityGroups: {
      type: Array,
      required: false,
      default: () => []
    },
    colors: {
      type: Array,
      required: false,
      default: () => []
    },
    width: {
      default: null
    },
    showShowHideDialog: {
      default: true
    },
    presetColumns: {
      default: () => []
    },
    presetColumn: {
      default: null
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: false
    },
    actions: {
      type: Array,
      required: false,
      default: () => DEFAULT_ACTIONS
    }
  },
  setup(props, { emit }) {
    const { $root, $toast } = getCurrentInstance().proxy
    const { rows } = toRefs(props)
    const currentRow = ref(null)
    const currentColumn = ref(null)
    const currentSearchInput = ref('')
    const menu = ref(false)
    const isShowSizeDialog = ref(false)
    const isShowBoxTypeDialog = ref(false)
    const isShowColorDialog = ref(false)
    const isShowItemDialog = ref(false)
    const isShowQualityDialog = ref(false)
    const isShowUnitDialog = ref(false)
    const isShowVarietyDialog = ref(false)
    const isShowPotSizeDialog = ref(false)
    const itemValue = ref({})

    const remarkValue = ''

    // const rows = ref(initRow(columns.value))

    const eventInRightClick = (event) => {
      /*{
        "event": "handle_add_new_row",
        "actionPayload": {
          "row": {...},
          "rowIndex": 0,
          "column": {...},
          "columnIndex": 4,
          "rows": [
            {...},
            {...}
          ],
          "rowIndexes": [0],
          "columns": [{...}],
          "columnIndexes": [4]
        }
      }*/
      const actionPayload = event.actionPayload
      const numberRowSelect = actionPayload.rows.length
      const firstRowIndex = actionPayload.rowIndexes[0]
      const lastRowIndex = actionPayload.rowIndexes[numberRowSelect - 1]

      if (event.event === 'handle_add_new_row_above') {
        rows.value = addRows(rows.value, numberRowSelect, firstRowIndex, true)
      }
      if (event.event === 'handle_add_new_row_below') {
        rows.value = addRows(rows.value, numberRowSelect, lastRowIndex)
      }
      if (event.event === 'handle_delete_rows') {
        const [newRows, delRow] = deleteRows(rows.value, numberRowSelect, firstRowIndex)
        const delRowList = delRow.map(row => {
          return { deleted_asn_id: row.id, deleted_asn_version: row.version }
        })
        rows.value = newRows
        emit('on-delete-rows', { rows: rows.value, delRowList })
      }
      if (event.event === 'handle_copy_rows') {
        rows.value = copyRows(rows.value, actionPayload.rows, lastRowIndex)
        emit('on-copy-rows', { rows: rows.value })
      }
      if (event.event === 'handle_open_history') {
        emit('on-open-history', firstRowIndex)
      }
    }

    const columnSizes = {}

    const onReorder = (newRows) => {
      emit("on-change", newRows.rows)
    }

    const onBodyClick = (curCursor) => {
      emit('on-body-click', { row: rows.value[curCursor.rowIndex], column: props.columns[curCursor.columnIndex] })
    }

    const onInput = (dataInput) => {
      if (dataInput.row) {
        // update new data into row exists
        const index = rows.value.indexOf(dataInput.row)
        rows.value[index][dataInput.column.name] = dataInput.value
        // Auto calculate stem, amount if inputting in box or quantity
        if (dataInput.column.name === 'box' || dataInput.column.name === 'quantity') {
          // convert value from full width to half width
          const box = (typeof rows.value[index].box === 'string' && rows.value[index].box) ? rows.value[index].box.normalize('NFKD') : rows.value[index].box
          const quantity = (typeof rows.value[index].quantity === 'string' && rows.value[index].quantity) ? rows.value[index].quantity.normalize('NFKD') : rows.value[index].quantity
          if (box && quantity) rows.value[index].stem = box * quantity

          const stem = (typeof rows.value[index].stem === 'string' && rows.value[index].stem) ? rows.value[index].stem.normalize('NFKD') : rows.value[index].stem
          const price = (typeof rows.value[index].price === 'string' && rows.value[index].price) ? rows.value[index].price.normalize('NFKD') : rows.value[index].price

          if (stem && price) rows.value[index].amount = price * stem
        }
        // Auto calculate amount if inputting in price or quantity
        if (dataInput.column.name === 'price' || dataInput.column.name === 'stem') {
          // convert value from full width to half width
          const price = (typeof rows.value[index].price === 'string' && rows.value[index].price) ? rows.value[index].price.normalize('NFKD') : rows.value[index].price
          const stem = (typeof rows.value[index].stem === 'string' && rows.value[index].stem) ? rows.value[index].stem.normalize('NFKD') : rows.value[index].stem

          if (stem && price) rows.value[index].amount = price * stem
        }
        autoAddEmptyRow(rows.value, index)
      } else {
        // if row not exists => create new row with data is dataInput.value
        const dataRow = {}
        dataRow[dataInput.column.name] = dataInput.value
        rows.value = JSON.parse(JSON.stringify(addOneRow(rows.value, null, dataRow)))
      }
      emit('on-input', { rows: rows.value, dataInput: dataInput })
    }

    const addNewMaster = async (column, row) => {
      currentRow.value = row
      currentColumn.value = column
      switch (column.name) {
        case 'item':
          isShowItemDialog.value = true
          break
        case 'variety':
          try {
            const { data } = await api.get(`${endpoints.MASTER_ITEM}`)
            itemValue.value = data?.find((item) => item.name_short === currentRow.value.item) || {}
          } catch (e) {
            showError(e, $toast, $root.$t('master.msg.get_data_failed'))
          }
          isShowVarietyDialog.value = true
          break
        case 'size':
          isShowSizeDialog.value = true
          break
        case 'box_type':
          isShowBoxTypeDialog.value = true
          break
        case 'color':
          isShowColorDialog.value = true
          break
        case 'quality':
          isShowQualityDialog.value = true
          break
        case 'unit':
          isShowUnitDialog.value = true
          break
        case 'pot_size':
          isShowPotSizeDialog.value = true
          break
      }
    }

    const index = 0
    const onEnterCell = (event) => {
      emit('on-enter-cell', { row: rows.value[event.cursor.rowIndex], column: event.column })
    }
    onMounted(() => {
      const wrapperScrollbarTop = document.getElementById('wrapper-scrollbar-top-' + props.domId)
      const wrapperTableContainer = document.getElementById('wrapper-table-container-' + props.domId)
      const tableContainer = document.getElementById('table-container-' + props.domId)
      let resizeObs = new ResizeObserver(entries => {
        for (let entry of entries) {
          const cr = entry.contentRect
          const scrollbarTopContainer = document.getElementById('scrollbar-top-container-' + props.domId)
          // padding left 4px, padding right 4px, need to plus 8px
          scrollbarTopContainer.style.width = cr.width.toString() + 'px'
        }
      })
      if (tableContainer && wrapperTableContainer && wrapperScrollbarTop) {
        resizeObs.observe(tableContainer)
        wrapperScrollbarTop.onscroll = function () {
          wrapperTableContainer.scrollLeft = wrapperScrollbarTop.scrollLeft
        }
        wrapperTableContainer.onscroll = function () {
          wrapperScrollbarTop.scrollLeft = wrapperTableContainer.scrollLeft
        }
        onUnmounted(() => {
          resizeObs.unobserve(tableContainer)
        })
      }
    })
    return {
      columnSizes,
      onInput,
      index,
      eventInRightClick,
      menu,
      onReorder,
      isShowItemDialog,
      isShowSizeDialog,
      isShowBoxTypeDialog,
      isShowColorDialog,
      isShowQualityDialog,
      isShowUnitDialog,
      isShowVarietyDialog,
      isShowPotSizeDialog,
      itemValue,
      addNewMaster,
      currentRow,
      currentColumn,
      currentSearchInput,
      onBodyClick,
      remarkValue,
      onEnterCell,
      ProductType
    }
  }
})

export default JCommonFormTable
</script>

<style scoped lang="sass">
$table-min-width: 1610
$sidebar-width: 100
$padding-width: 16
.active
  color: rgb(104 159 56)
.table-control-panel
  text-align: left
  margin-bottom: 5px
  padding: 0 0 0 5px

.wrapper-table-container
  width: 100%
  overflow-x: scroll
  overflow-y: hidden
.three-dots
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
  width: 150px /* some width */
.align-left
  text-align: left
.table-container
  width: 100%
.panel-items
  margin-top: 5px
  //width: calc(100%)
  text-align: center
  //overflow-x: scroll

.f-editable-table.items-table
  margin-bottom: 8px
  min-width: #{$table-min-width}px
  background-color: #ffffff

  ::v-deep
    // Disable these border due to it's overlapping the sheet's border
    td:first-child, th:first-child
      border-left: none

    td:last-child, th:last-child
      border-right: none
    // Adjust Header Stick to the top
    @media (min-width: #{$table-min-width + $sidebar-width + $padding-width}px)
      [class^="f-editable-table__header--"]
        position: sticky
        top: 0
        height: 48px
    // Adjust widths of columns
    th.f-editable-table__header
      @mixin width($width)
        width: $width
        min-width: $width

      &--assign_type
        @include width(90px)

      &--buyer_info
        @include width(90px)

      &--region, &--rgn_origin
        @include width(70px)

      &--item
        @include width(150px)

      &--variety
        width: 20%
        min-width: 150px

      &--color, &--images, &--count_flower, &--count_stem
        @include width(36px)

      &--pot_size
        @include width(72px)

      &--quantity, &--quantity, &--unit, &--box_type, &--quality, &--size
        @include width(72px)

      &--price, &--box, &--stem, &--remark, &--amount
        @include width(72px)
      &--mix_content
        @include width(36px)
      &--fee
        @include width(350px)
      &--rate
        @include width(150px)
    td.f-editable-table__cell
      &--price, &--quantity, &--pieces_per_unit, &--box, &--stem, &--amount
        text-align: right
        padding-right: 4px

      &--sold_out
        .v-input
          margin-top: 0px

        .v-input__control
          align-items: center

        .v-input__slot
          width: 25px

          .v-input--selection-controls__input
            margin-right: 0px

    .cell-input
      &--price input, &--quantity input, &--box input, &--stem input, &--amount input
        text-align: right
        padding-right: 4px

      &--icon, &--cutpot
        .v-input .v-input__append-inner
          display: none

  .v-text-field
    margin-top: 0
    padding-top: 0

  .inactive__badge ::v-deep span
    color: #6F6F6F

  ::v-deep
    .v-autocomplete.v-select.v-input--is-focused input
      min-width: 52px
.dropdown-item
  cursor: pointer
.save-btn
  position: absolute
  right: 0
.v-menu__content
  min-width: 0 !important
.check-box
  min-height: 35px
.pointer
  cursor: pointer
</style>