import { api,  i18n } from '@/plugins'
import { PICTURES } from '../apiEndpoints'
import Vue from 'vue'

export const createOrDeletePicture = async ( objidKey, newPictures, deletePictureIds ) => {
  let isSuccessAll = true
  if (deletePictureIds.length > 0) {
    try {
      await Promise.all(
        deletePictureIds.map(async (id) => {
          api.delete(`${PICTURES}${id}`)
        })
      )
    } catch (e) {
      Vue.$toast.error(i18n.t('master.msg.cannot_delete_picture'))
      isSuccessAll = false   
    }
  }

  await Promise.all(
    newPictures.map(async (picture) => {
      try {
        const { data } = await api.post(PICTURES, {
          ...picture,
          objid: objidKey
        })
        return data
      } catch(e) {
        Vue.$toast.error(i18n.t('master.msg.wrong_picture_format'))
        isSuccessAll = false        
      }    
    })
  )
  return isSuccessAll
}
