export const initColumnsBreakDown = () => {
    return [
        {
            name: 'auction_date',
            header: true,
            isActive: true,
            required: true,
            enterSkip: false,
            type: 'date'
        },
        {
            name: 'sale_stem',
            header: true,
            isActive: true,
            required: true,
            enterSkip: false,
            type: 'number'
        },
        {
            name: 'sale_amount',
            header: true,
            isActive: true,
            required: true,
            enterSkip: false,
            type: 'number'
        },
        {
            name: 'remaining',
            header: true,
            isActive: true,
            type: 'number',
            enterSkip: true,
            onEnableEditMode: () => false
        }
    ]
}