export const Columns = [
    'bundle_no',
    'assign_type',
    'buyer_info',
    'region',
    'rgn_origin',
    'item',
    'variety',
    'color',
    'images',
    'quality',
    'size',
    'pot_size',
    'count_flower',
    'count_stem',
    'quantity',
    'unit',
    'box',
    'box_type',
    'stem',
    'price',
    'amount',
    'remark',
    'mix_content'
]

export const RequiredColumns = [
    'assign_type',
    'item',
    'variety',
    'box'
]