<template lang="pug">
  v-app-bar(app color="white" dark elevation="1")
    v-row.ma-0.pa-0(align="center" justify="space-between")
      v-col.ma-0.pa-0(cols="4")
        logo

      v-col.pa-0.ma-0(cols="4")
        v-row.pa-0(justify="center")
          v-btn(
            rounded
            v-if="back"
            icon
            color="primary"
            @click="goBack"
          )
            v-icon(large) mdi-keyboard-backspace
          v-toolbar-title.header-text.primary--text {{$t($route.query.gr_name) ? $t($route.query.gr_name) + $t('common.no_ja') + $t($route.name) : $t($route.name) }}
          //portal-target(name="header-title")


      v-col.ma-0.pa-0.pr-2(cols="4")
        v-row.ma-0(justify="end")
          profile-menu
          //portal-target(name="header-action")
</template>

<script>
import {defineComponent} from 'vue'
import Logo from './Logo'
import ProfileMenu from './ProfileMenu'
import { backPage } from '../../utils'

const JHeaderMenu = defineComponent({
  components: {Logo, ProfileMenu},
  props: {
    back: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    const goBack = () => {
      backPage()
    }
    return {
      goBack
    }
  }
})
export default JHeaderMenu
</script>

<style lang="sass" scoped>
@import '@/style/css/common.sass'
.header-text
  font-size: 30px
  font-weight: bold
</style>