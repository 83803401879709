const TEST_TABLE = { path: '/test_table', name: 'test_table' }
const HOME = { path: '/', name: 'home.title' }
const UNIT_GROUP = { path: '/master/unit_group/', name: 'master.unit_master.unit_group.title' }
const UNIT = { path: '/master/unit/', name: 'master.unit_master.unit.title' }
const ITEM = { path: '/master/item/', name: 'master.item.title' }
const PRESET_COLUMN = { path: '/master/preset_column/', name: 'master.preset_column.title' }
const VARIETY = { path: '/master/variety/', name: 'master.variety.title' }
const SIZE_GROUP = { path: '/master/size_group/', name: 'master.size_master.size_group.title' }
const SIZE = { path: '/master/size/', name: 'master.size_master.size.title' }
const BOX_TYPE_GROUP = { path: '/master/box_type_group/', name: 'master.box_type_master.box_type_group.title' }
const BOX_TYPE = { path: '/master/box_type/', name: 'master.box_type_master.box_type.title' }
const QUALITY_GROUP = { path: '/master/quality_group/', name: 'master.quality_master.quality_group.title' }
const QUALITY = { path: '/master/quality/', name: 'master.quality_master.quality.title' }
const COLOR = { path: '/master/color/', name: 'master.color.title' }
const CUSTOMER = { path: '/master/customer/', name: 'master.customer.title' }
const LOGIN_CALLBACK = { path: '/account/callback/', name: 'login.callback' }
const ASSIGNMENT = { path: '/assignment/', name: 'assignment.title' }
const SETTING = { path: '/master/setting/', name: 'master.setting.title' }
const COMMENT = { path: '/master/comment/', name: 'master.comment.title' }
const FEE = { path: '/master/fee/', name: 'master.fee.title' }
const POT_SIZE = { path: '/master/pot_size/', name: 'master.pot_size.title' }
const INVOICE = { path: '/invoice/', name: 'invoice.title' }
const SALE = { path: '/sale/', name: 'sale.title' }


const DISABLE_NAVIGATION = [
    HOME.path
]

export {
    DISABLE_NAVIGATION,
    TEST_TABLE,
    HOME,
    UNIT_GROUP,
    UNIT,
    BOX_TYPE_GROUP,
    BOX_TYPE,
    QUALITY_GROUP,
    QUALITY,
    ITEM,
    VARIETY,
    SIZE,
    SIZE_GROUP,
    COLOR,
    CUSTOMER,
    LOGIN_CALLBACK,
    ASSIGNMENT,
    SETTING,
    COMMENT,
    PRESET_COLUMN,
    POT_SIZE,
    INVOICE,
    SALE,
    FEE
}