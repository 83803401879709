export const STATES = [
    'code',
    'name',
    'yomi',
    'name_short',
    'owner_name',
    'tel',
    'fax',
    'email',
    'zip',
    'address_pref',
    'address_1',
    'address_2',
    'cst_id_billing',
    'billing_name',
    'billing_dept',
    'billing_picname',
    'billing_tel',
    'billing_fax',
    'billing_zip',
    'billing_address_pref',
    'billing_address_1',
    'billing_address_2',
    'usage_fee_rate_pot',
    'usage_fee_rate_cut'
] // input order

export const CUSTOMER_INIT = {
    code: null,
    name: null,
    yomi: null,
    name_short: null,
    owner_name: null,
    tel: null,
    fax: null,
    email: null,
    zip: null,
    address_pref: null,
    address_1: null,
    address_2: null,
    cst_id_billing: null,
    billing_name: null,
    billing_dept: null,
    billing_picname: null,
    billing_tel: null,
    billing_fax: null,
    billing_zip: null,
    billing_address_pref: null,
    billing_address_1: null,
    billing_address_2: null,
    usage_fee_rate_pot: null,
    usage_fee_rate_cut: null
}

export const copyData = (isCopy, customerData) => {
    if (isCopy) {
        customerData.billing_name = customerData.name
        customerData.billing_tel = customerData.tel
        customerData.billing_fax = customerData.fax
        customerData.billing_zip = customerData.zip
        customerData.billing_address_pref = customerData.address_pref
        customerData.billing_address_1 = customerData.address_1
        customerData.billing_address_2 = customerData.address_2
    } else {
        customerData.billing_name = null
        customerData.billing_tel = null
        customerData.billing_fax = null
        customerData.billing_zip = null
        customerData.billing_address_pref = null
        customerData.billing_address_1 = null
        customerData.billing_address_2 = null
    }
    return customerData
}
