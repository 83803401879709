<template lang="pug">
  div
    div(v-if="orderByNum.length")
      draggable-container(v-model="orderByNum" @input="updateData($event)")
        div.dragging-wrapper(v-for="item in orderByNum" :key="JSON.stringify(item)")
          v-list-item
            span {{item.code}}
            span(style="margin-left: 25%; position: absolute") {{item.name_short ? item.name_short : item.name}}
            v-spacer
            v-btn(icon @click.stop="changeItemPriority(item.id, 'down')")
              v-icon mdi-chevron-double-down
          v-divider
    div.pt-3
      .separated-line.py-1.pl-3
        span {{$t('master.msg.items_sorted_by_name')}}
      div(v-for="item in orderNotByNum" :key="item.id" style="background: white")
        v-list-item(:key="JSON.stringify(item)")
          span {{item.code}}
          span(style="margin-left: 25%; position: absolute") {{item.name_short ? item.name_short : item.name}}
          v-spacer
          v-btn(icon @click.stop="changeItemPriority(item.id, 'up')")
            v-icon.list-item-icon mdi-chevron-double-up
        v-divider
</template>

<script lang="js">
import {defineComponent, toRefs, ref, watch} from 'vue'
import {orderBy} from 'lodash'
import DraggableContainer from '../DraggableContainer/index.vue'

const JMasterChangeOrderList = defineComponent({
  components: {
    DraggableContainer
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  setup(props, {emit}) {
    const {value} = toRefs(props)
    const orderByNum = ref([])
    const orderNotByNum = ref([])
    const items = ref([])

    const separateData = () => {
      const byNum = items.value.filter((item) => item.odrnum !== null)
      const notByYomi = items.value.filter((item) => item.odrnum === null)

      orderByNum.value = orderBy([...byNum], 'odrnum')
      orderNotByNum.value = notByYomi
    }

    const updateData = (events) => {
      const updatedOrder = events.map((el, index) => {
        // eslint-disable-next-line no-param-reassign
        el.odrnum = index + 1
        return el
      })
      orderByNum.value = JSON.parse(JSON.stringify(updatedOrder))
    }

    const changeItemPriority = (itemID, priority) => {
      const isGoUp = priority === 'up'
      const index = items.value.findIndex((e) => e.id === itemID)

      if (isGoUp) {
        // will be recalculate later
        items.value[index].odrnum = orderByNum.value.length + 1
      } else {
        items.value[index].odrnum = null
        let newOrderNum = 1
        orderByNum.value = orderByNum.value.forEach((item) => {
          if (item.odrnum !== null) {
            item.odrnum = newOrderNum++
          }
        })
      }

      separateData()
    }

    const calculateHeight = (itemLength) => {
      let height
      if (itemLength * 50 < window.innerHeight) {
        height = itemLength * 50
      } else {
        height = window.innerHeight
      }
      return height
    }

    watch(
      value,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          items.value = newVal
          separateData()
        }
      },
      {immediate: true}
    )
    watch(orderByNum, () => {
      const updated = orderByNum.value.concat(orderNotByNum.value)
      emit('input', updated)
    })
    return {
      orderByNum,
      orderNotByNum,
      items,
      changeItemPriority,
      updateData,
      calculateHeight
    }
  }
})

export default JMasterChangeOrderList
</script>

<style scoped lang="sass">
.actions-button
  bottom: 44px

.separated-line
  background-color: gray
  font-weight: 500
  color: #f7f7f7
</style>
