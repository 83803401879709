<template lang="pug">
  v-data-table.main.mb-8(
    v-model="selected"
    :headers="headersRef"
    :items="itemsRef"
    hide-default-footer
    :single-select="singleSelect"
    item-key="name"
    :items-per-page="-1"
    :show-select="false"
    class="elevation-2"
    :style="maxWidth ? 'max-width: ' + maxWidth.toString() + 'px;' : ''"
    fixed-header
    mobile-breakpoint="0"
    @click:row="handleClick"
  )
    template(v-slot:item.name="{ item }")
      slot(name="name" :item="item")
    template(v-slot:item.action="{ item }")
      slot(name="action" :item="item")
    template(v-slot:no-data)
      span {{ $t('common.no_data') }}
    template(v-slot:item.box="{ item }")
      span {{numberToString(item.box)}}
    template(v-slot:item.stem="{ item }")
      span {{numberToString(item.stem)}}
    template(v-slot:item.amount="{ item }")
      span {{numberToString(item.amount)}}
    template(v-slot:item.sale_amount="{ item }")
      span {{numberToString(item.sale_amount)}}
    template(v-slot:item.remaining_pot="{ item }")
      span {{numberToString(item.remaining_pot)}}
    template(v-slot:item.remaining_cut="{ item }")
      span {{numberToString(item.remaining_cut)}}
    template(v-slot:item.remaining="{ item }")
      span {{numberToString(item.remaining)}}
    template(v-slot:item.sale_stem="{ item }")
      span {{numberToString(item.sale_stem)}}
    template(v-slot:item.tax_amount="{ item }")
      span {{numberToString(item.tax_amount)}}
    template(v-slot:item.sale_amount_with_tax="{ item }")
      span {{numberToString(item.sale_amount_with_tax)}}
    template(v-slot:item.usage_fee="{ item }")
      span {{numberToString(item.usage_fee)}}
    template(v-slot:item.fee_amount="{ item }")
      span {{numberToString(item.fee_amount)}}
    template(v-slot:item.deduction_amount="{ item }")
      span {{numberToString(item.deduction_amount)}}

</template>
<script>
import {defineComponent, onMounted, ref, watch} from 'vue'
import { numberToString } from '@/utils'

const CommonTable = defineComponent({
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    maxWidth: {
      type: Number,
      default: 1000,
      required: false
    }
  },
  setup(props, {emit}) {
    const headersRef = ref([])
    const itemsRef = ref([])
    const singleSelect = ref(false)
    const selected = ref([])

    const handleClick = (row) => {
      emit('on-click', row)
    }

    const generateTable = () => {
      headersRef.value = props.headers
      itemsRef.value = props.items
    }

    onMounted(() => {
      generateTable()
    })
    watch(props, () => {
      generateTable()
    })
    return {
      singleSelect,
      selected,
      headersRef,
      itemsRef,
      handleClick,
      numberToString
    }
  }
})

export default CommonTable
</script>

<style scoped lang="sass">
.main
  margin: 0 auto
.text-start
  padding: 0
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th
  color: white !important
  font-weight: bold
  font-size: 22px
</style>
