// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { i18n } from '@/plugins'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { urlPath } from '@/utils'
// import moment from 'moment'

// const date = moment(new Date()).format('YYYY-MM-DD')

export const MENU = [
  {
    title: i18n.t('menu.assign.assign') as string,
    icon: 'mdi-flower-pollen',
    children: [
      {
        title: i18n.t('menu.assign.assign') as string,
        name: urlPath.ASSIGNMENT.name
      },
      {
        title: i18n.t('menu.assign.invoice') as string,
        name: urlPath.INVOICE.name
      },
      {
        title: i18n.t('menu.statistics.sale') as string,
        name: urlPath.SALE.name
      }
    ]
  },
  {
    title: i18n.t('menu.statistics.title') as string,
    icon: 'mdi-sale-outline'
  },
  {
    title: i18n.t('menu.master.title') as string,
    icon: 'mdi-cogs',
    children: [
      {
        title: i18n.t('menu.master.product') as string,
        children2: [
          {
            title: i18n.t('master.item.title') as string,
            path: '/master/item'
          },
          {
            title: i18n.t('master.color.title') as string,
            path: '/master/color'
          },
          {
            title: i18n.t('master.quality_master.quality.title') as string,
            path: '/master/quality_group'
          },
          {
            title: i18n.t('master.size_master.size.title') as string,
            path: `/master/size_group`
          },
          {
            title: i18n.t('master.box_type_master.box_type.title') as string,
            path: '/master/box_type_group'
          },
          {
            title: i18n.t('master.unit_master.unit.title') as string,
            path: `/master/unit_group`
          },
          {
            title: i18n.t('master.comment.title') as string,
            path: `/master/comment`
          },
          {
            title: i18n.t('master.pot_size.title') as string,
            path: `/master/pot_size`
          },
          {
            title: i18n.t('master.preset_column.title') as string,
            path: `/master/preset_column`
          },
          {
            title: i18n.t('master.fee.title') as string,
            path: `/master/fee`
          }
        ]
      },
      {
        title: i18n.t('menu.master.customer') as string,
        children2: [
          {
            title: i18n.t('master.customer.title') as string,
            path: '/master/customer'
          }
        ]
      },
      {
        title: i18n.t('master.setting.title') as string,
        name: urlPath.SETTING.name
      }
    ]
  }
]

