<template lang="pug">
  div.value-type(
    v-on:click="clickItem(item)"
  )
    v-row.ma-0.pa-0(align='center')
      v-col.pr-0.pl-2.ma-0(cols="3")
        span {{ item.name }}
      v-col.pa-0.ma-0
        slot
          v-text-field.pr-1(
            disabled
            flat
            solo
            reverse
            readonly
            hide-details
            :label="item.email"
          )
      v-col.pa-0.ma-0(cols="1")
        v-icon mdi-chevron-right
    v-divider
</template>

<script lang="js">
import { defineComponent } from 'vue'

const ListMember = defineComponent({
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  setup(props, { emit }) {
    const clickItem = (item) => {
      emit('click-item', item)
    }
    return {
      clickItem
    }
  }
})

export default ListMember
</script>

<style lang="sass" scoped></style>
