export const TransmissionTypes = Object.freeze({
  SEND_BY_MAIL: 'mail',
  SEND_BY_FAX: 'fax',
  CSV_DATA: 'data',
  DOWNLOAD_PDF: 'download'
})
export const TransmissionStatus = Object.freeze({
  SUCCESS: 'success',
  FAILED: 'fail',
  PENDING: 'pending'
})
export const Delivery = Object.freeze({
  SEND_D1: 'd1',
  SEND_D2: 'd2'
})
const widthWindow = window.innerWidth
export const MaxLengthRemark = widthWindow <= 1200 ? 15 : (widthWindow <= 1360 ? 35 : 70)