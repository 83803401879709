<template lang="pug">
  .icon
    v-icon(v-if='name == "mix_content"' @click="clickItem()" :color='color') mdi-playlist-edit
    v-btn(icon v-if='name == "image" && itemVal.variety' @click="clickItem() " :color='color')      
      v-icon mdi-image-outline
      span.num-pic(v-if='itemVal.images > 0') {{ itemVal.images }}
</template>

<script>
import { defineComponent, ref, toRefs } from 'vue'

const Icon = defineComponent({
  props: {
    itemVal: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      default: 'image'
    },
    color: {
      type: String,
      default: 'grey'
    }
  },
  setup(props, {emit}) {
    const { itemVal, name } = toRefs(props)
    const isOpened = ref(false)
    const clickItem = () => {
      const data = {
        value: itemVal.value,
        name: name.value
      }
      if (itemVal.value.variety === null)
        isOpened.value = true
      emit('on-click-icon', data)
    }

    return {
      isOpened,
      clickItem
    }
  }
})

export default Icon
</script>

<style lang="sass" scoped>
.icon
  position: relative
.num-pic
  font-size: 12px !important
</style>
<i18n>
en:

</i18n>
