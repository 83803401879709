<template lang="pug">
  div
    v-btn.pa-0.button-header(
      v-if="showReset"
      icon
      width="40"
      height="35"
      color="white"
      @click.stop="reset"
    )
      v-icon mdi-reload
    v-btn.pa-0.button-header(
      icon
      width="40"
      height="35"
      color="white"
      @click="save"
    )
      v-icon mdi-content-save
</template>

<script>
import {defineComponent} from 'vue'

const JSortActionButton = defineComponent({
  props: {
    showReset: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, {emit}) {
    const save = () => {
      emit('on-save')
    }
    const reset = () => {
      emit('on-reset')
    }
    // const cancel = () => {
    //   emit('on-cancel')
    // }

    return {save, reset}
  }
})

export default JSortActionButton
</script>

<style lang="sass" scoped>
@import '@/style/css/common.sass'
</style>
