// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { i18n } from '@/plugins'

export const DEFAULT_ACTIONS = [
  {
    icon: 'mdi-table-row-plus-before',
    label: i18n.t('common.add_row_before'),
    handler: 'handle_add_new_row_above'
  },
  {
    icon: 'mdi-table-row-plus-after',
    label:  i18n.t('common.add_row_after'),
    handler: 'handle_add_new_row_below'
  },
  {
    icon: "mdi-content-copy",
    label:  i18n.t('common.copy'),
    handler: 'handle_copy_rows'
  },
  {
    divider: true
  },
  {
    icon: 'mdi-delete',
    label:  i18n.t('common.delete'),
    handler: 'handle_delete_rows'
  }
]