<template lang="pug">
  v-tooltip(v-if='icon', :disabled='!enableTooltip' top)
    template(v-slot:activator='{on}')
      i(v-on='on', :class='`shipping-type al-item-icon--${icon}`')
    span(v-if='enableTooltip') {{`al_item_icon.${toUnderscore(icon)}`}}
</template>

<style lang="sass">
i.al-item-icon
  &--new
    background-image: url('@/assets/al-item-icon-new.svg')

  &--special_offer
    background-image: url('@/assets/al-item-icon-special_offer.svg')

  &--recommended
    background-image: url('@/assets/al-item-icon-recommended.svg')

  &--rare
    background-image: url('@/assets/al-item-icon-rare.svg')
</style>

<script>
export default {
  props: {
    icon: {
      type: String || null,
      default: null
    },
    enableTooltip: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toUnderscore(str) {
      return str.replace(/-/g, '_')
    }
  }
}
</script>

<!--<i18n src="@/messages.yaml"></i18n>-->
