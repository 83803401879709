<template lang="pug">
  div
    j-item-form(
      ref="master_dialog"
      :show="show"
      :master-data="itemData"
      :loading="loading"
      :is-add="isAdd"
      :label="isAdd ? $t('master.item.add') : $t('master.item.edit')"
      :qualityGroupList = "qualityGroupList"
      :sizeGroupList = "sizeGroupList"
      :unitGroupList = "unitGroupList"
      :boxTypeGroupList = "boxTypeGroupList"
      :colorList = "colorList"
      @on-close="closeDialog"
      @on-update="updateItem"
      @on-create="createItem"
      @on-typing="onTyping"
      @on-click-input="(event) => onClickInput(event)"
    )
      template(v-slot:top)
        v-row.pt-1
          v-col.px-1
            j-image-list(
              :images="itemData.pictures"
              @on-change="(input) => {pictures = input}"
              @on-remove="removeImage"
            )
    j-confirm-dialog(
      :showDialog="showConfirmDialog"
      :messages="confirmMsg"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.ok')"
      @on-cancel="showConfirmDialog=false"
      @on-ok="isAdd ? createItem(true) : updateItem(true), showConfirmDialog=false"
    )

</template>
<script>
import {defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted} from 'vue'
import { createOrDeletePicture } from 'utils'
import JItemForm from './jItemForm'
import JConfirmDialog from '../JConfirmDialog/index'
import JImageList from '../JImageList/index'

import { api } from '@/plugins'
import { endpoints, showError, master } from '@/utils'

const JItemDialog = defineComponent({
  components: {
    JItemForm,
    JConfirmDialog,
    JImageList
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    item: {
      type: Object,
      required: false,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    prevInput: {
      type: String,
      required: false,
      default: ''
    },
    unitGroupsProps: {
      type: Array,
      required: false,
      default: null
    },
    qualityGroupsProps: {
      type: Array,
      required: false,
      default: null
    },
    sizeGroupsProps: {
      type: Array,
      required: false,
      default: null
    },
    boxTypeGroupsProps: {
      type: Array,
      required: false,
      default: null
    },
    colorsProps: {
      type: Array,
      required: false,
      default: null
    }
  },
  setup(props, { emit }) {
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const { item } = toRefs(props)
    const loading = ref(false)
    const showConfirmDialog = ref(false)
    const confirmMsg = ref([])
    const LIST_STATES = [
      'id',
      'code',
      'name',
      'name_short',
      'yomi',
      'name_eng',
      'color',
      'quantity',
      'quality_group',
      'quality',
      'size_group',
      'size',
      'unit_group',
      'unit',
      'box_type_group',
      'box_type'
    ]// input order
    const SELECT_BOX_STATE_LIST = [
      'color',
      'quality_group',
      'quality',
      'size_group',
      'size',
      'unit_group',
      'unit',
      'box_type_group',
      'box_type'
    ] // inputs which are select box
    const focusInput = ref(LIST_STATES[0]) // focus default
    const enterKeydown = ref(0)
    const CREATE_ENDPOINT = `${endpoints.MASTER_ITEM}`
    const UPDATE_ENDPOINT = `${endpoints.MASTER_ITEM}`

    const pictures = ref([])
    const itemData = ref({})
    const listPictureRemove = ref([])
    const isSuccessAll = ref(true)

    const closeDialog = () => {
      listPictureRemove.value = []
      loading.value = false
      emit('on-close')
    }

    const uploadPicture = async (objidKey) => {
      itemData.value.pictures = pictures.value
      if (objidKey)  {
        const newPictures = pictures.value.filter((picture) => !picture.id)
        isSuccessAll.value = await createOrDeletePicture(objidKey, newPictures, listPictureRemove.value)
        listPictureRemove.value = []
      }
    }

    const removeImage = (id) => {
      listPictureRemove.value = [...listPictureRemove.value, id]
      itemData.value.pictures = pictures.value
    }

    const createItem = async (isDuplicate=false) => {
      loading.value = true
      try {
        const {data} = await api.post(CREATE_ENDPOINT + `?is_duplicate=${isDuplicate}`, itemData.value)
        await uploadPicture(data.objid)
        if (isSuccessAll.value)
          $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload', data)
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          confirmMsg.value = master.getErrorMessage(e)
          showConfirmDialog.value = true
        }
      } finally {
        loading.value = false
        closeDialog()
      }
    }

    const updateItem = async (isDuplicate=false) => {
      loading.value = true
      try {
        await api.put( UPDATE_ENDPOINT + `?is_duplicate=${isDuplicate}`, itemData.value)
        await uploadPicture(itemData.value.objid)
        if (isSuccessAll.value)
          $toast.success($root.$t('master.msg.create_successful'))
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          confirmMsg.value = master.getErrorMessage(e)
          showConfirmDialog.value = true
        }
      } finally {
        loading.value = false
        closeDialog()
        emit('on-reload')
      }
    }

    const onTyping = (event) => {
      enterKeydown.value = 0
      if (!SELECT_BOX_STATE_LIST.includes(focusInput.value)) {
        focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
      }
    }


    const init = async () => {
      focusInput.value = LIST_STATES[0]
      enterKeydown.value = 0
      if (props.show) {
        if (props.isAdd) {
          const itemInit = {
            code: null,
            name: props.prevInput,
            yomi: props.prevInput ? await master.toYomi(props.prevInput) : null,
            name_short: props.prevInput,
            name_eng: null,
            clr: null,
            quantity: null,
            quag: null,
            qua: null,
            sizg: null,
            siz: null,
            untg: null,
            unt: null,
            bxtg: null,
            bxt: null,
            vat_id: null,
            pictures: []
          }
          itemData.value = JSON.parse(JSON.stringify(itemInit))
        } else {
          itemData.value = JSON.parse(JSON.stringify(item.value))
          itemData.value.pictures = item.value.pictures
        }
      }
    }

    const onClickInput = (event) => {
      focusInput.value = event
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1]) {
        $refs.master_dialog.$refs[focusInput.value]?.blur()
      }
      if (SELECT_BOX_STATE_LIST.includes(focusInput.value)) {
        $refs.master_dialog.$refs[focusInput.value]?.blur()
        focusInput.value = LIST_STATES[LIST_STATES.indexOf(focusInput.value) + 1]
        $refs.master_dialog.$refs[focusInput.value]?.focus()
      }
    }
    const qualityGroupList = ref([])
    const getQualityGroup = async () => {
      if (props.qualityGroupsProps) {
        qualityGroupList.value = props.qualityGroupsProps
      } else {
        try {
          const { data } = await api.get(`${endpoints.MASTER_QUALITY_GROUP}`)
          qualityGroupList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    const sizeGroupList = ref([])
    const getSizeGroup = async () => {
      if (props.sizeGroupsProps) {
        sizeGroupList.value = props.sizeGroupsProps
      } else {
        try {
          const { data } = await api.get(`${endpoints.MASTER_SIZE_GROUP}`)
          sizeGroupList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    const unitGroupList = ref([])
    const getUnitGroup = async () => {
      if (props.unitGroupsProps) {
        unitGroupList.value = props.unitGroupsProps
      } else {
        try {
          const { data } = await api.get(`${endpoints.MASTER_UNIT_GROUP}`)
          unitGroupList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    const boxTypeGroupList = ref([])
    const getBoxTypeGroup = async () => {
      if (props.boxTypeGroupsProps) {
        boxTypeGroupList.value = props.boxTypeGroupsProps
      } else {
        try {
          const { data } = await api.get(`${endpoints.MASTER_BOX_TYPE_GROUP}`)
          boxTypeGroupList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    const colorList = ref([])
    const getColor = async () => {
      if (props.colorsProps) {
        colorList.value = props.colorsProps
      } else {
        try {
          const { data } = await api.get(`${endpoints.MASTER_COLOR}`)
          colorList.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }
    onMounted(() => {
      Promise.all([
        getQualityGroup(),
        getSizeGroup(),
        getUnitGroup(),
        getBoxTypeGroup(),
        getColor()
      ])
    })
    watch(
      () => props.show,
      () => {
        init()
      }
    )
    watch(
      () => [
        props.colorsProps, props.qualityGroupsProps,
        props.unitGroupsProps, props.sizeGroupsProps, props.boxTypeGroupsProps
      ],
      () => {
        colorList.value = props.colorsProps
        qualityGroupList.value = props.qualityGroupsProps
        unitGroupList.value = props.unitGroupsProps
        sizeGroupList.value = props.sizeGroupsProps
        boxTypeGroupList.value = props.boxTypeGroupsProps
      }
    )

    return {
      closeDialog,
      loading,
      itemData,
      updateItem,
      createItem,
      showConfirmDialog,
      confirmMsg,
      focusInput,
      onTyping,
      onClickInput,
      pictures,
      qualityGroupList,
      sizeGroupList,
      unitGroupList,
      boxTypeGroupList,
      colorList,
      removeImage
    }
  }
})

export default JItemDialog
</script>
