<template lang="pug">
  j-master-dialog(
    ref="master_dialog"
    :show="show"
    :master-data="qualityGroupData"
    :loading="loading"
    :is-add="isAdd"
    :label="isAdd ? $t('master.quality_master.quality_group.add') : $t('master.quality_master.quality_group.edit')"
    :label-name="$t('master.quality_master.quality.name')"
    @on-close="closeDialog"
    @on-update="updateQualityGroup"
    @on-create="createQualityGroup"
    @on-typing="onTyping"
    @on-click-input="focusInput = $event"
  )

</template>
<script>
import {defineComponent, toRefs, ref, watch, getCurrentInstance} from 'vue'
import JMasterDialog from '../JMasterDialog/index'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {api} from '@/plugins'
import {endpoints, master, showError} from '@/utils'

const JQualityGroupDialog = defineComponent({
  components: {
    JMasterDialog,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    qualityGroup: {
      type: Object,
      required: true,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  setup(props, {emit}) {
    const instance = getCurrentInstance()
    const {$toast, $refs, $root} = instance.proxy
    const loading = ref(false)
    const {qualityGroup} = toRefs(props)
    const LIST_STATES = ['code', 'name', 'yomi', 'name_short', 'name_eng'] // input order'
    const focusInput = ref('code')

    const qualityGroupInit = {
      code: null,
      name: null,
      yomi: null,
      name_short: null,
      name_eng: null
    }
    const qualityGroupData = ref({})

    const closeDialog = () => {
      loading.value = false
      emit('on-close')
    }

    // create new
    const createQualityGroup = async () => {
      loading.value = true
      try {
        await api.post(endpoints.MASTER_QUALITY_GROUP, qualityGroupData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.create_failed'))
      } finally {
        loading.value = false
      }
    }
    // update quality group
    const updateQualityGroup = async () => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_QUALITY_GROUP}`, qualityGroupData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const onTyping = (event) => {
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value)
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    watch(
      () => props.show,
      () => {
        focusInput.value = 'code'
        if (props.show) {
          if (props.isAdd) qualityGroupData.value = JSON.parse(JSON.stringify(qualityGroupInit))
          else qualityGroupData.value = JSON.parse(JSON.stringify(qualityGroup.value))
        }
      }
    )

    return {
      closeDialog,
      loading,
      qualityGroupData,
      updateQualityGroup,
      createQualityGroup,
      onTyping,
      focusInput
    }
  }
})

export default JQualityGroupDialog
</script>
