<template lang="pug">
  div
    j-fee-form(
      ref="master_dialog"
      :show="show"
      :master-data="feeData"
      :loading="loading"
      :is-add="isAdd"
      :label="isAdd ? $t('master.fee.add') : $t('master.fee.edit')"
      :label-name="$t('master.fee.name')"
      @on-close="closeDialog"
      @on-update="updateFee"
      @on-create="createFee"
      @on-typing="onTyping"
      @on-click-input="focusInput = $event"
    )
    j-confirm-dialog(
      :showDialog="showConfirmDialog"
      :messages="confirmMsg"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.ok')"
      @on-cancel="showConfirmDialog=false"
      @on-ok="isAdd ? createFee(true) : updateFee(true), showConfirmDialog=false"
    )

</template>
<script>
import { defineComponent, toRefs, ref, getCurrentInstance, watch } from 'vue'
import JConfirmDialog from '../JConfirmDialog/index'
import { api } from '@/plugins'
import { endpoints, showError, master } from '@/utils'
import JFeeForm from './jFeeForm'
const JFeeDialog = defineComponent({
  components: {
    JConfirmDialog,
    JFeeForm
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    fee: {
      type: Object,
      required: false,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    prevInput: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const { fee } = toRefs(props)
    const loading = ref(false)
    const showConfirmDialog = ref(false)
    const confirmMsg = ref([])
    const focusInput = ref('code') // focus default
    const enterKeydown = ref(0)
    const LIST_STATES = [
      'code', 'name', 'yomi',
      'price', 'basic_rate',
      'show_panel_name'
    ] // input order

    const feeData = ref({})

    const closeDialog = async () => {
      loading.value = false
      emit('on-close')
    }

    const createFee = async (isDuplicate=false) => {
      loading.value = true
      try {
        const { data } = await api.post(`${endpoints.MASTER_FEE}?is_duplicate=${isDuplicate}`, feeData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload', data)
        await closeDialog()
      } catch (e) {
        $toast.error($root.$t('master.msg.create_failed'))
        // if api return duplicate --> show dialog confirm and allow save in db
      } finally {
        loading.value = false
      }
    }

    const updateFee = async (isDuplicate=false) => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_FEE}?is_duplicate=${isDuplicate}`, feeData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        await closeDialog()
      } catch (e) {
        showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const onTyping = (event) => {
      enterKeydown.value = 0
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value, 'untg')
    }


    const init = async () => {
      focusInput.value = 'code'
      enterKeydown.value = 0
      if (props.show) {
        if (props.isAdd) {
          const feeInit = {
            code: null,
            name: props.prevInput,
            yomi: props.prevInput ? await master.toYomi(props.prevInput) : null,
            is_auto_calculate: true,
            is_percentage_based: true
          }
          feeData.value = JSON.parse(JSON.stringify(feeInit))
        } else {
          feeData.value = JSON.parse(JSON.stringify(fee.value))
        }
      }
    }

    watch(
      () => props.show,
      () => init()
    )

    return {
      closeDialog,
      loading,
      feeData,
      updateFee,
      createFee,
      showConfirmDialog,
      confirmMsg,
      focusInput,
      onTyping
    }
  }
})

export default JFeeDialog
</script>
