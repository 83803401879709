<template lang="pug">
  div
    j-master-dialog(
      ref="master_dialog"
      :show="show"
      :master-data="boxTypeData"
      :loading="loading"
      :is-add="isAdd"
      :label="isAdd ? $t('master.box_type_master.box_type.add') : $t('master.box_type_master.box_type.edit')"
      :label-name="$t('master.box_type_master.box_type.name')"
      @on-close="closeDialog"
      @on-update="updateBoxType"
      @on-create="createBoxType"
      @on-typing="onTyping"
      @on-click-input="focusInput = $event"
    )
      template(v-slot:after-top)
        v-autocomplete.pt-8(
          ref="bxtg"
          item-text="name"
          item-value="id"
          :items="boxTypeGroups"
          :label="$t('master.box_type_master.box_type_group.title')"
          v-model="boxTypeData.bxtg"
          @keydown="onFocus"
          disabled
        )
      template(v-slot:bottom)
        v-text-field(
          ref="bundle_size"
          type='number'
          :label="$t('master.bundle_size')"
          v-model="boxTypeData.bundle_size"
          @focus="focusInput = 'bundle_size'"
        )
    j-confirm-dialog(
      :showDialog="showConfirmDialog"
      :messages="confirmMsg"
      :cancel-text="$t('common.cancel')"
      :ok-text="$t('common.ok')"
      @on-cancel="showConfirmDialog=false"
      @on-ok="isAdd ? createBoxType(true) : updateBoxType(true), showConfirmDialog=false"
    )

</template>
<script>
import { defineComponent, toRefs, ref, getCurrentInstance, watch, onMounted } from 'vue'
import JMasterDialog from '../JMasterDialog/index'
import JConfirmDialog from '../JConfirmDialog/index'
import { api } from '@/plugins'
import { endpoints, showError, master } from '@/utils'

const JBoxTypeDialog = defineComponent({
  components: {
    JMasterDialog,
    JConfirmDialog
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    boxType: {
      type: Object,
      required: false,
      default: null
    },
    isAdd: {
      type: Boolean,
      required: false,
      default: true
    },
    bxtg: {
      type: Number,
      required: true
    },
    prevInput: {
      type: String,
      required: false,
      default: ''
    },
    groups: {
      type: Array,
      required: false,
      default: null
    }
  },
  setup(props, { emit }) {
    const { $toast, $root, $refs } = getCurrentInstance().proxy
    const { boxType } = toRefs(props)
    const loading = ref(false)
    const boxTypeGroups = ref([])
    const showConfirmDialog = ref(false)
    const confirmMsg = ref([])
    const focusInput = ref('code') // focus default
    const enterKeydown = ref(0)
    const LIST_STATES = ['code', 'bxtg', 'name', 'yomi', 'name_short', 'name_eng', 'bundle_size'] // input order

    const boxTypeData = ref({})

    const closeDialog = async () => {
      loading.value = false
      emit('on-close')
    }

    const createBoxType = async (isDuplicate=false) => {
      loading.value = true
      try {
        const {data} = await api.post(`${endpoints.MASTER_BOX_TYPE}?is_duplicate=${isDuplicate}`, boxTypeData.value)
        $toast.success($root.$t('master.msg.create_successful'))
        emit('on-reload', data)
        await closeDialog()
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          const itemName = ($root.$t('master.box_type_master.box_type.title'))
          const itemGroupName = ($root.$t('master.box_type_master.box_type_group.title'))
          confirmMsg.value = master.getErrorMessage(e, itemName, itemGroupName)
          confirmMsg.value.join('\n')
          showConfirmDialog.value = true
        } else showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const updateBoxType= async (isDuplicate=false) => {
      loading.value = true
      try {
        await api.put(`${endpoints.MASTER_BOX_TYPE}?is_duplicate=${isDuplicate}`, boxTypeData.value)
        $toast.success($root.$t('master.msg.update_successful'))
        emit('on-reload')
        await closeDialog()
      } catch (e) {
        // if api return duplicate --> show dialog confirm and allow save in db
        if (e.response?.data?.detail?.length > 0) {
          const itemName = ($root.$t('master.box_type_master.box_type.title'))
          const itemGroupName = ($root.$t('master.box_type_master.box_type_group.title'))
          confirmMsg.value = master.getErrorMessage(e, itemName, itemGroupName)
          confirmMsg.value.join('\n')
          showConfirmDialog.value = true
        } else showError(e, $toast, $root.$t('master.msg.update_failed'))
      } finally {
        loading.value = false
      }
    }

    const getBoxTypeGroup = async () => {
      if (props.groups) {
        boxTypeGroups.value = props.groups
      } else {
        try {
          const { data } = await api.get(endpoints.MASTER_BOX_TYPE_GROUP)
          boxTypeGroups.value = data
        } catch (e) {
          showError(e, $toast, $root.$t('master.msg.get_data_failed'))
        }
      }
    }

    const onTyping = (event) => {
      enterKeydown.value = 0
      const oldFocus = focusInput.value
      focusInput.value = master.moveCursor(event, $refs, LIST_STATES, focusInput.value, 'bxtg')
      if (focusInput.value === LIST_STATES[LIST_STATES.length - 1] && event.keyCode === 13 && oldFocus === focusInput.value)
        $refs.master_dialog.$refs.dialog_container.$refs.save_btn.$el.click()
    }

    const onFocus = (event) => {
      focusInput.value = 'bxtg'
      if (event.keyCode === 13) {
        enterKeydown.value += 1
        if (enterKeydown.value === 2) onTyping(event)
      }
    }

    const init = async () => {
      focusInput.value = 'code'
      enterKeydown.value = 0
      if (props.show) {
        if (props.isAdd) {
          const boxTypeInit = {
            code: null,
            name: props.prevInput,
            yomi: props.prevInput ? await master.toYomi(props.prevInput) : null,
            name_short: props.prevInput,
            name_eng: null,
            bxtg: props.bxtg,
            bundle_size: null
          }
          boxTypeData.value = JSON.parse(JSON.stringify(boxTypeInit))
        } else {
          boxTypeData.value = JSON.parse(JSON.stringify(boxType.value))
          boxTypeData.value.bxtg = boxType.value.bxtg.id
        }
      }
    }

    watch(
      () => props.show,
      () => init()
    )

    onMounted(getBoxTypeGroup)

    return {
      closeDialog,
      loading,
      boxTypeData,
      updateBoxType,
      createBoxType,
      boxTypeGroups,
      showConfirmDialog,
      confirmMsg,
      focusInput,
      onTyping,
      onFocus
    }
  }
})

export default JBoxTypeDialog
</script>
