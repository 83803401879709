
import { computed, ComputedRef, defineComponent, getCurrentInstance, Ref, ref, watch, toRefs } from 'vue'
import debounce from 'lodash/debounce'
import { convertToWidthCharacter } from 'utils/convertStringWidth'
import {Column, getCursor, getTable, Row} from './types'
import JCommonPickDateInput from '../JCommonPickDateInput/index'

export default defineComponent({
  props: {
    columns: {
      type: Array,
      required: true
    }
  },
  components: {
    JCommonPickDateInput
  },
  setup(props, { emit }) {
    const { columns: refColumns } = toRefs(props)
    const { style, cellClass } = setupCellStyle()
    const { row, column, cell, editing, inputType } = setupTemplateModels()

    const { shouldNotSelectAllOnFocus } = makeSureInputAlwaysHaveFocus(column)
    const { enableEditMode, disableEditMode } = setupTogglingEditMode(shouldNotSelectAllOnFocus, column, row)

    const {onInput} = setupInputEvent(row, column, disableEditMode)
    const {onKeyDown} = setupNavigation({enableEditMode, disableEditMode, onInput})
    const countItem = ref(0)
    const searchString = ref('')
    const autoSelectFirst = computed(() => {
      return (countItem.value > 0) && (searchString.value !== '')
    })

    const getItems = (column: any, cell: string) => {
      const items = column.items.filter(
        (item: any) => item.name_short?.startsWith(cell)
      ).concat(
        column.items.filter(
          (item: any) => !item.name_short?.startsWith(cell)
        )
      )
      countItem.value = items.length
      return items
    }

    const onUpdateSearchInput = (searchInput: any) => {
      if (!searchInput) {
        onInput(null)
        searchString.value = ''
      } else {
        searchString.value = searchInput
      }
      emit('on-searching-select-box', {row: row.value, column: column.value, searchInput})
    }

    const onFilter = (item: any, queryText: string) => {
      // convert alphanumeric to half width, lower case
      // convert kana to full width
      const normalizeText = convertToWidthCharacter(
        queryText.normalize('NFKC'),
        'full')
        .toLowerCase()
      const search_str = convertToWidthCharacter(
        item.search_str.normalize('NFKC'),
        'full')
        .toLowerCase()
      if (item.search_str) return search_str?.includes(normalizeText)
      return true
    }

    return {
      style,
      cellClass,
      onKeyDown,
      row,
      column,
      cell,
      editing,
      inputType,
      onInput,
      onUpdateSearchInput,
      onFilter,
      refColumns,
      getItems,
      autoSelectFirst
    }
  }
})

function setupCellStyle() {
  const $table = getTable()
  const cursor = getCursor()

  const style = computed(() => {
    const cursorBorderWidth = 2
    return {
      top: `${cursor.top + cursorBorderWidth}px`,
      left: `${cursor.left + cursorBorderWidth}px`,
      width: `${cursor.width - cursorBorderWidth * 2}px`
    }
  })

  const cellClass = computed(() => {
    return {
      editing: cursor.editing,
      [`cell-input--${$table.columns[cursor.columnIndex]?.name}`]: true,
      [`cell-input--row-${cursor.rowIndex + 1}`]: true
    }
  })

  return {
    style,
    cellClass
  }
}

function setupTemplateModels() {
  const cursor = getCursor()
  const $table = getTable()

  const row = computed(() => $table.rows[cursor.rowIndex])
  const column = computed(() => $table.columns[cursor.columnIndex])
  const cell = computed(() => row.value[column.value.name])

  return {
    row,
    column,
    cell,
    editing: computed(() => cursor.editing),
    inputType: computed(() => {
      if (column.value.type) {
        // type = number won't accept full width (zenkaku) number
        // to support inputting with full width number
        // we have change type number to type text and implement validate later
        if (column.value.type === 'number') {
          return 'text'
        }
        if (column.value.type === 'date') {
          return 'text'
        }
        return column.value.type
      }
      return 'text'
    })
  }
}

function setupInputEvent(row: Ref<Row>, column: Ref<Column>, disableEditMode: () => void) {
  const $table = getTable()
  return {
    onInput($event) {
      // validate for input type = number
      if (column.value.type === 'number') {
        // convert all alphanumeric characters to half width
        // check value is numeric or not, if not do not allow input
        if ($event && ! /^\d+$/.test($event.normalize('NFKD'))) return
      }

      $table.$emit('input', { row: row.value, column: column.value, value: $event })
      // Disable edit mode after user selected a dropdown entry
      if (column.value.dropdown && $event) {
        disableEditMode()
      }
    }
  }
}

function makeSureInputAlwaysHaveFocus(column: Ref<Column>) {
  const $table = getTable()
  const cursor = getCursor()
  const vm = getCurrentInstance().proxy
  const shouldNotSelectAllOnFocus = ref(false)

  /**
   * Focus on the input upon called
   */
  const focus = debounce(function () {
    // Find the input
    const input = getInput()
    if (!input) return

    // Focus if not already focus
    if (document.activeElement !== input) input.focus()

    // Select all texts inside
    if (shouldNotSelectAllOnFocus.value) {
      // eslint-disable-next-line no-param-reassign
      shouldNotSelectAllOnFocus.value = false
      vm.$nextTick(() => {
        try {
          input.selectionStart = input.selectionEnd
        } catch (e) {
          // Try setting selectionStart on a number input will cause exception
          // This is safe to ignore
        }
      })
    } else {
      vm.$nextTick(() => input.select())
    }

    // Click on v-autocomplete component to open options menu
    if (column.value.dropdown) {
      vm.$nextTick(() => input.click())
    }
    // Wait until the input completes moving to new coordinate, otherwise the page
    // will be janked back to input's old position
  }, 16)

  // Refocus whenever the table's body was clicked
  $table.$on('bodyclick', focus)

  // Refocus when edit mode changes
  watch(() => cursor.editing, focus)

  function getInput(): HTMLInputElement {
    return vm.$el?.querySelector('input:not([type=hidden])')
  }

  return {
    shouldNotSelectAllOnFocus
  }
}

function setupTogglingEditMode(
  shouldNotSelectAllOnFocus: Ref<boolean>,
  column: ComputedRef<Column>,
  row: ComputedRef<Row>
) {
  const $table = getTable()
  const cursor = getCursor()

  function enableEditMode(shouldNotSelectAll = false) {
    if (
      column.value.onEnableEditMode &&
      column.value.onEnableEditMode({ row: row.value, column: column.value }) === false
    )
      return

    // eslint-disable-next-line no-param-reassign
    shouldNotSelectAllOnFocus.value = shouldNotSelectAll
    cursor.editing = true
  }

  function disableEditMode() {
    cursor.editing = false
  }

  // Disable edit mode when row or column changes
  watch([() => cursor.rowIndex, () => cursor.columnIndex], (newValue, oldValue) => {
    $table.$emit('blur', { rowIndex: oldValue[0], columnIndex: oldValue[1] })
    disableEditMode()
  })

  // Click outside
  $table.$on('outsideclick', () => {
    $table.$emit('blur', { rowIndex: cursor.rowIndex, columnIndex: cursor.columnIndex })
    // remove cursor when click outside
    cursor.rowIndex = -1
    disableEditMode()
  })

  // Switch to edit mode when user double clicks on a cell
  $table.$on('celldblclick', () => {
    // When this cell is in edit mode, and user double click to a new cell
    if (cursor.editing) {
      // Then we must disable edit mode first to destroy current component
      disableEditMode()
      // Then re-enable to show the input
      setTimeout(() => enableEditMode)
    } else {
      // Otherwise just show the input directly
      enableEditMode()
    }
  })

  return {
    enableEditMode,
    disableEditMode
  }
}

type SetupNavigationInput = ReturnType<typeof setupTogglingEditMode> & ReturnType<typeof setupInputEvent>

function setupNavigation({ enableEditMode, disableEditMode, onInput }: SetupNavigationInput) {
  const cursor = getCursor()
  const $table = getTable()
  const vm = getCurrentInstance().proxy

  function onKeyDown(event, columns = [], column = null) {
    // We do not want to alter behavior of most keys while in edit mode.
    // Only except:
    // + Tab: to move cursor to right/left cell
    // + Enter: to move cursor to below cell
    // + Esc: to exit edit mode
    if (cursor.editing && !(event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape')) {
      return
    }
    if (event.key === 'Enter') {
      $table.$emit('cell-enter', {
        cursor: cursor,
        column: column
      })
    }
    if (column.enterSkip) {
      if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Escape') {
        moveCursorRightOnEnter({ force: true, nextLineOnEnd: true, cols: columns })
      }
    }
    // Do not intercept any key combination with modifier
    if (event.ctrlKey || event.altKey || event.metaKey) return

    // Character key events should not be prevented so user could start typing
    // when cell is not in edit mode
    // When using virtual/mobile keyboards, formally known as IME (Input-Method Editor),
    // the W3C standard states that a KeyboardEvent’s e.keyCode should be 229
    // and e.key should be "Unidentified" (on Ubuntu) or "Process" (on Windows)
    // https://javascript.info/keyboard-events#mobile-keyboards
    // TODO: Need to test on Macbook, remove this later
    if (event.key.length === 1 || ((event.key === 'Unidentified' || event.key === 'Process') && event.keyCode === 229)) {
      // If the cell is not in editing mode
      if (!cursor.editing) {
        // Clear the current value, so that the character will be
        // set as the input's new value
        onInput('')
        // Then enable edit mode
        enableEditMode(true)
        // Make sure that if the input element changed (e.g overridden by external slot),
        // it will still have focused
        vm.$nextTick(() => {
          const input = getInput()
          input?.focus()
        })
      }
      return
    }

    // Prevent default behavior of all keys
    event.preventDefault()

    navigateFromEvent(event, columns)

    function getInput(): HTMLInputElement {
      return vm.$el?.querySelector('input:not([type=hidden])')
    }
  }

  function navigateFromEvent(event, columns = []) {
    switch (event.key) {
      case 'Enter':
        // Integrate with vuetify's menuable component
        // Do not handle Enter if the menuable component is showing while in edit mode
        if (!cursor.editing) {
          // If not in edit mode, enable edit mode
          enableEditMode(true)
        } else {
          setTimeout(() => {
            // If in edit mode, move cursor down
            const cursorMoved = moveCursorRightOnEnter({ force: true, nextLineOnEnd: true, cols: columns })
            // If the cursor wasn't moved (i.e., cursor at last line), manually disable edit mode
            if (!cursorMoved) {
              disableEditMode()
            }
          })
        }
        break
      case 'ArrowDown':
        moveCursorDown()
        break
      case 'ArrowUp':
        moveCursorUp()
        break
      case 'ArrowRight':
        moveCursorRight({ cols: columns })
        break
      case 'ArrowLeft':
        moveCursorLeft({ force: true, prevLineOnStart: true, cols: columns })
        break
      case 'F2':
        enableEditMode(true)
        break
      case 'Escape':
        disableEditMode()
        break
      case 'Tab':
        // Ignore Alt+Tab or Ctrl+Tab
        if (event.ctrlKey || event.altKey) break

        if (!event.shiftKey) {
          moveCursorRight({ force: true, nextLineOnEnd: true, cols: columns })
        } else {
          moveCursorLeft({ force: true, prevLineOnStart: true, cols: columns })
        }
        break
      default:
        break
    }
  }

  function moveCursorRight({ force = false, nextLineOnEnd = false, cols = [] } = {}) {
    if (cursor.editing && !force) return
    // const nextIndex = cursor.columnIndex + 1
    let nextIndex = cursor.columnIndex
    //Searching the last active index
    let lastIndexActive = cols.length - 1
    for (let j = cols.length - 1; j >= 0; j -= 1) {
      if (cols[j].isActive === false) {
        continue
      } else if (cols[j].isActive === true) {
        lastIndexActive = j
        break
      }
    }
    //Searching nearest right active index
    if (cursor.columnIndex < lastIndexActive) {
      for (let i = cursor.columnIndex + 1; i < cols.length; i += 1) {
        if (cols[i].isActive === true) {
          nextIndex = i
          break
        }
      }
    } else {
      nextIndex += 1
    }
    //Move cursor down when it moved to the end of row
    if (lastIndexActive >= nextIndex) {
      cursor.columnIndex = nextIndex
    } else if (nextLineOnEnd) {
      if (moveCursorDown({ force })) {
        //Searching the first active column
        let firstActiveCol = 0
        for (let i = 0; i <= cols.length; i += 1) {
          if (cols[i].isActive === true) {
            firstActiveCol = i
            break
          }
        }
        cursor.columnIndex = firstActiveCol
      }
    }
  }

  function moveCursorLeft({ force = false, prevLineOnStart = false, cols = [] } = {}) {
    if (cursor.editing && !force) return
    let prevIndex = cursor.columnIndex
    //Searching nearest left active index
    for (let i = cursor.columnIndex - 1; i >= 0; i -= 1) {
      if (cols[i].isActive === true) {
        prevIndex = i
        break
      }
    }
    if (prevIndex >= 0) {
      cursor.columnIndex = prevIndex
    } else if (prevLineOnStart) {
      if (moveCursorUp({ force })) {
        cursor.columnIndex = $table.columns.length - 1
      }
    }
  }

  function moveCursorRightOnEnter({ force = false, nextLineOnEnd = false, cols = [] } = {}) {
    if (cursor.editing && !force) return
    // const nextIndex = cursor.columnIndex + 1
    let nextIndex = cursor.columnIndex

    //Searching the last active index
    let lastIndexActive = cols.length - 1
    for (let j = cols.length - 1; j >= 0; j -= 1) {
      if (cols[j].isActive === false) {
        continue
      } else if (cols[j].isActive === true && cols[j].enterSkip === false) {
        lastIndexActive = j
        break
      }
    }
    //Searching nearest right active index
    if (cursor.columnIndex < lastIndexActive) {
      for (let i = cursor.columnIndex + 1; i < cols.length; i += 1) {
        if (cols[i].isActive === true && cols[i].enterSkip === false) {
          nextIndex = i
          break
        }
      }
    } else {
      nextIndex += 1
    }
    //Move cursor down when it moved to the end of row
    if (lastIndexActive >= nextIndex) {
      cursor.columnIndex = nextIndex
    } else if (nextLineOnEnd) {
      if (moveCursorDown({ force })) {
        //Searching the first active column
        let firstActiveCol = 0
        for (let i = 0; i <= cols.length; i += 1) {
          if (cols[i].isActive === true && cols[i].enterSkip === false) {
            firstActiveCol = i
            break
          }
        }
        cursor.columnIndex = firstActiveCol
      }
    }
  }

  // function moveCursorLeftOnEnter({force = false, prevLineOnStart = false, cols = []} = {}) {
  //   if (cursor.editing && !force) return
  //   let prevIndex = cursor.columnIndex
  //   //Searching the first active index
  //   let firstIndexActive = 0
  //   for (let j = 0; j <= cols.length - 1 ; j += 1) {
  //     if (cols[j].isActive === false) {
  //       continue
  //     } else if (cols[j].isActive === true && cols[j].enterSkip === false) {
  //       firstIndexActive = j
  //       break
  //     }
  //   }
  //   //Searching nearest left active index
  //   for (let i = cursor.columnIndex - 1; i >= 0; i -= 1) {
  //     if (cols[i].isActive === true && cols[i].enterSkip === false) {
  //       prevIndex = i
  //       break
  //     }
  //   }
  //   if (prevIndex >= firstIndexActive) {
  //     cursor.columnIndex = prevIndex
  //   } else if (prevLineOnStart) {
  //     if (moveCursorUp({force})) {
  //       cursor.columnIndex = $table.columns.length - 1
  //     }
  //   }
  // }

  function moveCursorDown({ force = false } = {}) {
    if (cursor.editing && !force) return false

    const nextIndex = cursor.rowIndex + 1
    if ($table.rows.length > nextIndex) {
      cursor.rowIndex = nextIndex
      return true
    }

    return false
  }

  /**
   * Move the cursor up
   * @returns {boolean}
   */
  function moveCursorUp({ force = false } = {}) {
    if (cursor.editing && !force) return false
    const prevIndex = cursor.rowIndex - 1
    if (prevIndex >= 0) {
      cursor.rowIndex = prevIndex
      return true
    }
    return false
  }

  return {
    onKeyDown
  }
}
